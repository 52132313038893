import React, { useEffect } from 'react';
import { ConnectedWallet, TonConnectButton, TonConnectButtonProps, Wallet } from '@tonconnect/ui-react';
import styles from './styles.module.scss';
import { StorageKey } from '../../types';

export interface ITonWalletButtonProps extends TonConnectButtonProps {
  wallet: Wallet | ConnectedWallet | null;
  onClick?: (event: Event) => void;
}

export const TonWalletButton: React.FC<ITonWalletButtonProps> = ({ wallet, ...connectBtnProps }) => {
  useEffect(() => {
    if (wallet) localStorage.setItem(StorageKey.Wallet, JSON.stringify(wallet));
    else localStorage.removeItem(StorageKey.Wallet);
  }, [wallet]);

  return <TonConnectButton className={styles.tonConnectBtn} {...connectBtnProps} />;
};

export default TonWalletButton;
