import React, { ReactNode, useEffect, useState } from 'react';
// components
import { CloseButton } from '../../Button';
// styles
import styles from './styles.module.scss';

export interface IDrawerProps {
  children: ReactNode;
  inverseColor?: boolean;
  open: boolean;
  setOpen?: (value: boolean) => void;
}

export const Drawer: React.FC<IDrawerProps> = ({ open, inverseColor, children, setOpen }) => {
  const [isClosing, setClosing] = useState(false);

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        setOpen?.(!open);
        setClosing(false);
      }, 500); // Duration of the closing animation

      return () => clearTimeout(timer);
    }
  }, [open, setOpen, isClosing]);

  const toggleDrawer = () => setClosing(!isClosing);

  return (
    <>
      {open && (
        <div className={`${styles.overlay} ${isClosing ? styles.fadeOut : styles.fadeIn}`}>
          <div
            className={`${inverseColor ? styles.drawerInverse : styles.drawer} ${isClosing ? styles.slideOut : styles.slideIn}`}
          >
            <CloseButton onClick={toggleDrawer} />
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Drawer;
