import { useLocation } from 'react-router-dom';

export const useQueryParams = (): { [key: string]: string } => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParams: { [key: string]: string } = {};

  const sanitizeValue = (value: string): string => value.replace(/^"|"$/g, '');

  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = sanitizeValue(value);
  }

  return queryParams;
};
