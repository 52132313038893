import React from 'react';
// components
import { Box, Flex, Text } from '@chakra-ui/react';
import { BottomNavBar } from './NavBar';
import { QuestsSteps } from './Steps';
//styles
import styles from './styles.module.scss';
//other
import { IQuest, Quest } from '../../../types';
import { IUser } from '../../../../../types';
import { Icon as IconImg } from '../../../../../components/Icon';

interface IOnboardingProps {
  quest: IQuest;
  userInfo: IUser;
  mutateQuest: () => Promise<void>;
}

const Onboarding: React.FC<IOnboardingProps> = ({ quest, userInfo, mutateQuest }) => (
  <Flex flex='1' height='100vh' flexDirection='column'>
    <Flex flex='1' flexDirection='column' alignItems='center'>
      <Flex
        flexDirection={quest?.checkType === Quest.Quiz ? 'column-reverse' : 'column'}
        alignItems='center'
        padding='8px 0 0'
        gap='16px'
      >
        <Text
          fontFamily='heading'
          maxWidth='340px'
          fontSize='32px'
          position='relative'
          textAlign='center'
          lineHeight='1'
        >
          {quest?.name}
        </Text>
        <Box
          maxWidth='max-content'
          fontWeight='bold'
          border='1px solid #e2e2e2'
          padding='9px 16px'
          borderRadius='48px'
          background='fifthColor'
        >
          <span className={styles.bonusClicks}>
            +{quest?.bonusClicks}
            {quest?.targetBalance === 'pinkBalance' ? (
              <IconImg sizeInPx={22} icon='heart' alt='heartImg' />
            ) : (
              <IconImg sizeInPx={22} />
            )}
          </span>
        </Box>
      </Flex>
      <QuestsSteps quest={quest} userInfo={userInfo} />
    </Flex>
    <BottomNavBar quest={quest} mutateQuest={mutateQuest} />
  </Flex>
);

export default Onboarding;
