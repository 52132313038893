import React from 'react';
import { Spinner } from '@chakra-ui/react';
import { Icon } from '../Icon';
//styles
import styles from './styles.module.scss';
//other

export interface IBalanceProps {
  isLoading?: boolean;
  totalBalance: number | string;
  pinkBalance?: number | string;
}

export const Balance: React.FC<IBalanceProps> = ({
  isLoading = false,
  totalBalance = 0,
  pinkBalance = 0,
}) => (
  <>
    {isLoading ? (
      <Spinner />
    ) : (
      <div className={styles.balance}>
        <span className={styles.totalBalance}>
          <Icon sizeInPx={26} />
          {Number(totalBalance).toFixed(2)}
        </span>
        {pinkBalance && (
          <span className={styles.questsBalance}>
            {Number(pinkBalance).toFixed()} <Icon sizeInPx={26} icon='heart' alt='heartImg' />
          </span>
        )}
      </div>
    )}
  </>
);
