const isTelegramAppAvailable = window.Telegram?.WebApp;

export const isTelegramLink = (url: string) => {
  return typeof url === 'string' && url.includes('t.me');
};

export const openLink = (link: string) => {
  if (isTelegramAppAvailable) window.Telegram.WebApp.openLink(link);
  else console.error('Telegram is not defined');
};

export const openTelegramLink = (link: string) => {
  if (isTelegramAppAvailable) window.Telegram.WebApp.openTelegramLink(link);
  else console.error('Telegram is not defined');
};
