import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import icon from '../../../../assets/timer.png';

export const Status: React.FC = () => (
  <Flex
    padding='5px 12px'
    background='fifthColor'
    borderRadius='20px'
    maxWidth='max-content'
    maxHeight='24px'
    alignItems='center'
    gap='4px'
  >
    <Box maxWidth='15px' maxHeight='15px'>
      <Image width='100%' height='100%' objectFit='contain' src={icon} />
    </Box>
    <span className='text-[12px]'>Checking...</span>
  </Flex>
);

export default Status;
