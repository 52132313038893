import React, { ReactNode } from 'react';
// components
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { AdaptiveHeightScroll as ScrollWrapper } from '../Scroll';
// styles
import styles from './styles.module.scss';

export interface IListProps {
  title?: string;
  children: ReactNode;
  useBorderBottom?: boolean;
  isLoading?: boolean;
  onScroll?: React.UIEventHandler<HTMLDivElement> | undefined;
}

export const List: React.FC<IListProps> = ({ title, children, useBorderBottom, isLoading, onScroll }) => (
  <Flex flexDirection='column' flex='1' height='100%'>
    {title && (
      <Box className={styles.title} width='100%' alignSelf='center'>
        <Text>{title}</Text>
      </Box>
    )}
    <ScrollWrapper useBorderBottom={useBorderBottom} onScroll={onScroll}>
      {children}
      {isLoading && (
        <Box
          width='100%'
          display='flex'
          justifyContent='center'
          marginTop='10px'
          alignItems='center'
          height='100%'
        >
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='lg' />
        </Box>
      )}
    </ScrollWrapper>
  </Flex>
);

export default List;
