import React, { Context, useCallback, useContext } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, Box } from '@chakra-ui/react';
import {
  DiamondFilterContext,
  IDiamondFilters,
  IDiamondFilterState,
} from '../../../../context/DiamondFilterContext';
import { FilterAccordionPanel } from './Panel';
import debounce from 'lodash.debounce';

interface IFilterAccordion {
  resetLocalData?: () => Promise<void> | void;
}

export const FilterAccordion: React.FC<IFilterAccordion> = ({ resetLocalData }) => {
  const { filters, setFilters, options } = useContext(DiamondFilterContext as Context<IDiamondFilterState>);

  const handleFilterChange = async (
    category: keyof Omit<IDiamondFilters, 'weight' | 'price'>,
    option: string
  ) => {
    await resetLocalData?.();
    setFilters((prevFilters: IDiamondFilters) => {
      const newFilters = { ...prevFilters };
      if (newFilters[category]?.includes(option))
        newFilters[category] = newFilters[category].filter(item => item !== option);
      else newFilters[category].push(option);

      return newFilters;
    });
  };

  const debouncedHandleRangeChange = useCallback( // eslint-disable-line
    debounce(async (category: keyof IDiamondFilters, values) => {
      await resetLocalData?.();
      setFilters((prevFilters: IDiamondFilters) => ({
        ...prevFilters,
        [category]: { min: values[0], max: values[1] },
      }));
    }, 500),
    []
  );

  return (
    <Accordion allowMultiple marginTop='16px'>
      {Object.entries(filters).map(([category, value], index) => (
        <AccordionItem
          key={category}
          sx={{
            borderTop: index === 0 ? 'none' : '1px solid #eeeeee',
            '&:first-of-type': { borderTop: 'none' },
            '&:last-of-type': { borderBottom: 'none' },
          }}
        >
          <AccordionButton>
            <Box flex='1' textAlign='left' fontWeight='bold' fontSize='20px'>
              {category.charAt(0).toUpperCase() + category.slice(1)}
              {Array.isArray(value) ? `(${value.length})` : ''}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <FilterAccordionPanel
            category={category as keyof IDiamondFilters}
            handleFilterChange={handleFilterChange}
            handleRangeChange={debouncedHandleRangeChange}
            value={value}
            options={options}
          />
        </AccordionItem>
      ))}
    </Accordion>
  );
};
