import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { fromMillisecondToStringSecond } from '../../../../utils';

interface IClickerTimerProps {
  initialTime: number;
  onTimeUpdate: (time: number) => void;
  onFinish: () => void;
}

const msStep = 1000;

export const ClickerTimer = React.memo<IClickerTimerProps>(
  ({ initialTime, onTimeUpdate, onFinish }: IClickerTimerProps) => {
    const [displayTime, setDisplayTime] = useState<number>(initialTime);
    const timeLeft = useRef<number>(initialTime);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      intervalRef.current = setInterval(() => {
        timeLeft.current -= msStep;
        onTimeUpdate(timeLeft.current);

        setDisplayTime(timeLeft.current);

        if (timeLeft.current <= 0) {
          clearInterval(intervalRef.current as NodeJS.Timeout);
          onFinish();
        }
      }, msStep);

      return () => clearInterval(intervalRef.current as NodeJS.Timeout);
    }, [onTimeUpdate, onFinish]); // eslint-disable-line react-hooks/exhaustive-deps

    return <span className={styles.time}>🕐{fromMillisecondToStringSecond(displayTime)}</span>;
  }
);

ClickerTimer.displayName = 'ClickerTimer';
