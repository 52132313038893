import React from 'react';
// components
import { Image, Tag, TagLabel } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';

interface IFriendsCardTagProps {
  text: string;
  icon?: string;
}

export const FriendsCardTag: React.FC<IFriendsCardTagProps> = ({ text, icon }) => (
  <Tag className={styles.wrapper} variant='subtle'>
    {icon && <Image className='w-[18px]' src={icon} alt='icon' />}
    <TagLabel>{text}</TagLabel>
  </Tag>
);
