import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useTelegramBackButton = (path: string, callback?: () => void): void => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Telegram) {
      const { Telegram } = window;

      Telegram.WebApp.BackButton.show();

      Telegram.WebApp.BackButton.onClick(() => {
        callback?.();
        path && navigate(path);
      });

      return () => {
        Telegram.WebApp.BackButton.hide();
        Telegram.WebApp.BackButton.offClick(() => {});
      };
    }
  }, [navigate, path, callback]);
};
