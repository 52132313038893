import React from 'react';
// components
import { PlanCard, IPlanCardProps } from '../Card';
// styles
import styles from './styles.module.scss';

interface IPlansContainerProps {
  className?: string;
  title: string;
  plans: Array<IPlanCardProps>;
}

export const PlansContainer: React.FC<IPlansContainerProps> = ({ className, title, plans }) => (
  <div className={`${styles.wrapper} ${className}`}>
    <h3 className={styles.title}>{title}</h3>
    {plans.map((plan, idx) => (
      <PlanCard key={plan.type + idx} {...plan} />
    ))}
  </div>
);
