import React from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import fireGif from '../../assets/fire.gif';

interface IHeaderProps {
  currentLvl: number;
}

export const Header: React.FC<IHeaderProps> = ({ currentLvl }) => (
  <Box display='flex' justifyContent='center' alignItems='center' textAlign='center' padding='0 16px'>
    <VStack align='center' textAlign='start'>
      <Box width='150px' height='150px' display='flex' justifyContent='center' alignItems='center'>
        <Image src={fireGif} alt='Diamore Logo' boxSize='220px' objectFit='contain' />
      </Box>
      <VStack maxWidth='350px' align='center' textAlign='center'>
        <Text fontSize='32px' fontFamily='heading' lineHeight='1'>
          You have the {currentLvl > 10 ? 10 : currentLvl}-level now
        </Text>
        <Text fontSize='16px' color='gray.600' fontFamily='body' lineHeight='1'>
          Keep it up! Dont’t let your level to down
        </Text>
      </VStack>
    </VStack>
  </Box>
);
