import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
// components
import { Box, Button, Flex } from '@chakra-ui/react';
import { Navbar } from '../../components/Navbar';
import { Header } from './components/Header';
import { Slider } from './components/Slider';
import { FullPageLoader } from '../../components/FullPageLoader';
// other
import { API, fetcher, loggedAxios } from '../../api';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import { hapticNotification } from '../../utils/hapticFeedback';
import { ROUTES } from '../../constants';

export const DailyReward: React.FC = () => {
  const navigate = useNavigate();
  useTelegramBackButton(ROUTES.home + '?tab=QUESTS');

  const { data: dailyRewards, isLoading: isLoadingDailyRewards } = useSWR(API.dailyRewards, fetcher);

  const currentLvl = Number(dailyRewards?.inRow) + 1;

  useEffect(() => {
    const claimHandler = () => {
      loggedAxios
        .post(API.claimReward)
        .then(() => {
          hapticNotification('success');
        })
        .catch(error => {
          toast.error(error?.response?.data?.message);
          hapticNotification('error');
        });
    };

    claimHandler();
  }, []);

  const handleClickBack = () => {
    navigate(ROUTES.home + '?tab=QUESTS');
  };

  if (isLoadingDailyRewards) return <FullPageLoader />;

  return (
    <Flex direction='column' height='100vh'>
      <Flex flex='1' flexDirection='column' alignItems='center' justifyContent='center'>
        <Header currentLvl={currentLvl} />
        <Slider {...dailyRewards} />
      </Flex>
      <Box padding='0 16px'>
        <Button onClick={handleClickBack} variant={'primary'} width='100%'>
          Come back tomorrow
        </Button>
      </Box>
      <Navbar />
    </Flex>
  );
};
