import { StorageKey } from '../types';

export const checkAndUpdateUserId = (userId?: string) => {
  const userIdFromTelegram = window?.Telegram?.WebApp?.initDataUnsafe?.user?.id.toString();
  const savedUserId = localStorage.getItem(StorageKey.TelegramUserId);
  const existingUserId = userIdFromTelegram || userId;

  if (savedUserId && savedUserId !== userIdFromTelegram) {
    localStorage.removeItem('ton-connect-storage_bridge-connection');
    localStorage.removeItem('ton-connect-ui_wallet-info');
    localStorage.removeItem('wallet');
  }

  existingUserId && localStorage.setItem(StorageKey.TelegramUserId, existingUserId);
};
