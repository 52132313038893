import { IPlanCardProps } from '../components/Plan/Card';

export const plans: Array<IPlanCardProps> = [
  {
    type: '🎖️ Profitable',
    title: '6 Month',
    description: 'The best plan for accumulating points',
    benefits: [
      '+20000 daily reward',
      '+1 daily game',
      'x2 points multibonus',
      'x3 experience bonus',
      'AIRdrop $DMR',
      '+2.5% bonus 1stLV ref.',
      '+1.5% bonus 2stLV ref.',
      'Access to the "Diamond" game',
      'Get 100 active referrals to your friend list.',
    ],
  },
  {
    type: '🏎️️ Speed',
    title: '3 Month',
    description: 'A quick plan with small bonuses',
    benefits: ['+10000 daily reward', '+1 daily game', 'Access to the "Diamond" game'],
  },
];
