import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
// components
import { FormControl } from '@chakra-ui/react';
import { CustomInput } from '../../../../../components/Input';
// styles
import checkIcon from '../../../../../assets/images/checkIcon.svg';
import styles from './styles.module.scss';
// other
import { API, loggedAxios } from '../../../../../api';

export const PromocodeForm: React.FC = () => {
  const [input, setInput] = useState<string>('');
  const [error, setError] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

  const handleApply = async () => {
    try {
      const resp = await loggedAxios.post(API.sendPromocode, {
        code: input,
      });
      setError('');
      toast.success(resp?.data?.message, { icon: <img src={checkIcon} alt='Success Icon' /> });
    } catch (e: any) {
      setError(e?.response?.data?.message);
      console.warn(e?.response?.data?.message);
    }
  };

  useEffect(() => {
    const handleBlur = () => {
      setTimeout(() => {
        window.scrollTo(0, 5);
      }, 100);
    };

    const inputField = inputRef.current;
    if (inputField) {
      inputField.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputField) {
        inputField.removeEventListener('blur', handleBlur);
      }
    };
  }, []);

  return (
    <FormControl>
      <CustomInput
        ref={inputRef}
        wrapperClassName={styles.promocodeInput}
        input={input}
        error={error}
        handleInputChange={handleInputChange}
        handleApply={handleApply}
      />
    </FormControl>
  );
};
