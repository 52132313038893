import React from 'react';
import { DiamondCard } from '../Card';
import { Box, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IDiamondCard } from '../types/interfaces/IDiamondItem';

interface ISaleListProps {
  items: IDiamondCard[];
  isLoading?: boolean;
}

export const SaleList: React.FC<ISaleListProps> = ({ items, isLoading }) => {
  const navigate = useNavigate();

  const handleBuy = (id: number) => {
    navigate(`details/${id}`);
  };

  return (
    <>
      {items?.map((diamondInfo, i) => (
        <DiamondCard key={i} item={diamondInfo} linkLabel='BUY' onClick={() => handleBuy(diamondInfo.id)} />
      ))}
      {isLoading && (
        <Box flex='1' display='flex' justifyContent='center' alignItems='center' height='50px'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='lg' />
        </Box>
      )}
    </>
  );
};
