export const obfuscateString = (
  str: string,
  before = 2,
  after = 2,
  replacer = '*',
  fixedMiddleLength: number | null = null
) => {
  try {
    if (!str) return;

    if (str.length === 1) return str;

    if (str.length === 2) {
      before = 1;
      after = 0;
    }

    if (str.length === 3) {
      before = 1;
      after = 1;
    }

    if (str.length === 4) {
      before = 2;
      after = 1;
    }

    if (before + after >= str.length) {
      before = Math.floor(str.length / 2);
      after = str.length - before;
    }

    const partBefore = str.substring(0, before);
    const partAfter = str.substring(str.length - after, str.length);
    const middleLength = fixedMiddleLength !== null ? fixedMiddleLength : str.length - (before + after);
    const stars = replacer.repeat(middleLength);
    const newStr = partBefore + stars + partAfter;

    return newStr;
  } catch (err) {
    console.warn(str, err);
  }
};
