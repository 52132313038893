import React, { useState } from 'react';
// components
import { Box, Spinner } from '@chakra-ui/react';
import { DiamondCard } from '../Card';
import { BottomModal } from '../../../components/Modal/Bottom';
import { PeriodNavBar } from '../PeriodNavBar';
import { IDiamondCard } from '../types/interfaces/IDiamondItem';

interface IOwnListProps {
  items: IDiamondCard[];
  isLoading?: boolean;
  removeItemById?: (id: number) => void;
}

export const OwnList: React.FC<IOwnListProps> = ({ items, isLoading, removeItemById }) => {
  const [showPeriodModal, setShowPeriodModal] = useState(false);
  const [currentStakeId, setCurrentStakeId] = useState<number | null>(null);

  const handleStake = (id: number) => {
    setCurrentStakeId(id);
    setShowPeriodModal(true);
  };

  return (
    <>
      {items?.map((item, i) => (
        <DiamondCard key={i} item={item} linkLabel='STAKE' onClick={() => handleStake(item.id)} />
      ))}
      {isLoading && (
        <Box flex='1' display='flex' justifyContent='center' alignItems='center' height='50px'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='lg' />
        </Box>
      )}
      <BottomModal showModal={showPeriodModal} setShowModal={setShowPeriodModal}>
        <PeriodNavBar
          currentStakeId={currentStakeId ?? 0}
          setShowModal={setShowPeriodModal}
          removeItemById={removeItemById}
        />
      </BottomModal>
    </>
  );
};
