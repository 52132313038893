import React from 'react';
// components
import { List } from '../../../../../../components/List';
import { GifTextBlock } from '../../../../../../components/GifTextBlock';
import { TapPadsActivityRow as Row } from './Row';
// styles
import sadSmileGif from '../../../../../../assets/images/gif/sadSmile.gif';
import styles from './styles.module.scss';

interface ITapPadsActivityProps {
  total?: number | string;
  history: Array<any>;
}

export const TapPadsActivity: React.FC<ITapPadsActivityProps> = ({ total = 0, history }) => (
  <div className={styles.wrapper}>
    <h3>Activity</h3>
    {history.length ? (
      <div className={styles.history}>
        <h5>INCOME HISTORY</h5>
        <Row description='Total income' payout={total} header></Row>
        <List useBorderBottom>
          {history.map(activity => (
            <Row key={activity.id} {...activity} />
          ))}
        </List>
      </div>
    ) : (
      <>
        <GifTextBlock
          title='There’s no income here yet'
          subTitle='You haven’t started earn points with Act 2 Earn yet'
          img={sadSmileGif}
        />
      </>
    )}
  </div>
);
