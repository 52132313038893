import React, { useContext } from 'react';
// components
import { Box, Flex, Text } from '@chakra-ui/react';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { FilterAccordion } from './Accordion';
// styles
import 'rc-slider/assets/index.css';
// other
import { TABS_LIST } from '../Diamonds';
import { DiamondFilterContext } from '../../../context/DiamondFilterContext';
import { useTelegramBackButton } from '../../../hooks/useTelegramBackButton';
import { ROUTES } from '../../../constants';

interface IFilters {
  onCloseModal: () => void;
  resetLocalData?: () => void;
  hasActiveFilters: boolean;
}

export const Filters: React.FC<IFilters> = ({ onCloseModal, resetLocalData, hasActiveFilters }) => {
  const context = useContext(DiamondFilterContext);
  if (!context) {
    throw new Error('Must use within DiamondFilterProvider');
  }
  const { filters, setFilters, isLoading, initialValues } = context;
  useTelegramBackButton(`${ROUTES.diamonds}?tab=${TABS_LIST.sale.text}`, onCloseModal);

  const clearAllFilters = async () => {
    const initialFilters = Object.keys(filters).reduce((acc, category) => {
      if (category === 'weight' || category === 'price') {
        //@ts-ignore
        acc[category] = { min: initialValues[category].min, max: initialValues[category].max };
        //@ts-ignore
      } else acc[category] = [];

      return acc;
    }, {});
    if (hasActiveFilters) await resetLocalData?.();
    //@ts-ignore
    await setFilters(initialFilters);
  };

  if (isLoading) return <FullPageLoader />;

  return (
    <Box background='white' height='100%' padding='16px' overflowX='hidden'>
      <Flex alignItems='center' justifyContent='space-between'>
        <Text as='span' size='13px'>
          FILTERS
        </Text>
        <button className='text-[12px] underline font-bold' onClick={clearAllFilters}>
          CLEAR ALL
        </button>
      </Flex>
      <FilterAccordion resetLocalData={resetLocalData} />
    </Box>
  );
};

export default Filters;
