import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
// components
import { OnboardingCard } from './components';
// styles
import tokenImage from '../../assets/images/tokenImage.png';
// other
import { ROUTES } from '../../constants';

export const Screen2: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = () => navigate(ROUTES.onboardingStep3);

  return (
    <OnboardingCard
      title='Token Listing Confirmed!'
      description='Check out the Roadmap to know more!'
      img={<Image src={tokenImage} alt='Diamore Token' boxSize='240px' objectFit='contain' />}
      btn={{ text: 'Next', onClick: handleRedirect }}
    />
  );
};

export default Screen2;
