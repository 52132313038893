import React from 'react';
// components
import { List } from '../../../../../components/List';
import { FriendsCard as Card } from '../Card';
import { IFriend } from '../../../types';

interface IFriendListProps {
  title: string;
  data: IFriend[];
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  isLoading?: boolean;
}

export const FriendsList: React.FC<IFriendListProps> = ({ title, data, onScroll, isLoading }) => (
  <List title={title} isLoading={data && isLoading} onScroll={onScroll}>
    {data?.map((friend: IFriend, key: number) => (
      <Card
        key={friend.id + key}
        title={`@${friend?.username || friend?.first_name}`}
        tags={{
          first: `🥈 2rd LV: ${friend?.referralsCount?.[0]}`,
          second: `🥉 3rd LV: ${friend?.referralsCount?.[1]}`,
          third: `Total: ${Number(friend?.totalBonuses).toFixed(2)}`,
        }}
        availableDiamonds={Number(friend?.availableBonuses) || 0}
      />
    ))}
  </List>
);
