import React from 'react';
// components
import { Box, HStack, Text } from '@chakra-ui/react';
import { Icon } from '../../../../../components/Icon';
import { FriendsCardTag as Tag } from './Tag';
// styles
import styles from './styles.module.scss';
//other
import diamondImg from '../../../../../assets/images/icon-default-diamond.svg';

interface IFriendsCardProps {
  title: string;
  tags: any;
  availableDiamonds?: number;
}

export const FriendsCard: React.FC<IFriendsCardProps> = ({ title, tags, availableDiamonds = 0 }) => (
  <Box borderBottom='1px' borderColor='#ECECEC' paddingY='12px'>
    <HStack display='grid' gridTemplateColumns='auto max-content' alignItems='flex-start' gap='8px'>
      <Box fontSize='13px'>
        {title && (
          <Text className={styles.title} marginBottom='8px'>
            {title}
          </Text>
        )}
        <Box display='flex' justifyContent='flex-start' flexWrap='wrap' gap='4px'>
          {tags?.first && <Tag text={tags.first} />}
          {tags?.second && <Tag text={tags.second} />}
          {tags?.third && <Tag icon={diamondImg} text={tags.third} />}
        </Box>
      </Box>
      <Text display='flex' alignItems='center' gap='5px'>
        {Number(availableDiamonds || 0).toFixed(2)} <Icon sizeInPx={18} />
      </Text>
    </HStack>
  </Box>
);
