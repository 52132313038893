import React, { useEffect, useState } from 'react';
import ReactConfetti from 'react-confetti';
import { hapticNotification } from '../../utils/hapticFeedback';

export const Confetti: React.FC = () => {
  const [isConfettiShow, setConfettiShowing] = useState<boolean>(true);
  const [confettiWidth, setConfettiWidth] = useState<number>(window.innerWidth);
  const [confettiHeight, setConfettiHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setConfettiWidth(window.innerWidth);
      setConfettiHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    const confettiInterval = setInterval(() => {
      setConfettiShowing(isConfettiShow => !isConfettiShow);
    }, 2000);

    hapticNotification('success');

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(confettiInterval);
    };
  }, []);

  const confettiSource1 = {
    x: 0,
    y: confettiHeight / 2,
    w: 0,
    h: 100,
  };
  const confettiSource2 = {
    x: confettiWidth,
    y: confettiHeight / 2,
    w: 0,
    h: 100,
  };

  const initialVelocityX1 = { min: 0, max: 5 };
  const initialVelocityX2 = { min: 0, max: -5 };

  return (
    <>
      <ReactConfetti
        numberOfPieces={60}
        width={confettiWidth}
        height={confettiHeight}
        recycle={isConfettiShow}
        confettiSource={confettiSource1}
        initialVelocityX={initialVelocityX1}
      />
      <ReactConfetti
        numberOfPieces={60}
        width={confettiWidth}
        height={confettiHeight}
        recycle={isConfettiShow}
        confettiSource={confettiSource2}
        initialVelocityX={initialVelocityX2}
      />
    </>
  );
};

export default Confetti;
