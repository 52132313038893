import React from 'react';
// components
import { AdaptiveHeightScroll } from '../../../components/Scroll';
import { GifTextBlock } from '../../../components/GifTextBlock';
import { FullPageLoader } from '../../../components/FullPageLoader';
// styles
import img from '../assets/emptyStaking.gif';
// other
import { API } from '../../../api';
import { useInfiniteScroll } from '../../../hooks/useInfinityScroll';
import { OwnList } from './List';

const PAGE_LIMIT = 20;

export const Own: React.FC = () => {
  const { items, isLoading, handleScroll, isMounted, removeItemById } = useInfiniteScroll(
    API.marketplaceMy,
    PAGE_LIMIT
  );

  if (isLoading && items?.length === 0) return <FullPageLoader />;

  return (
    <AdaptiveHeightScroll onScroll={handleScroll}>
      {isMounted && !items?.length ? (
        <GifTextBlock
          title='Own list is empty'
          subTitle='When you buy a diamond, it will be displayed right here'
          img={img}
        />
      ) : (
        <OwnList items={items} isLoading={isLoading} removeItemById={removeItemById} />
      )}
    </AdaptiveHeightScroll>
  );
};
