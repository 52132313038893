import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR, { KeyedMutator } from 'swr';
// components
import { Balance } from '../../../../components/Balance/Balance';
import { Tabs } from '../../../../components/Tabs/Tabs';
import { Clicker } from '../../containers/Clicker';
import { Quests } from '../Quests/Quests';
import { Upgrades } from '../../containers/Upgrades';
// styles
import styles from './styles.module.scss';
//other
import { useQuestsInfo } from '../../hooks/useQuestsInfo';
import { API, fetcher } from '../../../../api';
import { IUser } from '../../../../types';

interface IHomeTabsProps {
  userInfo: IUser;
  mutate: KeyedMutator<IUser>;
}

export const HomeTabs: React.FC<IHomeTabsProps> = ({ userInfo, mutate }) => {
  const {
    error: errorUpgrades,
    isLoading: isLoadingUpgrades,
    data: upgrades,
    mutate: mutateUpgrades,
  } = useSWR(API.upgrades, fetcher);

  const location = useLocation();
  const { quests } = useQuestsInfo();

  const hasNoFinished = quests?.filter(qeust => !qeust?.status)?.length > 0;
  const query = new URLSearchParams(location.search);
  const tabParam = query.get('tab');

  const TABS_LIST = {
    game: { text: 'GAME' },
    upgrades: { text: 'UPGRADES' },
    quests: { text: 'QUESTS', notify: hasNoFinished },
  };

  const [currentTab, setCurrentTab] = useState(tabParam || TABS_LIST.game.text);

  const processUpgrades = async () => {
    await mutateUpgrades();
    await mutate();
  };

  return (
    <div className={styles.homeTabs + ' container'}>
      <Balance totalBalance={userInfo?.balance} pinkBalance={userInfo?.pinkBalance} />
      <Tabs
        setState={setCurrentTab}
        state={currentTab}
        className={styles.tabs}
        tabsList={Object.values(TABS_LIST)}
      />
      {/*<Tips content={"The more points you have, the cooler you are! And who knows what exciting goodies you\\'ll be able to grab with them."}/>*/}
      {TABS_LIST.quests.text === currentTab && <Quests />}
      {TABS_LIST.upgrades.text === currentTab && (
        <Upgrades
          upgrades={upgrades}
          isLoading={isLoadingUpgrades}
          error={errorUpgrades}
          onUpdate={processUpgrades}
        />
      )}
      {TABS_LIST.game.text === currentTab && (
        <Clicker userInfo={userInfo} upgrades={upgrades} mutate={mutate} />
      )}
    </div>
  );
};

export default HomeTabs;
