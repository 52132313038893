/* global BigInt */
Object.defineProperty(exports, '__esModule', { value: true });
exports.NftCollection = exports.DictValue = void 0;
exports.bufferToBigInt = bufferToBigInt;
exports.unpackDictData = unpackDictData;
exports.packDictData = packDictData;
exports.nftCollectionConfigToCell = nftCollectionConfigToCell;
const core_1 = require('@ton/core');
const constants_1 = require('./constants');
function bufferToBigInt(buffer) {
  return BigInt('0x' + buffer.toString('hex'));
}
function unpackDictData(cs) {
  return {
    mint_now: cs.loadUint(32),
    mint_max: cs.loadUint(32),
  };
}
function packDictData(data) {
  return (0, core_1.beginCell)().storeUint(data.mint_now, 32).storeUint(data.mint_max, 32).endCell();
}
exports.DictValue = {
  serialize: (src, builder) => {
    builder.storeBuilder(packDictData(src).asBuilder());
  },
  parse: src => {
    return unpackDictData(src);
  },
};
function nftCollectionConfigToCell(config) {
  return (0, core_1.beginCell)()
    .storeAddress(config.owner)
    .storeUint(0, 64)
    .storeRef(config.content)
    .storeRef(config.item)
    .storeRef(config.royalty)
    .storeDict(core_1.Dictionary.empty()) //
    .storeBit(false)
    .endCell();
}
class NftCollection {
  constructor(address, init) {
    this.address = address;
    this.init = init;
  }
  static createFromAddress(address) {
    return new NftCollection(address);
  }
  static createFromConfig(config, code, workchain = 0) {
    const data = nftCollectionConfigToCell(config);
    const init = { code, data };
    return new NftCollection((0, core_1.contractAddress)(workchain, init), init);
  }
  async sendDeploy(provider, via, value) {
    await provider.internal(via, {
      value,
      sendMode: core_1.SendMode.PAY_GAS_SEPARATELY,
      body: (0, core_1.beginCell)().endCell(),
    });
  }
  async getCollectionData(provider) {
    let { stack } = await provider.get('get_collection_data', []);
    let nextItem = stack.readNumber();
    let content = stack.readString();
    let owner = stack.readAddress();
    // const whitelist = core_1.Dictionary.loadDirect(core_1.Dictionary.Keys.BigUint(256), exports.DictValue, stack.readCellOpt());
    // const whitelist_dict_keys = whitelist.keys();
    // const whitelist_dict_values = whitelist.values();
    // const is_second_phase = stack.readNumber();
    return {
      nextItem,
      content,
      owner,
      // whitelist,
      // whitelist_dict_keys,
      // whitelist_dict_values,
      is_second_phase: nextItem,
    };
  }
  async getItemAddr(provider, nftId) {
    let { stack } = await provider.get('get_nft_address_by_index', [{ type: 'int', value: BigInt(nftId) }]);
    return stack.readAddress();
  }
  static mintMessage(params) {
    const uriContent = (0, core_1.beginCell)().storeStringTail(params.contentUrl).endCell();
    const body = (0, core_1.beginCell)()
      .storeAddress(params.owner)
      .storeRef((0, core_1.beginCell)().storeRef(uriContent).endCell())
      .storeAddress(params.owner)
      .storeRef((0, core_1.beginCell)().storeRef(uriContent).endCell())
      .endCell();
    return (0, core_1.beginCell)()
      .storeUint(1, 32) // Mint Operation
      .storeUint(params.queryId || 0, 64) // Query ID
      .storeUint(params.nftId, 64) // NFT ID
      .storeCoins(params.nftAmount) // Initial Balance
      .storeRef(body) // Mint Body
      .endCell();
  }
  async sendMint(provider, via, params) {
    await provider.internal(via, {
      value: params.gas,
      sendMode: core_1.SendMode.PAY_GAS_SEPARATELY,
      body: NftCollection.mintMessage(params),
    });
  }
  static customMintMessage() {
    return (0, core_1.beginCell)().storeUint(constants_1.Op.custom_mint_nft, 32).storeUint(0, 64).endCell();
  }
  async sendCustomMint(provider, via, value) {
    await provider.internal(via, {
      value: value,
      sendMode: core_1.SendMode.PAY_GAS_SEPARATELY,
      body: NftCollection.customMintMessage(),
    });
  }
  static switchPhaseMessage() {
    return (0, core_1.beginCell)()
      .storeUint(constants_1.Op.switch_phase, 32)
      .storeUint(0, 64) // query
      .storeBit(true) // true
      .endCell();
  }
  async sendSwitchPhase(provider, via, value) {
    await provider.internal(via, {
      value: value,
      sendMode: core_1.SendMode.PAY_GAS_SEPARATELY,
      body: NftCollection.switchPhaseMessage(),
    });
  }
  async sendDict(provider, via, value, dictData) {
    const whitelist = core_1.Dictionary.empty(core_1.Dictionary.Keys.BigUint(256), exports.DictValue);
    dictData.forEach(({ address, mint_now, mint_max }) => {
      whitelist.set(bufferToBigInt(address.hash), {
        mint_now,
        mint_max,
      });
    });
    await provider.internal(via, {
      value,
      sendMode: core_1.SendMode.PAY_GAS_SEPARATELY,
      body: (0, core_1.beginCell)()
        .storeUint(constants_1.Op.set_dict, 32)
        .storeUint(0, 64) // queryid
        .storeDict(whitelist, core_1.Dictionary.Keys.BigUint(256), exports.DictValue)
        .endCell(),
    });
  }
}
exports.NftCollection = NftCollection;
