import React, { ReactNode, useRef } from 'react';
// components
import { Box } from '@chakra-ui/react';
// other
import { useMount } from '../../hooks/useMount';
import { usePreventSwipeClose } from '../../hooks/usePreventSwipeClose';
import { ANIMATION_TIME_FOR_SIDE_MODAL } from '../../constants';

export interface ISideModalProps {
  isShowModal: boolean;
  children?: ReactNode;
}

export const SideModal: React.FC<ISideModalProps> = ({ isShowModal, children }) => {
  const sideModalRef = useRef<HTMLDivElement>(null);
  usePreventSwipeClose(sideModalRef);

  const { mounted } = useMount(isShowModal, ANIMATION_TIME_FOR_SIDE_MODAL);

  if (!isShowModal && !mounted) return null;

  return (
    <Box
      ref={sideModalRef}
      position='absolute'
      top={0}
      left={0}
      width={'100%'}
      bottom={0}
      zIndex={100}
      transform={`${isShowModal && mounted ? 'translateX(0)' : 'translateX(-100%)'}`}
      transition={'.3s ease all'}
      overflowX='hidden'
    >
      {children}
    </Box>
  );
};

export default SideModal;
