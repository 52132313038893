import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Box, Fade, Slide } from '@chakra-ui/react';
import { ANIMATION_TIME } from '../../constants';
import { hapticImpact } from '../../utils/hapticFeedback';

export interface IBottomModalProps {
  children?: ReactNode;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

export const BottomModal: React.FC<IBottomModalProps> = ({ setShowModal, showModal, children }) => {
  const handleClickOutside = () => {
    hapticImpact('light');
    setShowModal(false);
  };

  return (
    <>
      {createPortal(
        <>
          <Fade
            onClick={handleClickOutside}
            style={{ zIndex: 100 }}
            in={showModal}
            transition={{ exit: { duration: ANIMATION_TIME }, enter: { duration: ANIMATION_TIME } }}
          >
            <Box
              position='absolute'
              top={showModal ? '0' : 'initial'}
              bottom='0'
              left='0'
              right='0'
              bg='rgba(0, 0, 0, 0.4)'
            ></Box>
          </Fade>
          <Slide
            in={showModal}
            direction='bottom'
            style={{ zIndex: 101 }}
            transition={{ exit: { duration: ANIMATION_TIME }, enter: { duration: ANIMATION_TIME } }}
          >
            {children}
          </Slide>
        </>,
        document.body
      )}
    </>
  );
};

export default BottomModal;
