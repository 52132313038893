import React, { MouseEvent } from 'react';
// components
import { Button } from '../../../../../../../components/Button';
import { Icon } from '../../../../../../../components/Icon';
// styles
import styles from './styles.module.scss';
// other
import { ITapPadsFeed } from '../../../../../types';

export type OnTapPadsBtnCardClick = (feed: ITapPadsFeed, e: MouseEvent<HTMLButtonElement>) => void;

interface ITapPadsFeedCardProps extends ITapPadsFeed {
  onBtnClick?: OnTapPadsBtnCardClick;
}

export const TapPadsFeedCard: React.FC<ITapPadsFeedCardProps> = ({ onBtnClick, ...feed }) => {
  const { name, payout, btn_label: btnText, is_done: disabled } = feed;

  return (
    <div className={styles.wrapper}>
      <div className={styles.infoBlock}>
        <h4 className={styles.infoBlockTitle}>{name}</h4>
        <p className={styles.infoBlockDescription}>
          + {payout} &nbsp;
          <Icon />
        </p>
      </div>
      <Button
        _hover={{ background: disabled ? 'var(--bg-secondary-color)' : 'transparent' }}
        backgroundColor={disabled ? 'var(--bg-secondary-color)' : 'transparent'}
        color={'var(--text-primary-color)'}
        border='1px solid var(--border-secondary-color)'
        padding='10px 8px'
        isDisabled={disabled}
        onClick={e => onBtnClick?.(feed, e)}
      >
        {btnText}
      </Button>
    </div>
  );
};
