import React from 'react';
import styles from './styles.module.scss';
import { Icon } from '../../../../../../../components/Icon';

export interface ITapPadsHistoryActivity {
  description: string;
  payout: number | string;
}

interface ITapPadsActivityRowProps extends ITapPadsHistoryActivity {
  header?: boolean;
}

export const TapPadsActivityRow: React.FC<ITapPadsActivityRowProps> = ({ header, description, payout }) => (
  <div className={header ? styles.wrapperHeader : styles.wrapper}>
    <p>{description}</p>
    <p>
      {header ? payout : `+ ${payout}`} &nbsp;
      <Icon />
    </p>
  </div>
);
