import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// components
import { Box, Button, Flex, HStack, Spinner, Text } from '@chakra-ui/react';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { InviteFriends, FriendsList } from '../components/Friends';
// other
import { useInfiniteScroll } from '../../../hooks/useInfinityScroll';
import { API, loggedAxios } from '../../../api';

const PAGE_SIZE = 20;

export const Friends: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    items,
    totalItems,
    totalAvailableBonuses = 0,
    isLoading,
    handleScroll,
    setItems,
    mutate,
  } = useInfiniteScroll(API.referralRecruits, PAGE_SIZE);

  const isReferrals = items?.length > 0;

  useEffect(() => {
    if (isReferrals) setLoading(false);
  }, [isReferrals]);

  const claimHandler = async () => {
    try {
      setLoading(true);
      await loggedAxios.post(API.claimReferral);
      if (setItems) await setItems([]);
      if (mutate) await mutate(`${API.referralRecruits}?page=1&limit=${PAGE_SIZE}`, false);
      toast.success('Done');
    } catch (e) {
      toast.error('Too many requests, wait some time');
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading && !isReferrals ? (
        <div className='grow'>
          <FullPageLoader />
        </div>
      ) : (
        <Flex flexGrow='1' flexDirection='column' overflowX='hidden' padding='0 16px'>
          <InviteFriends isFriends={isReferrals} />
          {isReferrals ? (
            <>
              <FriendsList
                title={totalItems === 1 ? `${totalItems} FRIEND` : `${totalItems} FRIENDS`}
                data={items}
                isLoading={isLoading}
                onScroll={handleScroll}
              />
              <Box
                maxWidth='350px'
                width='100%'
                margin='10px auto 0'
                bgColor='#F4F4F4'
                borderRadius='16px'
                padding='16px'
              >
                <HStack display='flex' justifyContent='space-between' alignItems='center'>
                  <Text fontSize='16px'>{(totalAvailableBonuses || 0).toFixed(2)}</Text>
                  <Button
                    isDisabled={!(totalAvailableBonuses > 0) || loading}
                    minWidth='80px'
                    onClick={claimHandler}
                    variant='claim'
                  >
                    {loading ? (
                      <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='gray.900'
                        size='sm'
                      />
                    ) : (
                      'Claim'
                    )}
                  </Button>
                </HStack>
              </Box>
            </>
          ) : (
            <Flex flex='1' justifyContent='center' alignItems='center'>
              {loading && (
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='lg' />
              )}
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
};
