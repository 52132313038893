import React from 'react';
// components
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { PromocodeForm } from '../components/Promocode/Form';
// styles
import ticketGif from '../assets/ticket.gif';

export const Promocode: React.FC = () => (
  <Flex flex='1' padding='0 16px' overflow='auto'>
    <Box width='100%' flex='1' marginTop='10px'>
      <Image maxWidth='150px' maxHeight='150px' margin='0 auto' src={ticketGif} alt='ticketGif' />
      <VStack spacing={2} maxWidth='350px' align='center' textAlign='center' margin='30px auto 0'>
        <Text fontSize='28px' fontFamily='heading' lineHeight='1'>
          Earn bonus points
        </Text>
        <Text fontSize='16px' color='gray.600' fontFamily='body' lineHeight='1'>
          Enter the code below to add extra points to your account
        </Text>
      </VStack>
      <PromocodeForm />
    </Box>
  </Flex>
);
