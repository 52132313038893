import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR, { mutate } from 'swr';
// components
import { Button, Spinner } from '@chakra-ui/react';
import { Content } from './Content';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { Layout } from '../../../components/Layout/Layout';
// styles
import styles from './styles.module.scss';
// other
import { useTelegramBackButton } from '../../../hooks/useTelegramBackButton';
import { API, fetcher, loggedAxios } from '../../../api';
import { hapticImpact } from '../../../utils/hapticFeedback';
import { ROUTES } from '../../../constants';

export const Details: React.FC = () => {
  useTelegramBackButton(`${ROUTES.diamonds}?tab=FOR SALE`);
  const { id } = useParams();
  const { data: userInfo, isLoading: isLoadingUserInfo } = useSWR(API.userInfo, fetcher);
  const { data: itemInfo, isLoading: isLoadingItemInfo } = useSWR(API.marketplaceItem(id), fetcher);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleBuyItem = async (id: number) => {
    setIsLoading(true);
    try {
      await loggedAxios.post(API.marketplaceBuyItem(id), fetcher);
      await mutate(() => true, null, { revalidate: true });
      navigate(ROUTES.diamondPurchase);
    } catch (e: any) {
      toast.error(e?.response?.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuy = async (id: number) => {
    hapticImpact('medium');
    if (Number(userInfo?.balance) < itemInfo.price) {
      toast('NOT ENOUGH FUNDS IN THE ACCOUNT');
      return;
    }

    await handleBuyItem(id);
  };

  if (isLoadingUserInfo || isLoadingItemInfo) return <FullPageLoader />;

  return (
    <Layout>
      <div className={styles.contentWrapper + ' grow'}>
        <Content diamondInfo={itemInfo} />
      </div>
      <Button
        className={styles.button}
        disabled={false}
        variant='primary'
        onClick={() => handleBuy(itemInfo?.id)}
      >
        {isLoading ? <Spinner /> : 'Buy'}
      </Button>
    </Layout>
  );
};
