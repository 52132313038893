import React, { useEffect, useRef, useCallback, MutableRefObject } from 'react';
import { toast } from 'react-toastify';
// components
import { ClickerTimer } from './ClickerTimer';
import { GAME_STATE_VARIANTS } from '../../ClickerViewProvider';
// styles
import styles from './styles.module.scss';
// other
import { API, loggedAxios } from '../../../../../../api';
import { Icon } from '../../../../../../components/Icon';

interface IClickerGamePlayProps {
  tapBonusesCurrentSession: number;
  setGameViewState: (state: number) => void;
  tapDurationMil: number;
  loadingRef: MutableRefObject<boolean>;
  currentTimerValue: MutableRefObject<number>;
}

export const ClickerGamePlay: React.FC<IClickerGamePlayProps> = ({
  tapBonusesCurrentSession,
  setGameViewState,
  tapDurationMil,
  loadingRef,
  currentTimerValue,
}) => {
  const tapsRef = useRef<number>(tapBonusesCurrentSession);

  useEffect(() => {
    tapsRef.current = tapBonusesCurrentSession;
  }, [tapBonusesCurrentSession]);

  const handleTimeUpdate = useCallback(
    (newValue: number) => {
      currentTimerValue.current = newValue;
    },
    [currentTimerValue]
  );

  const handleFinish = useCallback(() => {
    if (loadingRef.current) return;

    loadingRef.current = true;
    loggedAxios
      .post(API.syncClicks, {
        amount: String(tapsRef?.current || 0),
      })
      .then(() => {
        setGameViewState(GAME_STATE_VARIANTS.gameFinishAnimation);
      })
      .catch(() => {
        toast.error('Connection error, refresh the page, and play again.');
      });
  }, [setGameViewState, loadingRef]);

  return (
    <span className={styles.wrap}>
      <ClickerTimer initialTime={tapDurationMil} onTimeUpdate={handleTimeUpdate} onFinish={handleFinish} />
      <span className={styles.amount}>
        +{tapBonusesCurrentSession}
        <Icon />
      </span>
    </span>
  );
};

export default ClickerGamePlay;
