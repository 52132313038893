import React from 'react';
import Close from '../../../../../components/Button/Close';
import styles from './styles.module.scss';

interface IMyChipsOfferWallProps {
  id: string;
  title?: string;
  frameSrc: string;
  showFrame?: boolean;
  onClose?: (state: boolean) => void;
}

export const MyChipsOfferWall: React.FC<IMyChipsOfferWallProps> = ({
  id,
  title,
  frameSrc,
  showFrame = true,
  onClose,
}) => (
  <div className={`${styles.wrapper} ${showFrame ? styles.active : ''}`}>
    {onClose && <Close onClick={() => onClose(false)} zIndex={10} />}
    <iframe id={id} title={title || id} src={frameSrc} className={styles.iframe}></iframe>
  </div>
);
