import React, { useEffect, useState } from 'react';
// components
import { AccordionPanel, Box, Checkbox, VStack, Text, Flex } from '@chakra-ui/react';
import Slider from 'rc-slider';
// styles
import 'rc-slider/assets/index.css';
import styles from '../styles.module.scss';
// other
import { IDiamondFilters, IDiamondFiltersOptions } from '../../../../context';

type FilterChangeCategory = 'shape' | 'clarity' | 'color';
type RangeChangeCategory = 'weight' | 'price';

interface IFilterAccordionPanelProps {
  category: keyof IDiamondFilters;
  options: IDiamondFiltersOptions;
  value: IDiamondFilters[keyof IDiamondFilters];
  handleFilterChange: (category: FilterChangeCategory, option: string) => void;
  handleRangeChange: (category: RangeChangeCategory, values: Array<number>) => void;
}

export const FilterAccordionPanel: React.FC<IFilterAccordionPanelProps> = ({
  category,
  options,
  value,
  handleFilterChange,
  handleRangeChange,
}) => {
  const min = category === 'weight' ? Number(options.weight[0]) : Number(options.price[0]);
  const max = category === 'weight' ? Number(options.weight[1]) : Number(options.price[1]);

  const defaultValue = value as IDiamondFilters['weight'];
  const defaultSliderValue = [
    defaultValue.min !== null ? defaultValue.min : min,
    defaultValue.max !== null ? defaultValue.max : max,
  ];

  const [sliderValue, setSliderValue] = useState<number[]>(defaultSliderValue);

  useEffect(() => setSliderValue(defaultSliderValue), [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccordionPanel pb={4}>
      {category === 'weight' || category === 'price' ? (
        <Box>
          <Slider
            range
            className={styles.rangeSlider}
            min={min}
            max={max}
            value={sliderValue}
            onChange={values => {
              setSliderValue(values as Array<number>);
              handleRangeChange(category, values as Array<number>);
            }}
          />
          <Flex gap='8px' mt='4px'>
            {sliderValue.map((el, index) => (
              <Text key={index} as='span' background='#f7f7f7' padding='4px 8px' borderRadius='8px'>
                {index === 0 ? 'min' : 'max'}: {el}
              </Text>
            ))}
          </Flex>
        </Box>
      ) : (
        <VStack align='start'>
          {options[category]?.map(option => (
            <Checkbox
              className={styles.checkbox}
              key={option}
              iconColor='black'
              isChecked={(value as IDiamondFilters[typeof category]).includes(option)}
              colorScheme='blackAlpha'
              onChange={() => handleFilterChange(category, option)}
            >
              {option}
            </Checkbox>
          ))}
        </VStack>
      )}
    </AccordionPanel>
  );
};
