import React from 'react';
//components
import { Button } from '../Button';
//styles
import styles from './styles.module.scss';

interface ICustomInputProps {
  error?: string;
  input: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleApply: () => void;
  wrapperClassName?: string;
  inputClassname?: string;
  isBtn?: boolean;
  placeholder?: string;
  isLoading?: boolean;
}

export const CustomInput = React.forwardRef<HTMLInputElement, ICustomInputProps>(
  (
    {
      error,
      input,
      handleInputChange,
      handleApply,
      wrapperClassName,
      inputClassname,
      isBtn = true,
      placeholder = 'promocode',
      isLoading = false,
    },
    ref
  ) => {
    return (
      <>
        <div
          style={{ borderColor: error ? 'red' : '#eeeeee' }}
          className={`${styles.wrapper} ${wrapperClassName}`}
        >
          <input
            ref={ref}
            value={input}
            onChange={handleInputChange}
            placeholder={placeholder}
            className={`${styles.input} ${inputClassname}`}
          />
          {isBtn && (
            <Button
              isLoading={isLoading}
              variant='primary'
              display='flex'
              gap='5px'
              alignItems='center'
              justifyContent='center'
              height='100%'
              onClick={handleApply}
            >
              Apply
            </Button>
          )}
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </>
    );
  }
);

CustomInput.displayName = 'CustomInput';
