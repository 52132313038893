import React, { ReactNode } from 'react';
import { Box, Button, ButtonProps, Text, VStack } from '@chakra-ui/react';

interface IOnboardingCardButton extends ButtonProps {
  text?: string;
}

export interface IOnboardingCardProps {
  title: string;
  description: string;
  img: ReactNode;
  btn: IOnboardingCardButton;
  icon?: () => ReactNode;
}

export const OnboardingCard: React.FC<IOnboardingCardProps> = ({
  title,
  description,
  img,
  btn = { text: '' },
  icon,
}) => (
  <Box height='100vh' display='flex' justifyContent='center' alignItems='center' p={4} textAlign='center'>
    <VStack spacing={6} align='center' textAlign='center'>
      {img && (
        <Box width='350px' height='250px' display='flex' justifyContent='center' alignItems='center'>
          {img}
        </Box>
      )}
      <VStack spacing={3} maxWidth='350px' align='center' textAlign='center'>
        <Text fontSize='32px' fontFamily='heading'>
          {title}
        </Text>
        <Text fontSize='16px' color='gray.600' fontFamily='body'>
          {description}
        </Text>
      </VStack>
      <Button
        variant={'primary'}
        mt={4}
        position='absolute'
        bottom='20px'
        display='flex'
        alignItems='center'
        gap='5px'
        {...btn}
      >
        <>
          {btn.text}
          {icon && icon()}
        </>
      </Button>
    </VStack>
  </Box>
);

export default OnboardingCard;
