import React from 'react';
import { BuyAssistantLauncher, IBuyAssistantLauncherSpecification } from '../../components/BuyAssistant';
import { API, fetcher } from '../../api';

const specification: Array<IBuyAssistantLauncherSpecification> = [
  { icon: '🧰', title: 'Auto routine', description: 'Automatic collection of all gems in the game' },
  {
    icon: '💸',
    title: 'No bonus loss',
    description: `The assistant will store your combo to ensure it doesn't get lost`,
  },

  {
    icon: '💪',
    title: 'Super tap',
    description: 'You now have enhanced clicking, and you receive more coins per click',
  },
];

export const BuyAssistant: React.FC = () => {
  const getInvoiceLink = async (): Promise<string> => fetcher(API.getInvoice);

  return (
    <div className='container' style={{ padding: 0 }}>
      <BuyAssistantLauncher specification={specification} getInvoiceLink={getInvoiceLink} />
    </div>
  );
};

export default BuyAssistant;
