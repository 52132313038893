import React, { useState } from 'react';
// components
import { Button } from '../../../../../components/Button';
import { ISelectedTest, OnSelectTest, TestQuestion } from './Question';
// styles
import styles from './styles.module.scss';
// other
import { IQuestTestOption } from '../../../types';

interface IQuestTestProps {
  title: string;
  question: string;
  options: Array<IQuestTestOption>;
  onClickNext?: (selected: ISelectedTest) => void;
  onClickPrev?: () => void;
}

export const QuestTest: React.FC<IQuestTestProps> = ({
  title,
  question,
  options,
  onClickNext,
  onClickPrev,
}) => {
  const [selected, setSelected] = useState<ISelectedTest | null>(null);

  const onSave = (callback: OnSelectTest, selected: ISelectedTest) => {
    callback?.(selected);
    setSelected(null);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        {title && <div className={styles.counter}>{title}</div>}
        <TestQuestion
          question={question}
          options={options}
          selected={selected?.answer as IQuestTestOption}
          onSelect={setSelected}
        />
      </div>
      <div className={styles.navigation}>
        {onClickPrev && (
          <Button onClick={() => onSave(onClickPrev, selected as ISelectedTest)} secondary fullWidth>
            PREVIOUS
          </Button>
        )}
        {onClickNext && (
          <Button
            isDisabled={!selected}
            onClick={() => onSave(onClickNext, selected as ISelectedTest)}
            fullWidth
          >
            NEXT
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestTest;
