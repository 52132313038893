import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
// components
import { Box, Spinner } from '@chakra-ui/react';
import { GAME_STATE_VARIANTS, tapPauseInterval } from '../../ClickerViewProvider';
import { AdButton } from '../../AdButton';
// styles
import styles from './styles.module.scss';
// other
import { fromMillisecondToStringHours } from '../../../../utils';
import { API, fetcher } from '../../../../../../api';
import { IUser } from '../../../../../../types';

interface IClickerGameDisabledProps {
  timePassed: number;
  setGameViewState: (state: number) => void;
  userInfo: IUser;
}

export const ClickerGameDisabled: React.FC<IClickerGameDisabledProps> = ({
  timePassed,
  setGameViewState,
  userInfo,
}) => {
  const initLeftState = tapPauseInterval - timePassed;

  const [left, setLeft] = useState<number>(initLeftState);
  const { data } = useSWR(API.getAvailableAds, fetcher);

  useEffect(() => {
    const interval = setInterval(() => {
      setLeft(p => {
        const newValue = p - 1000;
        if (newValue < 1) {
          setGameViewState(GAME_STATE_VARIANTS.readyToPlay);
        }
        return newValue;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [setGameViewState]);

  if (!initLeftState) <Spinner />;

  return (
    <Box className={styles.text}>
      Next harvest in: <br />
      {fromMillisecondToStringHours(left)}
      {data?.available > 0 && (
        <AdButton userInfo={userInfo} data={data} setGameViewState={setGameViewState} />
      )}
    </Box>
  );
};

export default ClickerGameDisabled;
