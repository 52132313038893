import React from 'react';
// components
import { List } from '../../../../../../components/List';
import { Button } from '../../../../../../components/Button';
import { OnTapPadsBtnCardClick, TapPadsFeedCard as Card } from './Card';
import { TapPadsBanner as Banner } from '../../Banner';
// styles
import styles from './styles.module.scss';
// other
import { ITapPadsFeed } from '../../../../types';

interface ITapPadsFeedsProps {
  feeds: ITapPadsFeed[];
  coefficient?: number;
  onClickActivity?: () => void;
  onClickCardBtn?: OnTapPadsBtnCardClick;
  onClickLuckyBtn?: () => void;
}

export const TapPadsFeeds: React.FC<ITapPadsFeedsProps> = ({
  feeds,
  coefficient,
  onClickActivity,
  onClickCardBtn,
  onClickLuckyBtn,
}) => (
  <div className={styles.wrapper}>
    <Banner
      description={
        <Button border='1px solid white' onClick={onClickActivity}>
          See Activity
        </Button>
      }
    />
    <List title='Proposals' useBorderBottom>
      {feeds.map(({ payout, ...feed }) => (
        <Card
          key={feed.id}
          {...feed}
          payout={coefficient ? coefficient * payout : payout}
          onBtnClick={onClickCardBtn}
        />
      ))}
    </List>
    {onClickLuckyBtn && (
      <Button fullWidth onClick={onClickLuckyBtn}>
        I’ll be lucky
      </Button>
    )}
  </div>
);
