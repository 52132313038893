import { useState } from 'react';
import { toast } from 'react-toastify';
import { GAME_STATE_VARIANTS } from '../components/Game/ClickerViewProvider';
import { AdType } from '../types/enums/Ads';
import { sendAdsWatchRequest } from '../utils';

export const useAdDisplay = (setGameViewState: (state: number) => void, isRedirected: any) => {
  const [isOnclickaAd, setIsOnclickaAd] = useState(false);

  const noAdAvailableRequest = async () => {
    try {
      await sendAdsWatchRequest(AdType.NoAdAvailable);
      setGameViewState(GAME_STATE_VARIANTS.readyToPlay);
    } catch (e) {
      console.log(e);
      toast('Sorry, no ads available');
    }
  };

  const showAdsgramAd = async (AdControllerRef: any, showOnclickaAd: () => void) => {
    if (AdControllerRef.current) {
      try {
        await AdControllerRef.current?.show();
        await sendAdsWatchRequest(AdType.Adsgram);
        setGameViewState(GAME_STATE_VARIANTS.readyToPlay);
      } catch (err) {
        console.error(err);
        if (!isRedirected.current) {
          isRedirected.current = true;
          showOnclickaAd();
        } else {
          noAdAvailableRequest();
        }
      }
    } else {
      console.error({
        error: true,
        done: false,
        state: 'load',
        description: 'Adsgram script not loaded',
      });
      if (!isRedirected.current) {
        isRedirected.current = true;
        showOnclickaAd();
      } else {
        noAdAvailableRequest().then();
      }
    }
  };

  const showOnclickaAd = async (showOnclickaAdRef: any, showAdsgramAd: () => void) => {
    if (showOnclickaAdRef.current) {
      try {
        setIsOnclickaAd(true);
        await showOnclickaAdRef?.current?.();
        await sendAdsWatchRequest(AdType.OnClicka);
        setGameViewState(GAME_STATE_VARIANTS.readyToPlay);
      } catch (err) {
        console.error(err);
        if (!isRedirected.current) {
          isRedirected.current = true;
          showAdsgramAd();
        } else {
          noAdAvailableRequest().then();
        }
      } finally {
        setIsOnclickaAd(false);
      }
    } else {
      console.error({
        error: true,
        done: false,
        state: 'load',
        description: 'Onclicka script not loaded',
      });
      if (!isRedirected.current) {
        isRedirected.current = true;
        showAdsgramAd();
      } else {
        noAdAvailableRequest().then();
      }
    }
  };

  return {
    showAdsgramAd,
    showOnclickaAd,
    isOnclickaAd,
  };
};
