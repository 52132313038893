import React, { MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';
// components
import { Button } from '../../../../../../components/Button';
// styles
import styles from './styles.module.scss';
// other
import { GAME_STATE_VARIANTS } from '../../ClickerViewProvider';
import { hapticImpact } from '../../../../../../utils/hapticFeedback';
import { useOpen } from '../../../../../../hooks/useOpen';
import { IUser } from '../../../../../../types';
import { Icon } from '../../../../../../components/Icon';

interface IFinishAnimationProps {
  tapBonusesCurrentSession: number;
  loadingRef: MutableRefObject<boolean>;
  mutate: KeyedMutator<IUser>;
  setTapBonusesCurrentSession: (bonuses: number) => void;
  setGameViewState: (state: number) => void;
}

export const FinishAnimation: React.FC<IFinishAnimationProps> = ({
  tapBonusesCurrentSession,
  loadingRef,
  mutate,
  setTapBonusesCurrentSession,
  setGameViewState,
}) => {
  const loading = useOpen();

  const handleFinish = (): void => {
    hapticImpact('medium');
    loading.onOpen();
    mutate()
      .then(() => {
        loadingRef.current = false;
        setTapBonusesCurrentSession(0);
        setGameViewState(GAME_STATE_VARIANTS.afterGame);
      })
      .catch(() => {
        window.location.reload();
      });
  };

  return (
    <span className={styles.wrap}>
      <span className={styles.title}>🏆Your reward</span>
      <span className={styles.amount + ' ' + (loading.isOpen ? styles.animated : ' ')}>
        {tapBonusesCurrentSession}
        <Icon sizeInPx={46} />
      </span>
      <Button className={styles.claim} as={'span'} isLoading={loading.isOpen} onClick={handleFinish}>
        CLAIM {tapBonusesCurrentSession}
        <Icon sizeInPx={20} />
      </Button>
    </span>
  );
};

export default FinishAnimation;
