import { useState } from 'react';
import { Id, toast } from 'react-toastify';

export const useDarkToast = (
  message = ''
): {
  toastId: Id | null;
  showToast: (toastMessage?: string) => void;
} => {
  const [toastId, setToastId] = useState<Id | null>(null);

  const showToast = (toastMessage = message) => {
    const formattedMessage = toastMessage.toUpperCase();

    if (toastId) {
      toast.update(toastId, {
        render: formattedMessage,
      });
    } else {
      const newToastId = toast.dark(formattedMessage, {
        draggable: false,
        closeOnClick: true,
        onClose: () => setToastId(null),
      });
      setToastId(newToastId);
    }
  };

  return { toastId, showToast };
};

export default useDarkToast;
