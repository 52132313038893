import React, { useEffect, useRef } from 'react';
// components
import { Box, Image, Text } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';
// other
import adIcon from '../../../assets/adIcon.svg';
import { IUser } from '../../../../../types';
import { useAdDisplay } from '../../../hooks/useAdDisplay';

interface IAdButtonProps {
  data: {
    total: number;
    available: number;
  };
  setGameViewState: (state: number) => void;
  userInfo: IUser;
}

const AdButton: React.FC<IAdButtonProps> = ({ data, setGameViewState, userInfo }) => {
  const blockId = String(process.env.REACT_APP_REACT_BLOCK_ID);
  const id = process.env.REACT_APP_ONCLICKA_SPOT_ID;
  const showOnclickaAdRef = useRef<any>(undefined);
  const AdControllerRef = useRef<any>(undefined);
  const isRedirected = useRef(false);

  useEffect(() => {
    //@ts-ignore
    window.initCdTma?.({ id }).then(show => (showOnclickaAdRef.current = show));
    AdControllerRef.current = window.Adsgram?.init({ blockId });

    return () => {
      isRedirected.current = false;
    };
  }, [id, blockId]);

  const { showAdsgramAd, showOnclickaAd, isOnclickaAd } = useAdDisplay(setGameViewState, isRedirected);

  const handleShowAd = () => {
    const userId = parseInt(userInfo.id, 10);
    userId % 2 === 0
      ? showOnclickaAd(showOnclickaAdRef, () => showAdsgramAd(AdControllerRef, () => {}))
      : showAdsgramAd(AdControllerRef, () => showOnclickaAd(showOnclickaAdRef, () => {}));
  };

  return (
    <>
      <Box className={styles.wrapper} onClick={handleShowAd}>
        <Image src={adIcon} alt='' />
        Skip time
        <Text as='span' color='secondaryColor'>
          ( {data?.total - data?.available} / {data?.total} )
        </Text>
      </Box>
      {isOnclickaAd && <div className={styles.adWrapperBg}></div>}
    </>
  );
};

export default React.memo(AdButton);
