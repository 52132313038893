import { extendTheme, defineStyleConfig } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    fontFamily: 'PP Radio Grotesk, sans-serif',
    textTransform: 'uppercase',
    borderRadius: '4px',
    padding: '12px',
    fontWeight: '500',
  },
  variants: {
    primary: {
      fontSize: '12px',
      bg: '#171717',
      color: 'white',
      height: '56px',
      padding: '12px',
      _hover: {
        bg: '#171717',
        color: '#c7c7c7',
      },
    },
    outline: {
      fontSize: '12px',
      bg: 'white',
      color: '#171717',
      border: '1px',
      height: '56px',
      width: '72px',
      paddingX: '12px',
      paddingY: '8px',
      borderColor: '#171717',
      _hover: {
        color: '#262626',
      },
    },
    game: {
      fontSize: '12px',
      bg: 'white',
      color: '#171717',
      height: '56px',
      paddingX: '12px',
      paddingY: '8px',
      _hover: {
        color: '#020202',
      },
    },
    claim: {
      fontSize: '12px',
      bg: '#171717',
      color: 'white',
      height: '32px',
      paddingX: '12px',
      paddingY: '8px',
      _hover: {
        bg: '#0a0a0a',
      },
    },
    claim2: {
      fontSize: '15px',
      bg: '#171717',
      fontWeight: '900',
      color: 'white',
      height: '56px',
      paddingX: '12px',
      paddingY: '12px',
    },
    transparent: {
      fontSize: '12px',
      bg: 'transparent',
      color: 'black',
      height: '56px',
      paddingX: '12px',
      paddingY: '8px',
      _hover: {
        color: '#1c1c1c',
      },
    },
    gray: {
      fontSize: '12px',
      bg: '#ededed',
      color: 'black',
      height: '56px',
      paddingX: '12px',
      paddingY: '8px',
      borderRadius: '8px',
    },
    blue: {
      fontSize: '12px',
      position: 'relative',
      bg: '#31a6f4',
      color: 'black',
      height: '56px',
      paddingX: '12px',
      paddingY: '8px',
    },
  },
});

const Tooltip = defineStyleConfig({
  baseStyle: {
    bg: '#171717',
    borderRadius: '16px',
    padding: '12px',
    color: 'white',
    fontSize: '15px',
    textAlign: 'center',
    margin: '0 5px',
  },
});

const IconButton = defineStyleConfig({
  baseStyle: {
    _hover: {
      bg: 'red',
      color: 'black',
    },
  },
});

const theme = extendTheme({
  fonts: {
    heading: 'PP Writer, serif',
    body: 'PP Radio Grotesk, sans-serif',
    inter: 'Inter, sans-serif',
  },
  colors: {
    primaryColor: '#171717',
    secondaryColor: '#6b6b6b',
    thirdColor: '#007aff',
    fifthColor: '#f4f4f4',
    sixthColor: '#d0d0d0',
    seventhColor: '#eeeeee',
    eighthColor: '#222224',
    ninthColor: '#31a6f4',
  },
  styles: {
    global: {
      'html, body, #root': {
        height: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden',
      },
      '@font-face': [
        {
          fontFamily: 'PP Writer',
          src: `url('/src/assets/fonts/PPWriter-RegularText.woff2') format('woff2')`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          fontFamily: 'PP Radio Grotesk',
          src: `url('/src/assets/fonts/PPRadioGrotesk-Regular.woff2') format('woff2')`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          fontFamily: 'Inter',
          src: `url('/src/assets/fonts/Inter-Regular.woff2') format('woff2')`,
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
      ],
      body: {
        fontFamily: 'PP Radio Grotesk, sans-serif',
        fontSize: '16px',
        color: '#171717',
      },
      '.custom-toast': {
        padding: '0 16px',

        '.Toastify__toast': {
          borderRadius: '16px',
          color: 'white !important',
          margin: '10px 0',
          backgroundColor: '#171717!important',
        },

        '.Toastify__toast--success': {
          backgroundColor: '#171717!important',
        },

        '.Toastify__toast--error': {
          backgroundColor: '#cc0000!important',
        },
      },
    },
  },

  textStyles: {
    h1: {
      fontFamily: 'PP Writer, serif',
      fontSize: '32px',
    },
    body: {
      fontFamily: 'PP Radio Grotesk, sans-serif',
      fontSize: '16px',
    },
  },

  components: {
    Button,
    IconButton,
    Tooltip,
  },
});

export default theme;
