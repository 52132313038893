import React from 'react';
// components
import { IconButton } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { ITooltipProps, Tooltip } from '../Tooltip';

export type ITipsProps = Omit<ITooltipProps, 'children'>;

export const Tips: React.FC<ITipsProps> = tipsProps => (
  <Tooltip bg='#171717' {...tipsProps}>
    <IconButton
      _hover={{ backgroundColor: 'transparent' }}
      _active={{ backgroundColor: 'transparent' }}
      background='transparent'
      aria-label='info'
      position='absolute'
      right='0'
      top='50%'
      transform='translateY(-50%)'
      maxWidth='16px'
      maxHeight='16px'
      minHeight='16px'
      minWidth='16px'
      height='16px'
      display='inline-flex'
      justifyContent='center'
      alignItems='center'
      icon={<InfoOutlineIcon fontSize='22px' color='gray.400' />}
    />
  </Tooltip>
);

export default Tips;
