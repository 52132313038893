import { Image } from '@chakra-ui/react';
import React from 'react';
//styles
import styles from './styles.module.scss';
//other
import diamondIcon from '../../assets/images/icon-default-diamond.svg';
import heartIcon from '../../assets/images/icon-heart-diamond.svg';

const ICONS = {
  diamond: diamondIcon,
  heart: heartIcon,
};
type TIcons = typeof ICONS;

interface IconProps {
  icon?: 'diamond' | 'heart';
  alt?: 'diamondImg' | 'heartImg';
  classname?: string;
  sizeInPx?: number;
}

export const Icon: React.FC<IconProps> = ({
  icon = 'diamond',
  alt = 'diamondImg',
  classname,
  sizeInPx = 20,
}) => (
  <span className={`${styles.wrapper} ${classname}`} style={{ width: sizeInPx }}>
    <Image src={ICONS[icon as keyof TIcons]} alt={alt} />
  </span>
);
