import React, { ReactNode, useRef } from 'react';
import { Container, Flex, VStack } from '@chakra-ui/react';
import { usePreventSwipeClose } from '../../hooks/usePreventSwipeClose';

export interface IScrollProps {
  children: ReactNode;
  onScroll?: React.UIEventHandler<HTMLDivElement> | undefined;
}

export const Scroll: React.FC<IScrollProps> = ({ children, onScroll }) => {
  const scrollElem = useRef<HTMLDivElement>(null);
  usePreventSwipeClose(scrollElem);

  return (
    <Flex
      direction='column'
      alignItems='center'
      width='100%'
      height='100%'
      margin='0 auto'
      overflowX='hidden'
      onScroll={e => onScroll?.(e)}
      ref={scrollElem}
    >
      <Container padding='0px'>
        <VStack spacing={0}>
          <div className='pb-4 w-[100%]'>{children}</div>
        </VStack>
      </Container>
    </Flex>
  );
};

export default Scroll;
