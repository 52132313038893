import React, { MutableRefObject, useEffect } from 'react';
import useSWR, { KeyedMutator } from 'swr';
// components
import { Spinner } from '@chakra-ui/react';
import { ClickerGamePlay } from './States/Play/GamePlay';
import { ClickerGameReady } from './States/Ready/GameReady';
import { FinishAnimation } from './States/Finising/FinishAnimation';
import { ClickerGameDisabled } from './States/Disabled/GameDisabled';
// other
import { API, fetcher } from '../../../../api';
import { TimePassedFromGameStart } from '../../utils';
import { IUser } from '../../../../types';

interface IClickerViewProviderProps {
  gameViewState: number;
  setGameViewState: (state: number) => void;
  tapDurationMil: number;
  tapBonusesCurrentSession: number;
  setTapBonusesCurrentSession: (bonuses: number) => void;
  tapBonusesDefault?: number;
  mutate: KeyedMutator<IUser>;
  loadingRef: MutableRefObject<boolean>;
  currentTimerValue: MutableRefObject<number>;
}

export const tapPauseInterval = 0;

export const GAME_STATE_VARIANTS = {
  inGame: 1,
  gameFinishAnimation: 2,
  afterGame: 3,
  readyToPlay: 4,
};

export const ClickerViewProvider: React.FC<IClickerViewProviderProps> = ({
  gameViewState,
  setGameViewState,
  tapDurationMil,
  tapBonusesCurrentSession,
  setTapBonusesCurrentSession,
  mutate,
  loadingRef,
  currentTimerValue,
}) => {
  const { data: userInfo, isLoading } = useSWR(API.userInfo, fetcher);
  const timePassed = TimePassedFromGameStart(userInfo);
  const ClickerProps = {
    setGameViewState,
    timePassed: timePassed || tapPauseInterval,
    tapDurationMil,
    tapBonusesCurrentSession,
    setTapBonusesCurrentSession,
    userInfo,
  };

  useEffect(() => {
    timePassed < 0 && setGameViewState(GAME_STATE_VARIANTS.afterGame);
  }, [timePassed, setGameViewState]);

  if (GAME_STATE_VARIANTS.inGame === gameViewState) {
    return (
      <ClickerGamePlay {...ClickerProps} loadingRef={loadingRef} currentTimerValue={currentTimerValue} />
    );
  }

  if (GAME_STATE_VARIANTS.gameFinishAnimation === gameViewState) {
    return <FinishAnimation {...ClickerProps} loadingRef={loadingRef} mutate={mutate} />;
  }

  if (GAME_STATE_VARIANTS.afterGame === gameViewState) {
    return <ClickerGameDisabled {...ClickerProps} />;
  }

  if (GAME_STATE_VARIANTS.readyToPlay === gameViewState) {
    return <ClickerGameReady />;
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (timePassed > 0 && timePassed < tapPauseInterval) {
    return <ClickerGameDisabled {...ClickerProps} />;
  }

  return <ClickerGameReady />;
};
