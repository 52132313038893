import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
// componets
import { Friends, Promocode } from './containers';
import { Layout } from '../../components/Layout';
import { Tabs } from '../../components/Tabs';

const TABS_LIST = {
  friends: { text: 'FRIENDS' },
  promocode: { text: 'PROMOCODE' },
};

export const Community: React.FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tabParam = query.get('tab');
  const [currentTab, setCurrentTab] = useState(tabParam || TABS_LIST.friends.text);

  return (
    <Layout paddingTop='20px'>
      <Box paddingLeft='16px' paddingRight='16px'>
        <Tabs
          className='mx-auto'
          setState={setCurrentTab}
          state={currentTab}
          tabsList={Object.values(TABS_LIST)}
        />
      </Box>
      {currentTab === TABS_LIST.friends.text && <Friends />}
      {currentTab === TABS_LIST.promocode.text && <Promocode />}
    </Layout>
  );
};
