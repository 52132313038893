import React, { useEffect } from 'react';
import { Box, Button, Image, Text, VStack } from '@chakra-ui/react';
import diamoreLogo from '../../assets/images/diamoreLogo.png';
import { StorageKey } from '../../types';

export const ErrorScreen: React.FC = () => {
  useEffect(() => {
    const backdoorID = typeof window !== 'undefined' && localStorage.getItem(StorageKey.BackdoorID);
    if (backdoorID) localStorage.removeItem(StorageKey.BackdoorID);

    window.gtag('event', 'we_will_back_soon_visited', {
      event_category: 'Error Pages',
      event_label: 'We Will Be Back Soon Page',
      non_interaction: true,
    });
  }, []);

  return (
    <Box height='100vh' display='flex' justifyContent='center' alignItems='center' p={4} textAlign='center'>
      <VStack spacing={6} align='center' textAlign='center'>
        <Box width='350px' height='250px' display='flex' justifyContent='center' alignItems='center'>
          <Image src={diamoreLogo} alt='Diamore Logo' boxSize='220px' objectFit='contain' />
        </Box>
        <VStack spacing={3} maxWidth='350px' align='center' textAlign='center'>
          <Text fontSize='32px' fontFamily='heading'>
            We will be back soon
          </Text>
          <Text fontSize='16px' color='gray.600' fontFamily='body'>
            We are updating app right now. Please, come back later.
          </Text>
        </VStack>
        <Button
          as={'a'}
          variant={'primary'}
          mt={4}
          href={'https://t.me/diamoremarket'}
          position='absolute'
          bottom='20px'
        >
          Stay tuned
        </Button>
      </VStack>
    </Box>
  );
};

export default ErrorScreen;
