import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HStack, VStack, IconButton, Text } from '@chakra-ui/react';
// styles
import home from './../../assets/images/navbar/home.svg';
import homeChecked from './../../assets/images/navbar/homeChecked.svg';
import diamond from './../../assets/images/navbar/diamond.svg';
import diamondChecked from './../../assets/images/navbar/diamondChecked.svg';
import gear from './../../assets/images/navbar/gear.svg';
import rocket from './../../assets/images/navbar/rocket.svg';
import rocketChecked from './../../assets/images/navbar/rocketChecked.svg';
import gearChecked from './../../assets/images/navbar/gearChecked.svg';
import megaphone from './../../assets/images/navbar/megaphone.svg';
import megaphoneChecked from './../../assets/images/navbar/megaphoneChecked.svg';
// other
import { hapticImpact } from '../../utils/hapticFeedback';
import { ROUTES } from '../../constants';

const NAVBAR_ITEMS = [
  {
    label: 'Earn',
    link: ROUTES.home,
    icon: home,
    iconChecked: homeChecked,
  },
  {
    label: 'Diamonds',
    link: ROUTES.diamonds,
    icon: diamond,
    iconChecked: diamondChecked,
  },
  {
    label: 'NFT',
    link: ROUTES.nft,
    icon: rocket,
    iconChecked: rocketChecked,
  },
  {
    label: 'Community',
    link: ROUTES.community,
    icon: megaphone,
    iconChecked: megaphoneChecked,
  },
];

const backdoorItem = {
  label: 'BKD',
  link: ROUTES.bkd,
  icon: gear,
  iconChecked: gearChecked,
};

export const Navbar: React.FC = () => {
  const location = useLocation();
  const isBackdoor = process.env.REACT_APP_IS_BACKDOOR === 'True';

  const navbarItems = [...NAVBAR_ITEMS, ...(isBackdoor ? [backdoorItem] : [])];

  return (
    <HStack
      p={4}
      bg='white'
      position='sticky'
      bottom='0'
      justifyContent='space-around'
      paddingBottom='26px'
      width='100%'
      zIndex='10'
    >
      {navbarItems.map(el => {
        const isActive = location.pathname.startsWith(el.link);
        const iconToShow = isActive ? el.iconChecked : el.icon;

        return (
          <VStack
            key={el.label}
            spacing={0}
            as={Link}
            to={el.link}
            align='center'
            onTouchStart={() => hapticImpact('medium')}
          >
            <IconButton
              icon={<img src={iconToShow} alt={el.label} />}
              variant='ghost'
              aria-label={el.label}
              size='lg'
              boxSize='24px'
              sx={{
                '&:hover': {
                  color: 'black',
                  backgroundColor: 'white',
                },
              }}
            />
            <Text fontSize='sm' mt='1'>
              {el.label}
            </Text>
          </VStack>
        );
      })}
    </HStack>
  );
};

export default Navbar;
