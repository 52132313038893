import dayjs, { Dayjs } from 'dayjs';
import { getDuration } from '../../../utils/timeConverters';

export const getStageCardDescription = (startDate: Dayjs, finishDate?: Dayjs | null) => {
  const dateNow = dayjs();

  if (finishDate?.isBefore(dateNow)) {
    return 'Ended';
  } else if (startDate?.isAfter(dateNow)) {
    return `Starts in: ${startDate.format('DD.MM.YYYY')}`;
  } else if (startDate && finishDate) {
    return `Duration: ${getDuration(startDate, finishDate)}`;
  }

  return;
};

export const getStageCardBtnText = ({
  startDate,
  done,
  eligible,
  isActiveStage,
  isComingSoon,
}: {
  startDate: Dayjs;
  done?: boolean;
  eligible?: boolean;
  isActiveStage?: boolean;
  isComingSoon?: boolean;
}) => {
  if (!done && isActiveStage) {
    if (!eligible) {
      return 'INFO';
    } else if (!isComingSoon) {
      return 'MINT';
    }
  } else {
    return startDate.format('MMM DD');
  }
};
