import React from 'react';
import { useQueryParams } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { MyChipsOfferWall } from '../components/Quests/MyChips';

export const MyChips: React.FC = () => {
  const navigation = useNavigate();

  const { url, userId } = useQueryParams();

  const handleClickBack = () => navigation(`${ROUTES.home}?tab=QUESTS`);

  return (
    <MyChipsOfferWall
      id='my-chips-iframe'
      frameSrc={`${url}&user_id=${userId}`.replace(/&quot;/g, '')}
      onClose={handleClickBack}
      showFrame
    />
  );
};
