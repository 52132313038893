import React from 'react';
import { Progress, ProgressProps } from '@chakra-ui/react';
import styles from './styles.module.scss';

export interface IProgress {
  percent: number;
  total: number;
  completed: number;
}

interface IStagesProgressBar extends ProgressProps {
  progress: IProgress;
}

export const StagesProgressBar: React.FC<IStagesProgressBar> = ({ progress, ...props }) => {
  const { percent = 0, total = 0, completed = 0 } = progress;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h5>Total minted</h5>
        <p>
          {percent}% ({completed}/{total})
        </p>
      </div>
      <Progress className={styles.progress} value={percent} {...props} />
    </div>
  );
};
