import React, { useEffect, useState } from 'react';
import { ConnectedWallet } from '@tonconnect/ui-react';
import dayjs from 'dayjs';
import useSWR from 'swr';
// components
import { Flex } from '@chakra-ui/react';
import { IStage, StagesList, StagesProgressBar } from '../components/Stage';
import { WithBgWalletButton } from '../../../components/WithBgWalletButton';
import { FullPageLoader } from '../../../components/FullPageLoader';
// other
import { API, fetcher } from '../../../api';
import { useTonConnect } from '../../../hooks/useTonConnect';
import { useTonNFTApi, useTonNFTContract } from '../hooks';
import { stages as stagesTmp } from '../constants/stages';
import { plans as plansTmp } from '../constants/plans';
import { IPlanCardProps } from '../components/Plan';
import { Network } from '../../../types';

interface IMintingProps {
  wallet: ConnectedWallet;
}

export const Minting: React.FC<IMintingProps> = ({ wallet }) => {
  const { data: account, isLoading: loadingWhitelist } = useSWR(
    `${API.checkWhitelist}?address=${wallet.account.address}`,
    fetcher
  );

  const {
    loading,
    progress: { totalMint, completedMint, isProgressFull },
    mint,
  } = useTonNFTContract({ onlyFirstLoading: true });

  const { network } = useTonConnect();

  const {
    NFTs: { isNFTExist },
    mutate,
    checkMintingTrigger,
  } = useTonNFTApi(
    process.env.REACT_APP_NFT_ADDRESS as string,
    wallet.account.address,
    network?.name as Network
  );

  const [stages, setStages] = useState<Array<IStage>>([]);
  const [plans, setPlans] = useState<Array<IPlanCardProps>>([]);

  const progress = {
    percent: +Number((completedMint / totalMint) * 100 || 0).toFixed(),
    total: totalMint,
    completed: completedMint,
  };

  useEffect(() => {
    isProgressFull && setPlans(plansTmp);
  }, [isProgressFull]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loadingWhitelist) {
      const nextStages = stagesTmp.getData(account?.isWhitelisted);
      setStages(nextStages);
    }
  }, [loadingWhitelist]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCardBtnClick = async (action: string) => {
    if (action === 'MINT') {
      await mint();
      mutate({ isNFTExist: true });
      checkMintingTrigger();
    }
  };

  const onUpdate = () => {
    const nextStages = stagesTmp.getData(account?.isWhitelisted, dayjs());
    setStages(nextStages);
  };

  return (
    <>
      {!stages.length ? (
        <FullPageLoader />
      ) : (
        <Flex flexGrow='1' flexDirection='column' justifyContent='space-between' height='100%'>
          <WithBgWalletButton className='mt-[16px] m-auto' />
          <StagesList
            stages={stages}
            plans={plans}
            isAllDone={isNFTExist}
            isAllFinished={isProgressFull}
            isLoading={loading}
            needCheckForUpdate={!isProgressFull}
            onCardBtnClick={onCardBtnClick}
            onUpdate={onUpdate}
          />
          {!isProgressFull && progress && <StagesProgressBar progress={progress} />}
        </Flex>
      )}
    </>
  );
};
