import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
// components
import { Tabs } from '../../components/Tabs';
import { Balance } from '../../components/Balance';
import { Layout } from '../../components/Layout';
import { CustomPopup } from '../../components/Modal';
import { Staked } from './Staked';
// other
import { Sale } from './Sale';
import { Own } from './Own';
import { API, fetcher } from '../../api';
// styles
import styles from '../Home/components/Tabs/styles.module.scss';
import { DiamondFilterProvider } from '../../context';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { StorageKey } from '../../types';

export const TABS_LIST = {
  sale: { text: 'FOR SALE' },
  own: { text: 'OWN' },
  staked: { text: 'STAKED' },
};

export const ACTIVE_MODAL = {
  filters: 'filters',
  sortBy: 'sortBy',
};

const marketplacePopup = {
  title: 'Educational Marketplace Notice',
  desc: 'All diamonds here are demo diamonds created for educational purpose. This is how our main product will work.',
  btnLabel: 'I agree',
};

export const Diamonds: React.FC = () => {
  const location = useLocation();
  const { isLoading, data: userInfo } = useSWR(API.userInfo, fetcher);
  const query = new URLSearchParams(location.search);
  const tabParam = query.get('tab');

  const [currentTab, setCurrentTab] = useState(tabParam || TABS_LIST.sale.text);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const hasPopupBeenShown = localStorage.getItem(StorageKey.HasMarketPlacePopupBeenShown);
    if (!hasPopupBeenShown) {
      onOpen();
    }
  }, [onOpen]);

  const closeModal = () => {
    localStorage.setItem(StorageKey.HasMarketPlacePopupBeenShown, 'true');
    onClose();
  };

  return (
    <DiamondFilterProvider>
      <Layout>
        <div className={styles.homeTabs + ' container'}>
          <Balance
            isLoading={isLoading}
            totalBalance={userInfo?.balance}
            pinkBalance={userInfo?.pinkBalance}
          />
          <Tabs
            setState={setCurrentTab}
            state={currentTab}
            className={styles.tabs}
            tabsList={Object.values(TABS_LIST)}
          />
          <Flex height='100%'>
            {TABS_LIST.sale.text === currentTab && <Sale />}
            {TABS_LIST.own.text === currentTab && <Own />}
            {TABS_LIST.staked.text === currentTab && <Staked />}
          </Flex>
        </div>
        <CustomPopup
          isOpen={isOpen}
          onClose={onClose}
          closeModal={closeModal}
          title={marketplacePopup.title}
          description={marketplacePopup.desc}
          btnLabel={marketplacePopup.btnLabel}
        />
      </Layout>
    </DiamondFilterProvider>
  );
};
