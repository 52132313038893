import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ConnectedWallet, useTonWallet, Wallet } from '@tonconnect/ui-react';
import { API, loggedAxios } from '../api';
import { StorageKey } from '../types';

export const useConnectWallet = (
  onUpdateWallet?: () => void | undefined
): Wallet | ConnectedWallet | null => {
  const tonWallet = useTonWallet();

  useEffect(() => {
    const savedWallet = typeof window !== 'undefined' && localStorage.getItem(StorageKey.Wallet);
    const connetedWallet = tonWallet as ConnectedWallet;

    const sendWallet = async () => {
      try {
        await loggedAxios.post(API.walletConnect, {
          address: connetedWallet?.account?.address,
          name: connetedWallet?.appName,
        });
        onUpdateWallet?.();
      } catch (error: any) {
        toast(error?.response?.data?.message);
      }
    };

    if (tonWallet && !savedWallet) {
      localStorage.setItem(StorageKey.Wallet, JSON.stringify(tonWallet));
      sendWallet();
    }
  }, [tonWallet, onUpdateWallet]);

  return tonWallet;
};
