import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
// components
import { OnboardingCard } from './components';
// styles
import onboardingImage from '../../assets/images/onboardingImage3.jpg';
// other
import { ROUTES } from '../../constants';
import { Icon } from '../../components/Icon';

export const Screen3: React.FC = () => {
  const navigate = useNavigate();

  const handleRedirect = () => navigate(ROUTES.home);

  return (
    <OnboardingCard
      title='Tap, Invite & Have Fun'
      description='Use the referral program, complete tasks, and enter codes to get points! Ask in the chat for any help!'
      img={
        <Image
          src={onboardingImage}
          alt='Real diamonds'
          boxSize='224px'
          objectFit='contain'
          borderRadius='40px'
        />
      }
      btn={{ text: 'Claim', onClick: handleRedirect }}
      icon={() => <Icon sizeInPx={18} />}
    />
  );
};

export default Screen3;
