Object.defineProperty(exports, "__esModule", { value: true });
exports.Errors = exports.Op = void 0;
class Op {
}
exports.Op = Op;
Op.set_dict = 4;
Op.custom_mint_nft = 0xbca1f58d;
Op.switch_phase = 5;
class Errors {
}
exports.Errors = Errors;
Errors.incorrect_sender = 401;
