import React, { useState } from 'react';
import { toast } from 'react-toastify';
// components
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { CloseButton } from '../../../components/Button/Close';
// other
import { hapticImpact } from '../../../utils/hapticFeedback';
import { API, loggedAxios } from '../../../api';

interface IPeriodNavBarProps {
  setShowModal: (state: boolean) => void;
  currentStakeId: number;
  removeItemById?: (id: number) => void;
}

export const PeriodNavBar: React.FC<IPeriodNavBarProps> = ({
  setShowModal,
  currentStakeId,
  removeItemById,
}) => {
  const [isCurrentButtonLoaded, setIsCurrentButtonLoaded] = useState('');

  const BUTTONS = [
    { id: 1, period: '3 MONTH', value: 3, percentage: '(10%)', variant: 'gray' },
    { id: 2, period: '6 MONTH', value: 6, percentage: '(15%)', variant: 'gray' },
    { id: 3, period: '12 MONTH', value: 12, percentage: '(20%)', variant: 'gray' },
  ];

  const handleClose = () => {
    setShowModal(false);
    hapticImpact('light');
  };

  const handleClick = async (period: string) => {
    try {
      setIsCurrentButtonLoaded(period);
      const resp = await loggedAxios.post(API.stakeItem(currentStakeId) + `?period=${period}`);
      toast(resp?.data?.message);
      setShowModal(false);
      currentStakeId && removeItemById?.(currentStakeId);
    } catch (e: any) {
      console.warn(e);
      toast(e?.response?.data?.message);
    } finally {
      setIsCurrentButtonLoaded('');
    }
  };

  return (
    <Box
      padding='16px'
      position='relative'
      backgroundColor='white'
      borderTopRightRadius='12px'
      borderTopLeftRadius='12px'
      maxH='400px'
      height='100%'
    >
      <CloseButton onClick={handleClose} />
      <Box width='36px' height='4px' backgroundColor='gray.300' borderRadius='2px' margin='0 auto' />

      <Text textAlign='center' marginTop='8px' fontSize='17px' fontWeight='600'>
        Staking period
      </Text>
      <Flex width='100%' direction='column' marginTop='28px' gap='8px' marginBottom='60px'>
        {BUTTONS.map(el => (
          <Button
            key={el.id}
            disabled={false}
            width='100%'
            onClick={() => handleClick(String(el.value))}
            variant={el.variant}
          >
            {isCurrentButtonLoaded === String(el.value) ? (
              <Spinner size='sm' />
            ) : (
              <>
                <Text as='span' textTransform='uppercase' fontWeight='bold'>
                  {el.period}
                </Text>
                <Text as='span'>&nbsp;</Text>
                <Text as='span' color='secondaryColor'>
                  {el.percentage}
                </Text>
              </>
            )}
          </Button>
        ))}
      </Flex>

      <Box width='140px' height='5px' backgroundColor='#171717' borderRadius='2px' margin='0 auto' />
    </Box>
  );
};
