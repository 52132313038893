import { createRef, useState } from 'react';
import { IFloater } from '../types';

export const useClickAnimation = (): {
  clickFloaters: IFloater[];
  onTouchHandler: (event: TouchEvent) => void;
} => {
  const [clickFloaters, setClickFloaters] = useState<Array<IFloater>>([]);

  const updateFloaters = (floater: IFloater): void => {
    setClickFloaters(prev => [...prev, floater]);
  };

  const onTouchHandler = (event: TouchEvent): void => {
    try {
      for (let i = 0; i < event.changedTouches.length; i++) {
        const tch = event.changedTouches[i];

        if (tch.clientX || tch.clientY) {
          const floater = {
            x: +tch.clientX.toFixed(0),
            y: +tch.clientY.toFixed(0),
            ref: createRef(),
            id: (tch.clientX + tch.clientY + Date.now()).toFixed(0),
          };

          updateFloaters(floater);
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  return { clickFloaters, onTouchHandler };
};
