import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// components
import { Timer } from '../../TaskCard/Timer';
import { Banner } from '../Banner';
// styles
import timerGif from '../../../../assets/timer.gif';
import moneyGif from '../../../../assets/money.gif';
import styles from './styles.module.scss';
// other
import { API, loggedAxios } from '../../../../../../api';
import { ROUTES } from '../../../../../../constants';
import { IDailyRewards } from '../../../../types/interfaces/IDailyRewards';

export const DailyRewardsBanner: React.FC = () => {
  const navigate = useNavigate();

  const [dailyRewards, setDailyRewards] = useState<IDailyRewards>();
  const [isClaimed, setIsClaimed] = useState<boolean>(false);

  useEffect(() => {
    const fetchDailyRewards = async () => {
      try {
        await loggedAxios.get(API.dailyRewards).then(resp => setDailyRewards(resp?.data));
      } catch (e: any) {
        console.error(e?.response?.data.message);
      }
    };

    fetchDailyRewards();
  }, []);

  useEffect(() => {
    setIsClaimed(dailyRewards?.current === '0');
  }, [dailyRewards]);

  const handleOpenReward = () => navigate(ROUTES.dailyReward);
  const handleEndDate = () => setIsClaimed(false);

  const getNextHarvestIn = () => {
    const now = new Date();
    const tomorrowStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));

    return (
      <>
        Next harvest in{' '}
        <Timer
          handleEndDate={handleEndDate}
          endTime={tomorrowStart}
          className={styles.timer}
          isIcon={false}
        />
      </>
    );
  };

  const content = isClaimed
    ? {
        title: getNextHarvestIn(),
        description: 'Daily reward claimed',
        imageSrc: timerGif,
      }
    : {
        title: 'Daily reward',
        description: 'Gifts await you',
        imageSrc: moneyGif,
      };

  return <Banner {...content} disabled={isClaimed} onClick={handleOpenReward} />;
};
