import React from 'react';
// components
import { Box, Flex, Image, Text } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';
import img from '../../../assets/banner.jpg';

export interface IBannerProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  imageSrc: string;
  disabled?: boolean;
  classNames?: {
    imageBox?: string;
  };
  onClick?: () => void;
}

export const Banner: React.FC<IBannerProps> = ({
  title,
  description,
  imageSrc,
  disabled,
  classNames,
  onClick,
}) => (
  <div
    className={`relative max-h-[100px] h-full w-full rounded-2xl overflow-hidden ${disabled ? styles.disabled : ''}`}
    onClick={onClick}
  >
    <Image position='absolute' top={0} left={0} width='100%' height='100%' src={img} />
    <Flex justifyContent='space-between' alignItems='stretch' padding='16px' height='100%' maxH='inherit'>
      <Flex position='relative' flexDirection='column' justifyContent='space-between' alignItems='flex-start'>
        <Text as='div' color='white' fontSize='16px' fontWeight='bold'>
          {title}
        </Text>
        {typeof description === 'string' ? (
          <Text
            className={styles.description}
            color='sixthColor'
            display='flex'
            alignItems='center'
            gap='10px'
          >
            {description}
            <Text
              as='span'
              display='block'
              marginRight='5px'
              transform='rotate(-45deg)'
              height='7px'
              width='7px'
              borderRight='2px'
              borderBottom='2px'
              borderColor='secondaryColor'
            />
          </Text>
        ) : (
          description
        )}
      </Flex>
      <Box
        className={classNames?.imageBox}
        position='relative'
        maxWidth='120px'
        color='white'
        marginRight='-15px'
      >
        <Image src={imageSrc} />
      </Box>
    </Flex>
  </div>
);
