import React from 'react';
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface ICustomPopupProps {
  isOpen: boolean;
  onClose?: () => void;
  closeModal: () => void;
  title?: string;
  description?: string;
  btnLabel?: string;
  isLoading?: boolean;
  img?: string;
}

export const CustomPopup: React.FC<ICustomPopupProps> = ({
  isOpen,
  onClose = () => {},
  closeModal,
  title = '',
  description = '',
  btnLabel = '',
  isLoading,
  img,
}) => (
  <Modal onClose={onClose} isOpen={isOpen} isCentered>
    <ModalOverlay />
    <ModalContent maxWidth='290px' borderRadius='16px' overflow='hidden'>
      {img && <Image src={img} />}
      <ModalBody textAlign='center'>
        <Text as='h5' fontSize='20px' fontWeight='bold'>
          {title}
        </Text>
        {description}
      </ModalBody>
      <ModalFooter>
        <Button isLoading={isLoading} width='100%' variant='primary' onClick={closeModal}>
          {btnLabel}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
