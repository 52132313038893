import React from 'react';
// components
import { Tag as ChakraTag, TagLabel, TagProps } from '@chakra-ui/react';
// styles
import styles from './styles.module.scss';

export interface ITagProps extends TagProps {
  text: string;
  icon?: string;
  className?: string;
}

export const Tag: React.FC<ITagProps> = ({ className, text, icon, ...tagProps }) => (
  <ChakraTag className={`${styles.wrapper} ${className}`} variant='subtle' {...tagProps}>
    {icon}
    <TagLabel>{text}</TagLabel>
  </ChakraTag>
);

export default Tag;
