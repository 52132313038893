import React from 'react';
import useSWR from 'swr';
// components
import { HomeTabs } from './components/Tabs/HomeTabs';
import { FullPageLoader } from '../../components/FullPageLoader';
import { Layout } from '../../components/Layout';
import { Popup } from './containers';
// other
import { API, fetcher } from '../../api';
import { IUser } from '../../types';

export const Home: React.FC = () => {
  const { isLoading, data: user, mutate } = useSWR<IUser>(API.userInfo, fetcher);

  // temporarily for popup, delete it if unnecessary
  const now = new Date();
  const deadline = new Date(Date.UTC(2024, 8, 1, 15, 0, 0));
  const showPopup = now < deadline;

  if (isLoading) return <FullPageLoader />;

  return (
    <Layout>
      <HomeTabs mutate={mutate} userInfo={user as IUser} />
      {showPopup && <Popup mutate={mutate} />}
    </Layout>
  );
};

export default Home;
