import React, { useRef } from 'react';
// components
import { FullPageLoader } from '../../../components/FullPageLoader';
import { GifTextBlock } from '../../../components/GifTextBlock';
import { AdaptiveHeightScroll } from '../../../components/Scroll';
import { StakedList } from './List';
// styles
import sadSmileGif from '../../../assets/images/gif/sadSmile.gif';
// other
import { useInfiniteScroll } from '../../../hooks/useInfinityScroll';
import { API } from '../../../api';

const PAGE_LIMIT = 20;

export const Staked: React.FC = () => {
  const withoutCacheKey = useRef(Date.now());
  const swrOptions = { revalidateOnFocus: true, revalidateIfStale: true };
  const { items, isLoading, handleScroll, isMounted, removeItemById, isValidating, refreshAllData } =
    useInfiniteScroll(API.stakedItems, PAGE_LIMIT, withoutCacheKey, undefined, swrOptions);

  if (isValidating && !items?.length) return <FullPageLoader />;

  return (
    <AdaptiveHeightScroll onScroll={handleScroll}>
      {isMounted && !items?.length ? (
        <GifTextBlock
          title={`You haven't staked anything yet`}
          subTitle='Try pressing the stake button in own page'
          img={sadSmileGif}
        />
      ) : (
        <StakedList
          items={items}
          isLoading={isLoading}
          removeItemById={removeItemById}
          refreshAllData={refreshAllData}
        />
      )}
    </AdaptiveHeightScroll>
  );
};

export default Staked;
