import React, { useCallback, useContext, useState } from 'react';
// components
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { AdaptiveHeightScroll } from '../../../components/Scroll';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { SideModal } from '../../../components/Modal';
import { GifTextBlock } from '../../../components/GifTextBlock';
import { Filters, SortBy } from '../Filters';
import { SaleList } from './List';
// styles
import sadSmileGif from '../../../assets/images/gif/sadSmile.gif';
import sortIconSvg from '../assets/sortIcon.svg';
import styles from './styles.module.scss';
// other
import { API } from '../../../api';
import { useInfiniteScroll } from '../../../hooks/useInfinityScroll';
import {
  DiamondFilterContext,
  IDiamondFilters,
  IDiamondInitFilters,
} from '../../../context/DiamondFilterContext';
import { ACTIVE_MODAL } from '../Diamonds';

const PAGE_LIMIT = 20;

export const Sale: React.FC = () => {
  const context = useContext(DiamondFilterContext);
  if (!context) {
    throw new Error('Must use within DiamondFilterProvider');
  }
  const { filters, initialValues, sortOption } = context;
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const queryFilters = JSON.stringify(filters);
  const filterQuery = filters ? `&filters=${queryFilters}&sort=${sortOption}` : '';
  const { items, isLoading, handleScroll, isMounted, resetLocalData } = useInfiniteScroll(
    API.marketplace,
    PAGE_LIMIT,
    undefined,
    filterQuery
  );
  //@ts-ignore
  const isArraysEqual = (category: string) =>
    JSON.stringify(initialValues[category as keyof IDiamondInitFilters]) ===
    JSON.stringify(filters[category as keyof IDiamondFilters]);

  const hasActiveFilters = Object.keys(filters).some(category => {
    if (category === 'weight' || category === 'price') return !isArraysEqual(category);
    //@ts-ignore
    return filters[category].length > 0;
  });

  const onCloseModal = useCallback(() => {
    setActiveModal(null);
  }, []);

  const handleFilterModal = (modal: string) => setActiveModal(modal);

  if (isLoading && !items?.length && !activeModal) return <FullPageLoader />;

  return (
    <div className={styles.sale}>
      <SideModal isShowModal={!!activeModal}>
        {activeModal === 'filters' && (
          <Filters
            onCloseModal={onCloseModal}
            resetLocalData={resetLocalData}
            hasActiveFilters={hasActiveFilters}
          />
        )}
        {activeModal === 'sortBy' && <SortBy onCloseModal={onCloseModal} resetLocalData={resetLocalData} />}
      </SideModal>
      {((isMounted && hasActiveFilters) || (isMounted && items?.length > 0)) && (
        <Flex gap='8px'>
          <Button
            onClick={() => handleFilterModal(ACTIVE_MODAL.filters)}
            variant='gray'
            width='100%'
            height='44px'
            fontWeight='600'
            fontFamily='inter'
          >
            filters
            {hasActiveFilters && (
              <Box
                position='absolute'
                top='calc(50% - 5px)'
                right='8px'
                width='10px'
                height='10px'
                background='black'
                borderRadius='50%'
              />
            )}
          </Button>
          <Button
            onClick={() => handleFilterModal(ACTIVE_MODAL.sortBy)}
            variant='gray'
            width='44px'
            height='44px'
          >
            <Image src={sortIconSvg} />
          </Button>
        </Flex>
      )}
      <AdaptiveHeightScroll onScroll={handleScroll}>
        {isMounted && !items?.length ? (
          <GifTextBlock
            title='Sold out'
            subTitle='Currently, there are no Demo Diamonds available for sale, but they will be available soon'
            img={sadSmileGif}
          />
        ) : (
          <SaleList items={items} isLoading={isLoading} />
        )}
      </AdaptiveHeightScroll>
    </div>
  );
};
