import { DiamondCard } from '../Card';
import { Box, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { API, loggedAxios } from '../../../api';
import { mutate } from 'swr';
import { IDiamondCard } from '../types/interfaces/IDiamondItem';

const calculateDaysDifference = (start: number, end: number) => {
  const diffInMs = end - start;
  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
};

const calculatePeriod = (start: number, end: number) => {
  const diffInMs = end - start;
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  if (diffInDays <= 93) return '3 months';
  else if (diffInDays <= 186) return '6 months';
  else return '12 months';
};

interface IStakedListProps {
  items: IDiamondCard[];
  isLoading?: boolean;
  removeItemById?: (id: number) => void;
  refreshAllData?: (url?: string) => void;
}

export const StakedList: React.FC<IStakedListProps> = ({
  items,
  isLoading,
  removeItemById,
  refreshAllData,
}) => {
  const [isLoadingItemById, setIsLoadingItemById] = useState<number | null>(null);

  const handleClick = async (id: number, isNeedToWithdraw: boolean) => {
    if (isNeedToWithdraw) {
      try {
        await loggedAxios.post(API.stakeWithdraw(id));
        removeItemById?.(id);
      } catch (e: any) {
        toast(e?.response?.data?.message);
      }
    } else {
      try {
        setIsLoadingItemById(id);
        const resp = await loggedAxios.post(API.stakeClaim(id));
        if (refreshAllData) await refreshAllData();
        await mutate(API.userInfo);
        toast(resp?.data?.message);
      } catch (e: any) {
        toast(e?.response?.data?.message);
        if (refreshAllData) await refreshAllData();
      } finally {
        setIsLoadingItemById(null);
      }
    }
  };

  return (
    <>
      {items?.map(diamondInfo => {
        const link = `details/${diamondInfo.id}`;
        const stakeFinish = new Date(diamondInfo?.stakeFinish);
        const stakeStart = new Date(diamondInfo?.stakeStart);
        const lastClaim = new Date(diamondInfo?.lastClaim);
        const now = new Date();
        const daysDifference = calculateDaysDifference(now.getTime(), stakeFinish.getTime());

        const oneHourInMilliseconds = 60 * 60 * 1000;
        const isLessThanHour = now.getTime() - lastClaim.getTime() < oneHourInMilliseconds;
        const period = calculatePeriod(stakeStart.getTime(), stakeFinish.getTime());

        const perHour = (Number(diamondInfo.price) * Number(diamondInfo.apy) * (60 * 60)) / (365 * 86400);
        const hoursPassedFromLastCLaim = lastClaim
          ? (now.getTime() - lastClaim.getTime()) / (1000 * 60 * 60)
          : 0;
        const claimBonus = perHour * hoursPassedFromLastCLaim;
        const isNeedToWithdraw = lastClaim > stakeFinish;

        const linkLabel = isNeedToWithdraw ? 'WITHDRAW' : 'CLAIM';

        return (
          <DiamondCard
            isStaked
            key={diamondInfo.id}
            item={diamondInfo}
            claimBonus={isNeedToWithdraw ? 0 : claimBonus}
            time={daysDifference}
            link={link}
            linkLabel={linkLabel}
            period={period}
            onClick={() => handleClick(diamondInfo.id, isNeedToWithdraw)}
            disabled={isLessThanHour && !isNeedToWithdraw}
            hiddenKeys={['id', 'createdAt', 'name', 'type', 'stakeStart', 'stakeFinish', 'lastClaim']}
            isLoading={isLoadingItemById === diamondInfo.id}
          />
        );
      })}
      {isLoading && (
        <Box flex='1' display='flex' justifyContent='center' alignItems='center' height='50px'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='lg' />
        </Box>
      )}
    </>
  );
};
