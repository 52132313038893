const isHapticFeedbackAvailable = window.Telegram?.WebApp?.HapticFeedback;

export const hapticImpact = (style: 'light' | 'medium' | 'heavy' | 'rigid' | 'soft') => {
  if (isHapticFeedbackAvailable) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred(style);
  }
};

export const hapticNotification = (type: 'error' | 'success' | 'warning') => {
  if (isHapticFeedbackAvailable) {
    window.Telegram.WebApp.HapticFeedback.notificationOccurred(type);
  }
};

export const hapticSelectionChanged = () => {
  if (isHapticFeedbackAvailable) {
    window.Telegram.WebApp.HapticFeedback.selectionChanged();
  }
};
