import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KeyedMutator } from 'swr';
// components
import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { Status } from './Status';
import { Timer } from './Timer';
// styles
import { Icon, CheckIcon } from '@chakra-ui/icons';
import { Icon as IconImg } from '../../../../../components/Icon';
import styles from './styles.module.scss';
// other
import { hapticImpact } from '../../../../../utils/hapticFeedback';
import { ROUTES } from '../../../../../constants';
import { IQuest } from '../../../types';

interface ITaskCardProps {
  quest: IQuest;
  mutateQuests?: KeyedMutator<IQuest[]>;
}

export const TaskCard: React.FC<ITaskCardProps> = ({ quest, mutateQuests }) => {
  const [isMounted, setIsMounted] = useState(false);
  const { name, bonusClicks, status, _id, endDate, targetBalance } = quest;
  const navigate = useNavigate();

  const isCompleted = status === 'completed';
  const isChecking = status === 'checking';
  const isDailyQuest = endDate;

  const handleClick = () => {
    navigate(ROUTES.questsInstruction.replace(':id', _id));
    hapticImpact('medium');
  };

  const handleEndDate = () => {
    setIsMounted(false);
    setTimeout(() => {
      mutateQuests?.();
    }, 400);
  };

  useEffect(() => {
    if (quest?.completed) setIsMounted(false);
    else setIsMounted(true);
  }, [quest]);

  return (
    <Box
      as='button'
      borderBottom='1px'
      borderColor='#ECECEC'
      paddingY='12px'
      paddingX='5px'
      onClick={handleClick}
      className={`${styles.card} ${isMounted ? styles.active : ''}`}
      pointerEvents={quest.status === 'completed' ? 'none' : 'all'}
    >
      <HStack justifyContent='space-between' alignItems='center'>
        <VStack spacing={4} flexDirection='row'>
          <Image width='40px' borderRadius='50%' src={quest?.imgUrl} />
          <Box textAlign='left' display='flex' flexDirection='column'>
            <Box marginBottom='10px'>
              <Text
                color={isCompleted ? 'secondaryColor' : '#000'}
                fontWeight='bold'
                fontSize='14px'
                letterSpacing='1px'
              >
                <span className={styles.bonusClicks}>
                  +{bonusClicks}
                  {targetBalance === 'pinkBalance' ? (
                    <IconImg sizeInPx={22} icon='heart' alt='heartImg' />
                  ) : (
                    <IconImg sizeInPx={22} />
                  )}
                </span>
              </Text>
              <Text color='secondaryColor'>{name} </Text>
            </Box>
            <Flex alignItems='center' gap='10px' flexWrap='wrap'>
              {isDailyQuest && <Timer endTime={endDate} handleEndDate={handleEndDate} />}
              {isChecking && <Status />}
            </Flex>
          </Box>
        </VStack>
        {isCompleted ? (
          <Icon maxWidth='18px' marginRight='5px' as={CheckIcon} w={8} h={8} color='thirdColor' />
        ) : (
          <Box
            marginRight='5px'
            transform='rotate(-45deg)'
            height='9px'
            width='9px'
            borderRight='2px'
            borderBottom='2px'
            borderColor='secondaryColor'
          />
        )}
      </HStack>
    </Box>
  );
};

export default TaskCard;
