import { AxiosResponse } from 'axios';
import { API, loggedAxios } from '../api';
import { StorageKey } from '../types';

export const checkAppVersion = async () => {
  const currentVersion = localStorage.getItem(StorageKey.AppVersion);
  const branchName = process.env.REACT_APP_BRANCH;

  if (branchName) {
    const { data }: AxiosResponse<{ version: string }> = await loggedAxios({
      baseURL: 'https://version.diamore.co',
      url: `${API.appVersion}?branch=${branchName}`,
    });

    if (currentVersion !== data?.version) {
      localStorage.setItem(StorageKey.AppVersion, data.version);
      window.location.reload(); // force page reload
    }
  }
};
