export const ROUTES = {
  root: '/',
  home: '/home',
  welcome: '/welcome',
  assistant: '/assistant',
  onboardingStep1: '/onboarding/step1',
  onboardingStep2: '/onboarding/step2',
  onboardingStep3: '/onboarding/step3',
  questsInstruction: '/quests/instruction/:id',
  dailyReward: '/quests/daily-reward',
  tapPads: '/quests/tap-pads',
  myChips: '/quests/my-chips',
  quiz: '/quests/quiz/:id',
  community: '/community',
  diamonds: '/diamonds',
  diamondDetail: '/diamonds/details/:id',
  diamondPurchase: '/diamonds/purchase',
  nft: '/nft',
  bkd: '/bkd',
};
