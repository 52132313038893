import React, { useEffect, useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import timerPng from '../../../../assets/timer.png';

interface ITimerProps {
  endTime: Date;
  handleEndDate?: () => void;
  isIcon?: boolean;
  className?: string;
}

export const Timer: React.FC<ITimerProps> = ({
  endTime,
  handleEndDate = () => {},
  isIcon = true,
  className,
}) => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const updateTimer = () => {
      const now = new Date().valueOf();
      const endDate = new Date(endTime).valueOf();
      const totalSeconds = (endDate - now) / 1000;

      if (totalSeconds > 0) {
        const hours = Math.floor(totalSeconds / 3600) % 24;
        const minutes = Math.floor(totalSeconds / 60) % 60;
        const seconds = Math.floor(totalSeconds) % 60;

        setTimeLeft([hours, minutes, seconds].map(val => val.toString().padStart(2, '0')).join(':'));
      } else {
        setTimeLeft('00:00:00');
        handleEndDate();
      }
    };

    updateTimer();

    const intervalId = setInterval(updateTimer, 1000);
    return () => clearInterval(intervalId);
  }, [endTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex
      className={className}
      padding='5px 12px'
      background='fifthColor'
      borderRadius='20px'
      maxWidth='max-content'
      alignItems='center'
      gap='4px'
    >
      {isIcon && (
        <Box maxWidth='13px' maxHeight='13px'>
          <Image width='100%' height='100%' objectFit='contain' src={timerPng} />
        </Box>
      )}
      <span className='text-[12px]'>{timeLeft}</span>
    </Flex>
  );
};

export default Timer;
