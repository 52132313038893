import { useState } from 'react';
import { NftAPI } from '../../../api/NFT';
import { useInterval } from '../../../hooks/useInterval';
import { useDarkToast } from '../../../hooks/useToast';
import { Network, StorageKey } from '../../../types';

interface INFTs {
  isNFTExist: boolean;
}

export const useTonNFTApi = (
  NFTAddress: string,
  ownerAddress: string,
  network: Network
): {
  NFTs: INFTs;
  mutate: (values: INFTs) => void;
  checkForNFT: () => Promise<boolean>;
  checkMintingTrigger: () => void;
} => {
  const { showToast } = useDarkToast();

  const API = new NftAPI(network);

  const savedMinted: string | null = localStorage.getItem(StorageKey.Minted);
  const minted: boolean = !!savedMinted && JSON.parse(savedMinted);

  const savedCheckingMint: string | null = localStorage.getItem(StorageKey.CheckingMint);
  const checkingMint: boolean = !!savedCheckingMint && JSON.parse(savedCheckingMint);

  const [NFTs, setNFTs] = useState<INFTs>({ isNFTExist: minted });
  const [checkMinting, setMintingCheck] = useState<boolean>(checkingMint);

  const checkMintingTrigger = (): void => {
    setMintingCheck(!checkMinting);
    localStorage.setItem(StorageKey.CheckingMint, JSON.stringify(!checkMinting));
  };

  const mutate = (values: INFTs): void => {
    const { isNFTExist } = values;
    minted !== isNFTExist && localStorage.setItem(StorageKey.Minted, JSON.stringify(isNFTExist));
    setNFTs({ ...NFTs, ...values });
  };

  const checkForNFT = async (): Promise<boolean> => {
    const nftByClient = await API.getNftByOwner(ownerAddress, NFTAddress);
    const isNFTExist = !!nftByClient.length;

    NFTs.isNFTExist !== isNFTExist && mutate({ isNFTExist });

    return isNFTExist;
  };

  const updateNftAvailability = async (): Promise<void> => {
    if (!ownerAddress && !NFTAddress) return;

    const isNFTExist: boolean = await checkForNFT();

    if (isNFTExist) {
      setMintingCheck(!isNFTExist);
      localStorage.removeItem(StorageKey.CheckingMint);
      showToast('NFT HAS BEEN SUCCESSFULLY MINTED');
    } else {
      NFTs.isNFTExist !== isNFTExist && mutate({ isNFTExist });
    }
  };

  useInterval(updateNftAvailability, { ms: 10000, active: checkMinting });

  return { NFTs, mutate, checkForNFT, checkMintingTrigger };
};
