import React, { MouseEvent } from 'react';
// components
import { CheckIcon } from '@chakra-ui/icons';
import { Button, IButtonProps } from '../../../../../components/Button';
import { Tag } from '../../../../../components/Tag';
//styles
import styles from './styles.module.scss';

export type OnStageCardClick = (
  card: IStageCardProps,
  event: MouseEvent<HTMLDivElement>
) => Promise<void> | void;

export type OnStageCardBtnClick = (
  card: IStageCardProps,
  event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
) => Promise<void> | void;

export interface IStageCardState {
  selected?: boolean;
  disabled?: boolean;
  done?: boolean;
  eligible?: boolean;
  comingSoon?: boolean;
}

export interface IButton extends Omit<IButtonProps, 'onClick'> {
  text?: string;
  onClick?: OnStageCardBtnClick;
}

export interface IStageCardProps extends IStageCardState {
  title: string;
  description?: string;
  hideBtn?: boolean;
  btn?: IButton;
  onCardClick?: OnStageCardClick;
}

export const StageCard: React.FC<IStageCardProps> = props => {
  const { selected, title, description, btn, disabled, done, eligible, comingSoon, hideBtn, onCardClick } =
    props;

  return (
    <div
      data-selected={selected || false}
      className={disabled ? styles.cardDisabled : styles.cardActive}
      onClick={e => onCardClick?.(props, e)}
    >
      <div className={styles.header}>
        <div>
          <h2>{title}</h2>
          {description && <p>{description}</p>}
        </div>
        <>
          {(comingSoon || done) && (
            <div className={comingSoon ? styles.iconActive : styles.iconDone}>
              <CheckIcon color='white' />
            </div>
          )}
        </>
      </div>
      <div className={styles.details}>
        <Tag text={eligible ? '✅ Eligible' : '🔒 Not eligible'} />
        {btn?.text && !disabled && !done && !hideBtn && (
          <Button {...btn} onClick={e => btn.onClick?.(props, e)}>
            {btn.text}
          </Button>
        )}
      </div>
    </div>
  );
};
