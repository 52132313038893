import React from 'react';
import { Text } from '@chakra-ui/react';
import styles from './styles.module.scss';

export interface IGifTextBlockProps {
  title: string;
  subTitle: string;
  img: string;
}

export const GifTextBlock: React.FC<IGifTextBlockProps> = ({ title, subTitle, img }) => (
  <div className={styles.wrapper}>
    <div className='max-w-[150px] max-h-[150px] mx-auto'>
      <img src={img} alt='gif' />
    </div>
    <Text className={styles.title} as='span'>
      {title}
    </Text>
    <Text className={styles.subTitle} as='span'>
      {subTitle}
    </Text>
  </div>
);

export default GifTextBlock;
