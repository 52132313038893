import React, { MouseEvent } from 'react';
import { Button } from '../../../../../../components/Button/Button';
import styles from './styles.module.scss';
import { IQuestTestOption } from '../../../../types';

export interface ISelectedTest {
  question: string;
  answer: IQuestTestOption;
}

export type OnSelectTest = (selected: ISelectedTest, event?: MouseEvent<HTMLButtonElement>) => void;

interface ITestQuestionProps {
  question: string;
  options: Array<IQuestTestOption>;
  selected: IQuestTestOption;
  onSelect: OnSelectTest;
}

export const TestQuestion: React.FC<ITestQuestionProps> = ({
  question = '',
  options = [],
  selected = {},
  onSelect,
}) => (
  <>
    <div className={styles.title}>{question}</div>
    <div className={styles.answers}>
      {options.map(answer => {
        const isSelected = answer._id === (selected as IQuestTestOption)?._id;
        return (
          <Button
            key={answer._id}
            padding='16px'
            color={isSelected ? 'var(--text-primary-color)' : 'var(--text-tertiary-color)'}
            border={
              isSelected ? '2px solid var(--border-primary-color)' : '1px solid var(--border-secondary-color)'
            }
            onClick={e => onSelect?.({ question, answer }, e)}
            secondary
            fullWidth
            style={{
              textWrap: 'wrap',
            }}
          >
            {answer.text}
          </Button>
        );
      })}
    </div>
  </>
);

export default TestQuestion;
