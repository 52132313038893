import React, { useState } from 'react';
import { toast } from 'react-toastify';
// component
import { Button } from '@chakra-ui/react';
import { Layout } from '../../components/Layout';
import { BuyAssistantButton } from '../../components/BuyAssistant';
import { StorageKey } from '../../types';

export const Bkd: React.FC = () => {
  const [backdoorId, setBackdoorId] = useState<string>('');

  const saveBackdoorID = async (): Promise<void> => {
    try {
      await localStorage.setItem(StorageKey.BackdoorID, backdoorId);
      toast.success('added');
    } catch (e) {
      toast.error('some error');
      console.warn(e);
    }
  };

  const onClearLS = (): void => localStorage.clear();

  return (
    <Layout>
      <div className='grow flex justify-center items-center flex-col gap-2 px-[16px]'>
        <input
          className={'border-2 w-full h-[40px] p-1'}
          value={backdoorId}
          onChange={e => setBackdoorId(e.target.value)}
          type='text'
        />
        <Button variant='primary' className='w-full' onClick={saveBackdoorID}>
          add
        </Button>
        <Button variant='primary' className='w-full' onClick={onClearLS}>
          clear local storage
        </Button>
      </div>
      <div style={{ padding: '16px' }}>
        <BuyAssistantButton />
      </div>
    </Layout>
  );
};
