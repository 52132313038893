import React, { useState, useEffect, useCallback, MutableRefObject } from 'react';
import useSWR from 'swr';
import { fetcher } from '../api';
import { toast } from 'react-toastify';

export interface IUseIntervalOptions {
  revalidateOnFocus?: boolean;
  refreshInterval?: number;
  revalidateIfStale?: boolean;
}

export const useInfiniteScroll = (
  apiUrl: string,
  limit = 20,
  withoutCacheKey?: MutableRefObject<number>,
  filters?: any,
  options: IUseIntervalOptions = {
    revalidateOnFocus: true,
    refreshInterval: 10000,
    revalidateIfStale: true,
  }
): {
  totalItems?: number;
  items: Array<any>;
  setItems?: (items: Array<any>) => void;
  mutate?: (url: string, shouldRevalidate?: boolean) => void;
  error: string;
  isLoading?: boolean;
  handleScroll: React.UIEventHandler<HTMLDivElement>;
  totalAvailableBonuses?: number;
  isMounted?: boolean;
  removeItemById?: (id: number) => void;
  refreshAllData?: (url?: string) => Promise<void>;
  isValidating?: boolean;
  setCurrentPage?: (page: number) => void;
  resetLocalData?: () => Promise<void>;
} => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [items, setItems] = useState<Array<any>>([]);
  const [isReachingEnd, setIsReachingEnd] = useState<boolean>(false);
  const [totalAvailableBonuses, setTotalAvailableBonuses] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const queryFilters = filters && filters;

  const swrKey = !!withoutCacheKey
    ? `${apiUrl}?page=${currentPage}&limit=${limit}&key=${withoutCacheKey.current}${queryFilters || ''}`
    : `${apiUrl}?page=${currentPage}&limit=${limit}${queryFilters || ''}`;

  const { data, error, isLoading, mutate, isValidating } = useSWR(apiUrl ? swrKey : '', fetcher, options);

  useEffect(() => {
    if (data) {
      setTotalItems(data?.total);
      setTotalAvailableBonuses(Number(data?.totalAvailableBonuses || 0));
      const newItems =
        data?.result?.filter((newItem: any) => !items.some(item => item.id === newItem.id)) || [];
      setItems(prevItems => [...prevItems, ...newItems]);
      const totalPages = Math.ceil(data.total / limit);
      if (currentPage >= totalPages) {
        setIsReachingEnd(true);
      }
      setIsMounted(true);
    }
  }, [data, limit, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
    event => {
      const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLDivElement;
      if (scrollHeight - scrollTop <= clientHeight + 1) {
        if (!isReachingEnd && !isLoading) {
          setCurrentPage(prevPage => prevPage + 1);
        }
      }
    },
    [isReachingEnd, isLoading]
  );

  const refreshAllData = async (url = ''): Promise<void> => {
    const allItems = [];
    for (let page = 1; page <= currentPage; page++) {
      const response = await fetcher(`${url || apiUrl}?page=${page}&limit=${limit}`);
      if (response?.result) {
        allItems.push(...response.result);
      }
    }
    setItems(allItems);
  };

  const resetLocalData = async (): Promise<void> => {
    await setCurrentPage(1);
    await setIsReachingEnd(false);
    await setItems([]);
  };

  const removeItemById = (id: number): void => {
    setItems(prevItems => prevItems.filter(item => item.id !== id));
  };

  if (error) {
    toast.error(error.message);
    return { items: [], error, handleScroll };
  }

  return {
    totalItems,
    items,
    setItems,
    mutate,
    error,
    isLoading,
    handleScroll,
    totalAvailableBonuses,
    isMounted,
    removeItemById,
    refreshAllData,
    isValidating,
    setCurrentPage,
    resetLocalData,
  };
};

export default useInfiniteScroll;
