import React, { useContext } from 'react';
// components
import { Flex, Text } from '@chakra-ui/react';
import { CheckIcon, Icon } from '@chakra-ui/icons';
// other
import { TABS_LIST } from '../Diamonds';
import { DiamondFilterContext } from '../../../context/DiamondFilterContext';
import { useTelegramBackButton } from '../../../hooks/useTelegramBackButton';
import { ROUTES } from '../../../constants';

export const OPTIONS = [
  { label: 'Relevance', value: 'relevant' },
  { label: 'Price: low to high', value: 'priceAsc' },
  { label: 'Price: high to low', value: 'priceDesc' },
];

interface ISortByProps {
  onCloseModal: () => void;
  resetLocalData?: () => void;
}

export const SortBy: React.FC<ISortByProps> = ({ onCloseModal, resetLocalData }) => {
  const context = useContext(DiamondFilterContext);
  if (!context) {
    throw new Error('Must use within DiamondFilterProvider');
  }
  const { sortOption, setSortOption } = context;
  useTelegramBackButton(`${ROUTES.diamonds}?tab=${TABS_LIST.sale.text}`, onCloseModal);

  const onHandleSort = async (value: string) => {
    await resetLocalData?.();
    await setSortOption(value);
  };

  return (
    <Flex
      background={'white'}
      flexDirection='column'
      alignItems='flex-start'
      height='100%'
      width='100%'
      padding='16px'
    >
      <Text as='span' fontSize='13px' marginBottom='16px' color='secondaryColor'>
        SORT BY
      </Text>
      {OPTIONS.map(el => (
        <button
          onClick={() => onHandleSort(el.value)}
          key={el.value}
          className='mb-[16px] font-bold text-[20px] flex items-center justify-between w-full'
        >
          {el.label}
          {sortOption === el.value && (
            <Icon maxWidth='18px' marginRight='5px' as={CheckIcon} w={7} h={7} color='black' />
          )}
        </button>
      ))}
    </Flex>
  );
};
