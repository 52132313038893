import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
// components
import { Box, Button, Flex, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { FullPageLoader } from '../../components/FullPageLoader';
import { Confetti } from '../../components/Confetti';
import { Navbar } from '../../components/Navbar';
// styles
import glassesGif from '../../assets/images/gif/glasses.gif';
// other
import { API, fetcher, loggedAxios } from '../../api';
import { ROUTES } from '../../constants';
import { Icon } from '../../components/Icon';

export const Welcome: React.FC = () => {
  const { isLoading, data } = useSWR(API.userInfo, fetcher);
  const [isClaimLoading, setIsClaimLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onClaim = async (): Promise<void> => {
    setIsClaimLoading(true);
    try {
      await loggedAxios.post(API.claimInvitation);
      setIsClaimLoading(false);
      navigate(ROUTES.onboardingStep1);
    } catch (e) {
      console.warn(e);
      toast.error('Something went wrong, try again later');
    } finally {
      setIsClaimLoading(false);
    }
  };

  if (isLoading) return <FullPageLoader />;

  return (
    <Flex direction='column' height='100vh'>
      <Box display='flex' flexGrow={1} justifyContent='center' alignItems='center' textAlign='center'>
        <VStack spacing={5} align='center' textAlign='center'>
          <Box width='350px' height='250px' display='flex' justifyContent='center' alignItems='center'>
            <Image src={glassesGif} alt='Diamore Logo' boxSize='220px' objectFit='contain' />
          </Box>
          {data?.invitationReward && (
            <Box
              maxWidth='max-content'
              padding='9px 16px'
              display='flex'
              gap='3px'
              alignItems='center'
              borderRadius='48px'
              background='#f4f4f4'
            >
              {data.invitationReward} <Icon />
            </Box>
          )}
          <VStack spacing={2} maxWidth='350px' align='center' textAlign='center'>
            <Text fontSize='32px' fontFamily='heading' lineHeight='1'>
              Welcome to Diamore
            </Text>
            <Text fontSize='16px' color='gray.600' fontFamily='body' lineHeight='1'>
              A new user is a new gift
            </Text>
          </VStack>
        </VStack>
      </Box>
      <Box padding='0 16px'>
        <Button variant={'primary'} onClick={onClaim} width='100%'>
          {isClaimLoading ? (
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='gray.900' size='lg' />
          ) : (
            'Claim'
          )}
        </Button>
      </Box>
      <Navbar />
      <Confetti />
    </Flex>
  );
};

export default Welcome;
