import React from 'react';
// components
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { AdaptiveHeightScroll } from '../../../../components/Scroll';
import { BannersList } from './Banner';
import { TaskCard } from './TaskCard';
// other
import { useQuestsInfo } from '../../hooks/useQuestsInfo';

export const Quests: React.FC = () => {
  const { loading, quests, mutateQuests } = useQuestsInfo();
  const now = new Date();

  const ongoingQuests = quests.filter(q => q.status !== 'completed' && !q.hidden);

  const completedQuests = quests.filter(q => {
    if (q.status === 'completed' && !q.hidden) {
      const completedAt = new Date(q.completedAt);
      const isWithin24Hours = now.getTime() - completedAt.getTime() <= 24 * 60 * 60 * 1000;

      return isWithin24Hours;
    }
  });

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Box display='flex' flexDirection='column' flex='1'>
          <AdaptiveHeightScroll>
            <BannersList />
            {/* Rendering ongoing quests */}
            {ongoingQuests.length > 0 && (
              <Flex flexDirection='column'>
                {ongoingQuests.map(item => (
                  <TaskCard key={item._id} quest={item} mutateQuests={mutateQuests} />
                ))}
              </Flex>
            )}

            {/* Rendering completed quests */}
            {completedQuests.length > 0 && (
              <Flex flexDirection='column' marginTop='32px' transition='all 0.3s ease'>
                <Text alignSelf='flex-start' paddingX='0px' color='#6b6b6b' fontSize='20px'>
                  Finished
                </Text>
                {completedQuests.map(item => (
                  <TaskCard key={item._id} quest={item} mutateQuests={mutateQuests} />
                ))}
              </Flex>
            )}
          </AdaptiveHeightScroll>
        </Box>
      )}
    </>
  );
};

export default Quests;
