import axios from 'axios';

const BASE_URL = 'https://tganalytics.xyz';
const TELEGRAM_ANALYTICS_TOKEN = String(process.env.REACT_APP_TELEGRAM_ANALYTICS_TOKEN);

interface TgAnalyticsEventOptions {
  [key: string]: any;
}

const telegramAnalyticsApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'TGA-Auth-Token': TELEGRAM_ANALYTICS_TOKEN,
    'Content-Type': 'application/json',
  },
});

export const sendTgAnalyticsEvent = async (
  eventName: string,
  userId: number,
  sessionId?: string,
  appName?: string,
  additionalOptions: TgAnalyticsEventOptions = {}
) => {
  try {
    const response = await telegramAnalyticsApi.post('/events', {
      user_id: userId,
      event_name: eventName,
      session_id: sessionId,
      app_name: appName,
      ...additionalOptions,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending event:', error);
    throw error;
  }
};
