import React, { createContext, useState, useEffect, ReactNode, SetStateAction } from 'react';
import useSWR from 'swr';
import { OPTIONS } from '../pages/Diamonds/Filters';
import { API, fetcher } from '../api';
import { StorageKey } from '../types';

export interface IDiamondInitFilters {
  weight: { min: number | null; max: number | null };
  price: { min: number | null; max: number | null };
}

export interface IDiamondFilters extends IDiamondInitFilters {
  shape: Array<string>;
  clarity: Array<string>;
  color: Array<string>;
}

export type IDiamondFiltersOptions = {
  [Key in keyof IDiamondFilters]: Array<string>;
};

export interface IDiamondFilterState {
  initialValues: IDiamondInitFilters;
  options: IDiamondFiltersOptions;
  filters: IDiamondFilters;
  sortOption: string;
  isLoading: boolean;
  setFilters: React.Dispatch<SetStateAction<IDiamondFilters>>;
  setSortOption: React.Dispatch<SetStateAction<string>>;
}

export const DiamondFilterContext = createContext<IDiamondFilterState | undefined>(undefined);

export const DiamondFilterProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { data: options, isLoading } = useSWR(API.filterOptions, fetcher);

  const [initialValues, setInitialValues] = useState<IDiamondInitFilters>({
    weight: { min: null, max: null },
    price: { min: null, max: null },
  });

  const [filters, setFilters] = useState<IDiamondFilters>(() => {
    const savedFilters = localStorage.getItem(StorageKey.DiamondFilters);
    return savedFilters
      ? JSON.parse(savedFilters)
      : {
          shape: [],
          clarity: [],
          color: [],
          weight: { min: null, max: null },
          price: { min: null, max: null },
        };
  });

  const [sortOption, setSortOption] = useState<string>(() => {
    const savedSortOption = localStorage.getItem(StorageKey.DiamondSortOption);
    return savedSortOption || OPTIONS[0].value;
  });

  useEffect(() => {
    if (options) {
      setFilters(prevFilters => {
        const newFilters = { ...prevFilters };

        if (!prevFilters.weight.min && !prevFilters.weight.max) {
          newFilters.weight = { min: Number(options.weight[0]), max: Number(options.weight[1]) };
        }

        if (!prevFilters.price.min && !prevFilters.price.max) {
          newFilters.price = { min: Number(options.price[0]), max: Number(options.price[1]) };
        }

        return newFilters;
      });

      setInitialValues({
        weight: { min: Number(options.weight[0]), max: Number(options.weight[1]) },
        price: { min: Number(options.price[0]), max: Number(options.price[1]) },
      });
    }
  }, [options]);

  useEffect(() => {
    localStorage.setItem(StorageKey.DiamondFilters, JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    localStorage.setItem(StorageKey.DiamondSortOption, sortOption);
  }, [sortOption]);

  return (
    <DiamondFilterContext.Provider
      value={{ filters, setFilters, sortOption, setSortOption, isLoading, options, initialValues }}
    >
      {children}
    </DiamondFilterContext.Provider>
  );
};
