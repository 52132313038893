import { RefObject, useEffect } from 'react';

export const usePreventSwipeClose = <T extends HTMLElement>(ref: RefObject<T>): void =>
  useEffect(() => {
    const overflow = 5;
    document.body.style.overflowY = 'hidden';
    document.body.style.marginTop = `${overflow}px`;
    document.body.style.height = window.innerHeight + overflow + 'px';
    document.body.style.paddingBottom = `${overflow}px`;
    window.scrollTo(0, overflow);

    let ts: number;

    const onTouchStart = (event: TouchEvent) => {
      ts = event.touches[0].clientY;
    };

    const onTouchMove = (event: TouchEvent) => {
      if (ref) {
        const scroll = ref?.current?.scrollTop as number;
        const te = event.changedTouches[0].clientY;
        if (scroll <= 0 && ts < te) {
          event.preventDefault();
        }
      } else {
        event.preventDefault();
      }
    };

    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });

    return () => {
      document.documentElement.removeEventListener('touchstart', onTouchStart);
      document.documentElement.removeEventListener('touchmove', onTouchMove);
    };
  }, [ref]);
