import { useEffect, useState } from 'react';

export const useMount = (startAnimation: boolean, animationTimeMs: number): { mounted: boolean } => {
  const [mounted, setMounted] = useState<boolean>(startAnimation);

  useEffect(() => {
    if (startAnimation) {
      setMounted(true);
    } else {
      const timeoutId = setTimeout(() => setMounted(false), animationTimeMs);
      return () => clearTimeout(timeoutId);
    }
  }, [startAnimation, animationTimeMs]);

  return { mounted };
};
