import React, { useRef, useEffect, useState, useMemo } from 'react';
import {
  Scene,
  PlaneGeometry,
  TextureLoader,
  MeshBasicMaterial,
  Mesh,
  OrthographicCamera,
  WebGLRenderer,
  Object3DEventMap,
} from 'three';
import diamond from '../../../assets/diamond.svg';

interface IFloatersRef {
  id?: string;
  mesh: Mesh<PlaneGeometry, MeshBasicMaterial, Object3DEventMap>;
  initialY: number;
  isExpired: boolean;
}

interface IClickerFloatersProps {
  clickFloaters: Array<{ x: number; y: number; id: string }>;
}

export const ClickerFloaters: React.FC<IClickerFloatersProps> = ({ clickFloaters }) => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef(new Scene());
  const floatersRef = useRef<Array<IFloatersRef>>([]);
  const rendererRef = useRef<WebGLRenderer | null>(null);
  const cameraRef = useRef<OrthographicCamera>(
    new OrthographicCamera(
      window.innerWidth / -2,
      window.innerWidth / 2,
      window.innerHeight / 2,
      window.innerHeight / -2,
      1,
      1000
    )
  );
  const [processedFloaters, setProcessedFloaters] = useState<Set<string>>(new Set());

  const MAX_FLOATERS = 50;

  const geometry = useMemo(() => new PlaneGeometry(32, 32), []);
  const texture = useMemo(() => new TextureLoader().load(diamond), []);

  useEffect(() => {
    const scene = sceneRef.current;
    const camera = cameraRef.current;
    camera.position.z = 10;

    const renderer = new WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    canvasRef.current?.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const animate = () => {
      requestAnimationFrame(animate);
      floatersRef.current.forEach(floater => {
        floater.mesh.position.y += 2;
        const distanceTraveled = floater.mesh.position.y - floater.initialY;

        if (distanceTraveled > 50) {
          floater.mesh.material.opacity = 0.8 * (1 - (distanceTraveled - 50) / 50);
        }

        if (distanceTraveled >= 100) {
          scene.remove(floater.mesh);
          floater.isExpired = true;
        }
      });
      floatersRef.current = floatersRef.current.filter(floater => !floater.isExpired);
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      renderer.dispose();
    };
  }, []);

  useEffect(() => {
    const scene = sceneRef.current;

    const newProcessedFloaters = new Set(processedFloaters);

    clickFloaters.forEach(({ x, y, id }) => {
      if (!newProcessedFloaters.has(id)) {
        const material = new MeshBasicMaterial({ map: texture, transparent: true, opacity: 0.8 });
        const mesh = new Mesh(geometry, material);
        const initialY = -(y - window.innerHeight / 2);
        mesh.position.set(x - window.innerWidth / 2, initialY, 0);
        scene.add(mesh);
        floatersRef.current.push({ mesh, initialY, isExpired: false });
        newProcessedFloaters.add(id);
      }
    });

    // Remove excess floaters
    while (floatersRef.current.length > MAX_FLOATERS) {
      const floater = floatersRef.current.shift() as IFloatersRef;
      scene.remove(floater.mesh);
      newProcessedFloaters.delete(floater.id as string);
    }

    setProcessedFloaters(newProcessedFloaters);
  }, [clickFloaters]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div ref={canvasRef} className='fixed top-0 left-0 w-full h-full pointer-events-none z-20' />;
};

export default ClickerFloaters;
