import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
// components
import { OnboardingCard } from './components';
// styles
import diamoreLogo from '../../assets/images/diamoreLogo.png';
// other
import { ROUTES } from '../../constants';

export const Screen1: React.FC = () => {
  const navigate = useNavigate();

  const hendleRedirect = () => navigate(ROUTES.onboardingStep2);

  return (
    <OnboardingCard
      title='Diamore is'
      description='A Web3 project backed by a real diamond business.'
      img={<Image src={diamoreLogo} alt='Diamore Logo' boxSize='220px' objectFit='contain' />}
      btn={{ text: 'Next', onClick: hendleRedirect }}
    />
  );
};

export default Screen1;
