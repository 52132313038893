import { getHttpEndpoint } from '@orbs-network/ton-access';
import { TonClient } from 'ton';
import { useAsyncInitialize } from './useAsyncInitialize';
import { useTonConnect } from './useTonConnect';
import { INetwork } from '../types';

export const useTonClient = (): {
  network: INetwork | null;
  client: TonClient | undefined;
} => {
  const { network } = useTonConnect();

  return {
    network,
    client: useAsyncInitialize(async () => {
      if (!network) return;

      return new TonClient({
        endpoint: await getHttpEndpoint({
          network: network.name,
        }),
      });
    }, []),
  };
};
