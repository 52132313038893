import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
// components
import { Banner } from '../Banner';
// styles
import unicornGif from '../../../../assets/unicorn.gif';
import styles from './styles.module.scss';
// other
import { API, fetcher } from '../../../../../../api';
import { ROUTES } from '../../../../../../constants';

export const MyChipsBanner: React.FC = () => {
  const navigate = useNavigate();

  const { data: myChips, isLoading } = useSWR(API.myChipsAdUnit, fetcher);

  const onOpen = () => navigate(`${ROUTES.myChips}?url="${myChips.url}"`);

  const classNames = {
    imageBox: styles.imageWrapper,
  };

  return (
    <>
      {!isLoading && myChips.isEnabled && (
        <Banner
          title='Play 2 Earn'
          description='Play games to earn points'
          imageSrc={unicornGif}
          classNames={classNames}
          onClick={onOpen}
        />
      )}
    </>
  );
};
