import React, { MouseEventHandler, ReactNode, TouchEventHandler, useRef } from 'react';
// components
import { GAME_STATE_VARIANTS } from '../ClickerViewProvider';
// styles
import bg from '../../../assets/bg.png';
import brilliant from '../../../assets/brilliantx2.png';
import styles from './styles.module.scss';
// other
import { usePreventSwipeClose } from '../../../../../hooks/usePreventSwipeClose';

interface IPlaygroundProps {
  gameViewState: number;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onTouchEnd?: TouchEventHandler<HTMLDivElement>;
}

export const Playground: React.FC<IPlaygroundProps> = ({ gameViewState, children, onClick, onTouchEnd }) => {
  const diamondRef = useRef<HTMLDivElement>(null);
  usePreventSwipeClose(diamondRef);

  return (
    <div ref={diamondRef} className={styles.button} onClick={onClick} onTouchEnd={onTouchEnd}>
      <div className={styles.bg}>
        <img loading='lazy' src={bg} alt='' />
      </div>
      {GAME_STATE_VARIANTS.gameFinishAnimation !== gameViewState && (
        <div className={styles.brilliant}>
          <img loading='lazy' src={brilliant} alt='' />
        </div>
      )}
      {children}
    </div>
  );
};

export default Playground;
