import { useEffect, useState } from 'react';
import { Address } from 'ton-core';
import { useAsyncInitialize } from '../../../hooks/useAsyncInitialize';
import { useTonClient } from '../../../hooks/useTonClient';
import { useTonConnect } from '../../../hooks/useTonConnect';
import { useInterval } from '../../../hooks/useInterval';

import { NftCollection } from '../constants/wrappers/NftMinter';
import { INetwork } from '../../../types';

interface IProgressState {
  completedMint: number;
  isProgressFull: boolean;
}

export interface IProgress extends IProgressState {
  totalMint: number;
}

const contractAddress = process.env.REACT_APP_NFT_ADDRESS && Address.parse(process.env.REACT_APP_NFT_ADDRESS);
const totalMint = 10000;

export const useTonNFTContract = (
  config = { onlyFirstLoading: false }
): {
  network: INetwork | null;
  loading: boolean;
  progress: IProgress;
  walletAddress: string;
  mint: () => Promise<void> | undefined;
} => {
  const { onlyFirstLoading } = config;

  const firstLoading = onlyFirstLoading;

  const { client, network } = useTonClient();
  const { wallet, sender } = useTonConnect();

  const [loading, setLoading] = useState<boolean>(firstLoading);
  const [progress, setProgress] = useState<IProgressState>({
    completedMint: 0,
    isProgressFull: true,
  });

  const tonNFTContract = useAsyncInitialize(async () => {
    if (!client || !wallet || !contractAddress) return;

    const contract = NftCollection.createFromAddress(contractAddress);

    return client.open(contract);
  }, [client, wallet]);

  const updateProgress = async () => {
    !onlyFirstLoading && !loading && setLoading(true);
    if (!tonNFTContract) return;

    const collection = await tonNFTContract.getCollectionData();
    const completedMint = collection.nextItem;
    const isProgressFull = totalMint === completedMint;
    const needUpdateProgress = completedMint !== progress.completedMint;

    needUpdateProgress && setProgress({ completedMint, isProgressFull });
    loading && setLoading(false);
  };

  useEffect(() => {
    updateProgress();
  }, [tonNFTContract]); // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(updateProgress, { ms: 30000, active: !progress.isProgressFull });

  return {
    network,
    loading,
    progress: { totalMint, ...progress },
    walletAddress: tonNFTContract?.address.toString(),
    mint: () => tonNFTContract?.sendCustomMint(sender, 50000000n),
  };
};
