import React from 'react';
import { toast } from 'react-toastify';
// components
import { Box, Link, Text } from '@chakra-ui/react';
import { AdaptiveHeightScroll } from '../../../../../../components/Scroll';
import { IOnboardingStep, OnboardingSteps } from '../../../../../../components/OnboardingSteps';
// other
import { IUser } from '../../../../../../types';
import { IQuest } from '../../../../types';
import { StepInput } from '../../StepInput';

interface IQuestsStepsProps {
  quest: IQuest;
  userInfo: IUser;
}

export const QuestsSteps: React.FC<IQuestsStepsProps> = ({ quest, userInfo }) => {
  const copyToClipboard = (text: string): void => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('LOGIN COPIED TO CLIPBOARD');
      })
      .catch(error => {
        console.error('Failed to copy text: ', error);
      });
  };

  const renderLabel = ({ label }: IOnboardingStep): string | JSX.Element => {
    const usernamePlaceholder = '%%username%%';
    const urlPlaceholder = '%%url%%';
    const prizePlaceholder = '%%prize%%';
    const input = '%%input%%';

    if (label.includes(usernamePlaceholder)) {
      const username = userInfo?.username || '@durov(change it to your username)';
      const parts = label.split(usernamePlaceholder);

      return (
        <>
          {parts[0]}
          <Text
            as='span'
            cursor='pointer'
            color='secondaryColor'
            display='contents'
            onClick={() => copyToClipboard(username)}
          >
            {username}
          </Text>
          {parts[1]}
        </>
      );
    }

    if (label.includes(urlPlaceholder))
      return (
        <>
          <Link href={quest.url} target='_blank' cursor='pointer' textDecoration='underline'>
            {label.slice(7)}
          </Link>
        </>
      );

    if (label.includes(prizePlaceholder)) return label.replace(prizePlaceholder, quest?.bonusClicks as never); // need change logic
    if (label.includes(input)) return <StepInput questType={quest?.checkType} />;

    return label;
  };

  const stepsWithIcons = quest?.steps?.map(step => ({ label: step.text, icon: step.bullet }));

  return (
    <Box display='flex' flex='1' width='100%'>
      <AdaptiveHeightScroll>
        <OnboardingSteps steps={stepsWithIcons} renderLabel={renderLabel} />
      </AdaptiveHeightScroll>
    </Box>
  );
};

export default QuestsSteps;
