import React, { MouseEventHandler } from 'react';
// components
import { Button } from '../../../../../../components/Button';
// styles
import badgeBlack from '../../../../assets/badgeBlack.svg';
import badgeGold from '../../../../assets/badgeGold.svg';
import styles from './styles.module.scss';
// other
import { IQuestTestOption } from '../../../../types';

interface ITestResultProps {
  bonus: string;
  result: Array<IQuestTestOption>;
  onReturn: MouseEventHandler<HTMLButtonElement>;
  onRetry: MouseEventHandler<HTMLButtonElement>;
}

const text = {
  correct: {
    title: 'Awesome! We knew this would be easy for you',
    description: 'You are brilliant! You answered all the questions correctly — here is your reward',
    tag: '+1 500 💎',
  },
  partiallyCorrect: {
    title: 'Continue learning and challenging yourself!',
    description:
      'Well done! However, it seems that not all the questions were answered correctly. Review the material and try again to claim your reward.',
    tag: '🔒 Reward',
  },
};

export const TestResult: React.FC<ITestResultProps> = ({ bonus, result = [], onReturn, onRetry }) => {
  const answersCount = result.length;
  const correctAnswersCount = result.filter(answer => answer.isCorrect).length;
  const isAllCorrect = answersCount === correctAnswersCount;

  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <div className={styles.badge}>
          <img src={isAllCorrect ? badgeGold : badgeBlack} alt='bage' width={128} height={128} />
          <span>
            {correctAnswersCount}/{answersCount}
          </span>
        </div>
        <p className={styles.badgeCaption}>Answered correctly</p>
        <h3 className={styles.title}>{isAllCorrect ? text.correct.title : text.partiallyCorrect.title}</h3>
        <p className={styles.description}>
          {isAllCorrect ? text.correct.description : text.partiallyCorrect.description}
        </p>
        <div className={styles.tag}>
          <span>{isAllCorrect ? bonus : text.partiallyCorrect.tag}</span>
        </div>
      </div>
      <div className={styles.navigation}>
        <Button fullWidth onClick={isAllCorrect ? onReturn : onRetry}>
          {isAllCorrect ? 'GOT IT!' : 'TRY AGAIN'}
        </Button>
        {!isAllCorrect && (
          <Button secondary fullWidth onClick={onReturn}>
            RETURN TO QUESTS
          </Button>
        )}
      </div>
    </div>
  );
};

export default TestResult;
