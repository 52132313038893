import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConnectedWallet, useTonWallet } from '@tonconnect/ui-react';
import { mutate } from 'swr/_internal';
import { AxiosError } from 'axios';
// components
import { Flex } from '@chakra-ui/react';
import { Button } from '../../../../../../components/Button';
import { TonWalletButton } from '../../../../../../components/TonWalletButton/TonWalletButton';
// styles
import arrowUpRightSVG from '../../../../assets/arrowUpRight.svg';
// other
import { API, loggedAxios } from '../../../../../../api';
import { useOpen } from '../../../../../../hooks/useOpen';
import { hapticImpact } from '../../../../../../utils/hapticFeedback';
import { isTelegramLink, openLink, openTelegramLink } from '../../../../../../utils/openTelegramLink';
import { ROUTES } from '../../../../../../constants';
import { StorageKey } from '../../../../../../types';
import { IQuest, Quest } from '../../../../types';
import { sendTgAnalyticsEvent } from '../../../../../../api/tganalytics';

interface IBottomNavBarProps {
  quest: IQuest;
  mutateQuest: () => Promise<void>;
}

export const BottomNavBar: React.FC<IBottomNavBarProps> = ({ quest, mutateQuest }) => {
  const navigate = useNavigate();

  const wallet = useTonWallet() as ConnectedWallet;

  const { _id, name, url, status } = quest || {};
  const isCompleted = status === 'completed';
  const isChecking = status === 'checking';
  const isConnectWalletQuest = quest?.checkType === 'walletConnection';
  const isQuizeTest = quest?.checkType === Quest.Quiz;
  const disabled = useOpen(true);
  const storageKey = `${_id}`;
  const localStorageWallet = typeof window !== 'undefined' && localStorage.getItem(StorageKey.Wallet);
  const appName = String(process.env.REACT_APP_NAME);
  const userId = typeof window !== 'undefined' && localStorage.getItem(StorageKey.TelegramUserId);

  useEffect(() => {
    if (localStorage.getItem(storageKey)) {
      disabled.onClose();
    }

    const sendWallet = async () => {
      try {
        await loggedAxios.post(API.walletConnect, {
          address: wallet?.account?.address,
          name: wallet?.appName,
        });
      } catch (error) {
        if (error instanceof AxiosError) {
          toast(error?.response?.data?.message);
        }
      }
    };

    if (wallet && isConnectWalletQuest && !localStorageWallet) {
      localStorage.setItem(storageKey, 'checking');
      disabled.onClose();
      sendWallet();
    }
  }, [storageKey, disabled, wallet, localStorageWallet, isConnectWalletQuest]);

  const handleQuestCompletion = async () => {
    const sessionId = localStorage.getItem(StorageKey.SessionId);
    await mutateQuest();
    disabled.onOpen();
    hapticImpact('medium');
    try {
      await loggedAxios.post(API.finishQuest, { questName: name });

      if (sessionId) {
        await sendTgAnalyticsEvent('custom-event', Number(userId), sessionId, appName, {
          client_timestamp: new Date().getTime().toString(),
          custom_data: {
            questId: _id,
            event_name: 'quest-completed',
          },
        }).then();
      }
    } catch (error) {
      console.warn(error);
      if (error instanceof AxiosError) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      await mutateQuest();
      await mutate(API.userInfo);
      disabled.onClose();
    }
  };

  const onOpenLink = async () => {
    const isTelegramUrl = isTelegramLink(url);
    hapticImpact('medium');
    localStorage.setItem(storageKey, 'checking');
    disabled.onClose();
    try {
      isTelegramUrl ? openTelegramLink(url) : openLink(url);
    } catch (e) {
      console.warn(e);
    }
  };

  const startTest = () =>
    navigate(`${ROUTES.quiz.replace(':id', _id)}?bonus=${quest.bonusClicks}&status=${quest.status}`);

  return (
    <Flex width='100%' padding='0 16px' direction='column' gap='8px'>
      {isQuizeTest ? (
        <>
          <Button fullWidth onClick={onOpenLink}>
            DISCOVER
            <img width='16px' height='10px' src={arrowUpRightSVG} alt='arrowUpRightSVG' />
          </Button>
          <Button secondary fullWidth onClick={startTest}>
            START TEST
          </Button>
        </>
      ) : (
        <>
          {isConnectWalletQuest ? (
            <TonWalletButton wallet={wallet} onClick={onOpenLink} />
          ) : (
            <Button fullWidth onClick={onOpenLink}>
              Let’s start
            </Button>
          )}
          <Button
            secondary
            fullWidth
            isDisabled={disabled.isOpen || isChecking || isCompleted}
            onClick={handleQuestCompletion}
          >
            {isCompleted ? 'Finished' : isChecking ? 'Checking' : 'Check the execution'}
          </Button>
        </>
      )}
    </Flex>
  );
};
