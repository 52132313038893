import React, { createContext, ReactNode, useState } from 'react';

export interface ILoadingState {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const LoadingContext = createContext<ILoadingState>({
  loading: false,
  setLoading: () => null,
});

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};
