import React from 'react';
import { useNavigate } from 'react-router-dom';
// styles
import styles from './styles.module.scss';
// other
import { hapticImpact } from '../../utils/hapticFeedback';

export interface ITab {
  text: string;
  notify?: boolean;
}

export interface ITabsProps {
  state: string;
  tabsList?: Array<ITab>;
  className?: string;
  setState?: (state: any) => void;
}

const defaults: Array<ITab> = [{ text: 'GAME' }, { text: 'QUESTS', notify: true }];

export const Tabs: React.FC<ITabsProps> = ({ state, setState, tabsList = defaults, className }) => {
  const navigate = useNavigate();

  const handleChangeTab = (tab: ITab) => {
    setState?.(tab.text);
    hapticImpact('soft');
    navigate(`?tab=${encodeURIComponent(tab.text)}`, { replace: true });
  };

  return (
    <div className={styles.tabs + '  ' + className}>
      {tabsList.map((tab, i) => {
        return (
          <button
            onClick={() => handleChangeTab(tab)}
            disabled={state === tab.text}
            className={`${styles.tab} ${
              state !== tab.text && i < tabsList.length - 1 && state !== tabsList[i + 1].text
                ? styles.rightBorder
                : ''
            }`}
            key={i}
          >
            {typeof tab === 'string' ? (
              tab
            ) : (
              <span className='relative text-nowrap'>
                {tab?.notify && <span className={styles.redPoint} />}
                {tab?.text}
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};
