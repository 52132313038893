import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// components
import { CustomInput } from '../../../../../components/Input/Input';
import { FormControl } from '@chakra-ui/react';
//styles
import styles from './styles.module.scss';
//other
import { Quest } from '../../../types';
import { loggedAxios } from '../../../../../api';
import {
  questAPI,
  questRequestBody,
  stepInputRegex,
  stepInputRegexError,
  TQuestAPI,
  TQuestTypeRegex,
  TQuestTypeRegexError,
} from '../../../constants';

const inputPlaceholders: Partial<Record<Quest, string>> = {
  [Quest.BybitConnection]: 'user ID ByBit',
};

interface IStepInputProps {
  questType: string;
}

export const StepInput: React.FC<IStepInputProps> = ({ questType }) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState(false);
  const [isApplyLoading, setIsApplyLoading] = useState(false);

  useEffect(() => {
    const savedInput = localStorage.getItem(questType);
    if (savedInput) {
      setInput(savedInput);
    }
  }, [questType]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value);

  const handleApply = async () => {
    const regex = stepInputRegex[questType as TQuestTypeRegex];

    if (!regex.test(input)) {
      setError(true);
      toast(stepInputRegexError[questType as TQuestTypeRegexError]);
      return;
    }
    setError(false);

    try {
      setIsApplyLoading(true);
      const requestBody = questRequestBody[questType as TQuestAPI](input);

      await loggedAxios.post(questAPI[questType as TQuestAPI], requestBody);
      toast('Successfully added');
      localStorage.setItem(questType, input);
    } catch (e: any) {
      console.error(e?.response?.data.message);
      toast(e?.response?.data.message);
    } finally {
      setIsApplyLoading(false);
    }
  };

  return (
    <FormControl>
      <CustomInput
        isLoading={isApplyLoading}
        placeholder={inputPlaceholders[questType as Quest] || ''}
        wrapperClassName={`${styles.wrapper} ${error ? styles.inputError : ''}`}
        input={input}
        handleInputChange={handleInputChange}
        handleApply={handleApply}
      />
    </FormControl>
  );
};
