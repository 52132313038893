import React, { ReactNode } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { FullPageLoader } from '../FullPageLoader';
import { Navbar } from '../Navbar';

export interface ILayoutProps extends FlexProps {
  loading?: boolean;
  children?: ReactNode;
}

export const Layout: React.FC<ILayoutProps> = ({ loading, children, ...props }) => (
  <Flex direction='column' justifyContent='space-between' height='100vh' {...props}>
    <Flex
      direction='column'
      justifyContent='space-between'
      width='100%'
      maxWidth='550px'
      height='100%'
      margin='0 auto'
    >
      {loading ? <FullPageLoader /> : <>{children}</>}
    </Flex>
    <Navbar />
  </Flex>
);

export default Layout;
