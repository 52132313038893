import React, { useState } from 'react';
import { Id, toast } from 'react-toastify';
import { AxiosError } from 'axios';
// components
import { List } from '../../../components/List';
import { ICardUpgrade, UpgradeCard } from '../components/Upgrades';
// other
import { msToTimeString } from '../../../utils/timeConverters';
import { IUpgrades, UpgradesOptionsKey } from '../types';
import { API, loggedAxios } from '../../../api';

export interface IUpgradeConfig {
  [key: string]: {
    title: string;
    cargo: {
      key: string;
      convertValue: (value: any) => string;
    };
  };
}

export interface IUpgradesProps {
  upgrades: IUpgrades;
  isLoading: boolean;
  error: AxiosError;
  onUpdate: () => void;
}

const upgradeConfig: IUpgradeConfig = {
  tapPower: {
    title: 'Upgrade clicking power',
    cargo: { key: 'value', convertValue: value => value + 'p/click' },
  },
  tapDuration: {
    title: 'Make timer longer',
    cargo: { key: 'durationMil', convertValue: ms => `${ms / 1000} sec` },
  },
  tapCoolDown: {
    title: 'Reduce cooldown timer',
    cargo: { key: 'coolDownMil', convertValue: ms => msToTimeString(ms) },
  },
};

export const Upgrades: React.FC<IUpgradesProps> = ({ upgrades, isLoading, error, onUpdate }) => {
  const [toastId, setToastId] = useState<Id | null>(null);

  const showToast = (message: string) => {
    const formattedMessage = message.toUpperCase();

    if (toastId) {
      toast.update(toastId, {
        render: formattedMessage,
      });
    } else {
      const newToastId = toast.dark(formattedMessage, {
        draggable: false,
        closeOnClick: true,
        onClose: () => setToastId(null),
      });
      setToastId(newToastId);
    }
  };

  const handleLevelUpgrade = ({ type }: ICardUpgrade) => {
    loggedAxios
      .post(API.buyUpgrade, {
        type,
      })
      .then(response => {
        showToast(response.data.message);
        onUpdate();
      })
      .catch(error => {
        showToast(error.response.data.message);
        console.error(error);
      });
  };

  return (
    <>
      <List title='LEVEL UP' isLoading={isLoading && !error}>
        {upgrades &&
          Object.keys(upgrades)?.map((type, key) => {
            const upgrade = upgradeConfig?.[type];

            if (!upgrade) return null;

            const [current, next] = upgrades?.[type as keyof typeof UpgradesOptionsKey];

            const { title, cargo } = upgrade;

            const currentCargo = current?.[cargo.key];
            const nextCargo = next?.[cargo.key];

            return (
              <UpgradeCard
                key={type + key}
                upgrade={{
                  type,
                  title,
                  level: current?.level,
                  price: next?.price,
                  cargo: {
                    from: currentCargo ? cargo.convertValue(currentCargo) : null,
                    to: nextCargo ? cargo.convertValue(nextCargo) : null,
                  },
                }}
                onUpgrade={handleLevelUpgrade}
              />
            );
          })}
      </List>
    </>
  );
};

export default Upgrades;
