import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// components
import { Flex } from '@chakra-ui/react';
import { Button, IButtonProps } from '../../Button';
// styles
import star from '../assets/assistantLogo.png';
// other
import { ROUTES } from '../../../constants';

export interface IBuyAssistantButtonProps extends IButtonProps {
  text?: string;
  children?: ReactNode;
}

export const BuyAssistantButton: React.FC<IBuyAssistantButtonProps> = ({
  text = 'Buy Assistant ›',
  children,
  ...buttonProps
}) => {
  const navigate = useNavigate();
  const openAssistant = () => navigate(ROUTES.assistant);

  return (
    <Button
      fontSize='16px'
      lineHeight='21.6px'
      letterSpacing='-0.03em'
      textTransform='capitalize'
      borderRadius='24px'
      padding='6px 16px'
      fullWidth
      background='linear-gradient(113.17deg, var(--bg-accent-color) 0.28%, #414141 4.96%, var(--bg-accent-color) 9.74%)'
      onClick={openAssistant}
      {...buttonProps}
    >
      {children || (
        <Flex justifyContent='space-between' alignItems='center' width='100%'>
          <img src={star} alt='🌟' width='32px' height='32px' />
          {text}
        </Flex>
      )}
    </Button>
  );
};

export default BuyAssistantButton;
