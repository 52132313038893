import { useTonWallet } from '@tonconnect/ui-react';
import { useMemo } from 'react';
import { CHAIN, toUserFriendlyAddress } from '@tonconnect/ui';

export const useTonAddress = (userFriendly = true): string => {
  const wallet = useTonWallet();
  return useMemo(() => {
    if (wallet) {
      return userFriendly
        ? toUserFriendlyAddress(wallet.account.address, wallet.account.chain === CHAIN.TESTNET)
        : wallet.account.address;
    } else {
      return '';
    }
  }, [wallet, userFriendly, wallet?.account.address, wallet?.account.chain]); // eslint-disable-line react-hooks/exhaustive-deps
};
