import React from 'react';
import { Button } from '@chakra-ui/react';
import { hapticImpact } from '../../../../../../utils/hapticFeedback';
import styles from './styles.module.scss';

export const ClickerGameReady: React.FC = () => (
  <div className={styles.wrap}>
    <Button className={styles.button} onTouchStart={() => hapticImpact('light')} type='button'>
      Tap to start game
    </Button>
    <span className={styles.text}>
      Tap the screen as fast as possible to get <br /> more diamonds!
    </span>
  </div>
);

export default ClickerGameReady;
