import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { hapticImpact } from '../../../../../utils/hapticFeedback';
import { BottomModal } from '../../../../../components/Modal/Bottom';
import { InvitationNavBar } from './NavBar';

interface IInviteProps {
  className?: string;
  label: string;
}

export const InviteBtn: React.FC<IInviteProps> = ({ className = '', label }) => {
  const [showNavbar, setShowNavBar] = useState<boolean>(false);

  const handleClick = (): void => {
    setShowNavBar(true);
    hapticImpact('medium');
  };

  return (
    <Box
      width='100%'
      zIndex={showNavbar ? '11' : '10'}
      padding='0 16px'
      display='flex'
      justifyContent='center'
    >
      <Button
        disabled={false}
        maxWidth='140px'
        width='100%'
        className={`${className}`}
        onClick={handleClick}
        variant='primary'
        borderRadius='8px'
      >
        {label}
      </Button>

      <BottomModal showModal={showNavbar} setShowModal={setShowNavBar}>
        <InvitationNavBar setShowModal={setShowNavBar} />
      </BottomModal>
    </Box>
  );
};
