import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { isAndroid, isIOS } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import useSWR from 'swr';
// components
import { FullPageLoader } from './components/FullPageLoader';
import { ErrorScreen } from './components/ErrorScreen';
import { DesktopPage } from './pages/DesktopPage/DesktopPage';
import { Welcome } from './pages/Welcome';
import { Purchase } from './pages/Diamonds/Purchase';
import { Screen1, Screen2, Screen3 } from './pages/Onboarding';
import { Home, Instruction, MyChips, Quiz, TapPads } from './pages/Home';
import { Diamonds } from './pages/Diamonds';
import { Community } from './pages/Community';
import { Nft } from './pages/Nft';
import { DailyReward } from './pages/DailyReward';
import { Bkd } from './pages/Bkd';
import { Details } from './pages/Diamonds/Details';
import { BuyAssistant } from './pages/BuyAssistant';
// styles
import 'react-toastify/dist/ReactToastify.css';
// other
import { ROUTES } from './constants';
import { API, fetcher, loggedAxios } from './api';
import { checkAndUpdateUserId } from './utils/checkAndUpdateUserId';
import { checkAppVersion } from './utils/checkAppVersion';
import { LoadingContext } from './context';
import { useInterval } from './hooks';
import { IUser, StorageKey } from './types';

interface IRoutingProps {
  balance: string;
  isInvitationReward: boolean;
}

const Routing: React.FC<IRoutingProps> = ({ balance, isInvitationReward }) => (
  <Router>
    <Routes>
      <Route
        path={ROUTES.root}
        element={
          <Navigate
            to={
              isInvitationReward ? ROUTES.welcome : Number(balance) > 0 ? ROUTES.home : ROUTES.onboardingStep1
            }
          />
        }
      />
      <Route path={ROUTES.welcome} element={<Welcome />} />
      <Route path={ROUTES.onboardingStep1} element={<Screen1 />} />
      <Route path={ROUTES.onboardingStep2} element={<Screen2 />} />
      <Route path={ROUTES.onboardingStep3} element={<Screen3 />} />
      <Route path={ROUTES.assistant} element={<BuyAssistant />} />
      <Route path={ROUTES.home} element={<Home />} />
      <Route path={ROUTES.questsInstruction} element={<Instruction />} />
      <Route path={ROUTES.quiz} element={<Quiz />} />
      <Route path={ROUTES.tapPads} element={<TapPads />} />
      <Route path={ROUTES.myChips} element={<MyChips />} />
      <Route path={ROUTES.dailyReward} element={<DailyReward />} />
      <Route path={ROUTES.community} element={<Community />} />
      <Route path={ROUTES.diamonds} element={<Diamonds />} />
      <Route path={ROUTES.diamondDetail} element={<Details />} />
      <Route path={ROUTES.diamondPurchase} element={<Purchase />} />
      <Route path={ROUTES.nft} element={<Nft />} />
      <Route path={ROUTES.bkd} element={<Bkd />} />
    </Routes>
  </Router>
);

const MobileOnly: React.FC = () => {
  const { loading } = useContext(LoadingContext);
  const { data: userData, isLoading: isLoadingUserInfo, error } = useSWR<IUser>(API.userInfo, fetcher);
  const { isLoading } = useSWR(API.questsList, fetcher);

  useInterval(checkAppVersion, { ms: 60000 });

  useEffect(() => {
    checkAppVersion();
  }, []);

  useEffect(() => {
    loggedAxios
      .post(API.logVisit)
      .then()
      .catch(e => {
        console.error(e);
      });

    checkAndUpdateUserId(userData?.id);
  }, [userData?.id]);

  if (error) {
    return <ErrorScreen />;
  }

  return (
    <>
      {(isLoadingUserInfo || isLoading || loading) && <FullPageLoader />}

      <div>
        <ToastContainer
          autoClose={3000}
          closeOnClick
          hideProgressBar={true}
          className={'custom-toast'}
          closeButton={false}
        />
        {userData && <Routing balance={userData?.balance} isInvitationReward={userData?.invitationReward} />}
      </div>
    </>
  );
};

export const App: React.FC = () => {
  const isMobile = isAndroid || isIOS;
  const platform = window?.Telegram?.WebApp.platform;
  const isMobileApp = platform === 'android' || platform === 'ios';
  const isTest = process.env.REACT_APP_TG_TOKEN;
  const manifestUrl = `${window.location.origin}/tonconnect-manifest.json`;
  const sessionId = crypto.randomUUID();
  const appName = String(process.env.REACT_APP_NAME);

  useEffect(() => {
    if (sessionId) {
      localStorage.setItem(StorageKey.SessionId, sessionId);
    }

    return () => {
      localStorage.removeItem(StorageKey.SessionId);
    };
  }, [sessionId]);

  useEffect(() => {
    try {
      window.telegramAnalytics.init({
        token: String(process.env.REACT_APP_TELEGRAM_ANALYTICS_TOKEN),
        appName: appName,
      });
      console.log('Telegram Analytics SDK initialized successfully'); // eslint-disable-line no-console
    } catch (error) {
      console.error('Error initializing Telegram Analytics SDK:', error);
    }
  }, [appName]);

  if (isTest || (isMobile && isMobileApp)) {
    return (
      <TonConnectUIProvider manifestUrl={manifestUrl}>
        <MobileOnly />
      </TonConnectUIProvider>
    );
  }
  return <DesktopPage />;
};
