import dayjs, { Dayjs } from 'dayjs';
import { IStage } from '../components/Stage/List';

interface IStages {
  data: Array<IStage>;
  getData: (isUserWhitelisted: boolean, dateNow?: Dayjs) => Array<IStage>;
}

export const stages: IStages = {
  data: [
    {
      id: 1,
      startDate: dayjs.tz('2024-08-15 13:00', 'UTC'),
      finishDate: dayjs.tz('2024-08-15 14:00', 'UTC'),
      whiteList: true,
      eligible: true,
    },
    {
      id: 2,
      startDate: dayjs.tz('2024-08-15 14:00', 'UTC'),
      finishDate: null,
      whiteList: false,
      eligible: true,
    },
  ],
  getData: function (isUserWhitelisted, dateNow = dayjs()) {
    return this.data.map(stage => {
      if (stage.whiteList) {
        const whiteListStage = {
          ...stage,
          eligible: isUserWhitelisted,
          description: stage.finishDate?.isAfter(dateNow) ? 'Duration: 1h' : 'Ended',
        };

        return isUserWhitelisted
          ? whiteListStage
          : Object.assign(whiteListStage, {
              btn: {
                as: 'a',
                href: 'https://t.me/diamoremarket/65',
                text: 'INFO',
                isDisabled: false,
              },
            });
      } else {
        return { btn: { text: stage.startDate.isBefore(dateNow) ? 'MINT' : undefined }, ...stage };
      }
    });
  },
};
