import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

export interface ITooltipProps extends TooltipProps {
  children: ReactElement;
  content: string | undefined;
  hasArrow?: boolean;
}

export const Tooltip: React.FC<ITooltipProps> = ({ children, content, hasArrow = true, ...tooltipProps }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const childWithRef = React.cloneElement(children, {
    ref: ref,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    onTouchStart: handleToggle,
  });

  return (
    <ChakraTooltip hasArrow={hasArrow} label={content} isOpen={isOpen} closeOnScroll {...tooltipProps}>
      {childWithRef}
    </ChakraTooltip>
  );
};
