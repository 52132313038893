import React from 'react';
// components
import { Tag } from '../../../../../components/Tag';
// styles
import diamond from '../../../../../assets/images/navbar/diamondChecked.svg';
import styles from './styles.module.scss';

export interface IPlanCardProps {
  type: string;
  title: string;
  description: string;
  benefits: Array<string>;
}

export const PlanCard: React.FC<IPlanCardProps> = ({ type, title, description, benefits = [] }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Tag className={styles.tag} text={type} />
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.description}>{description}</p>
    </div>
    <div className={styles.body}>
      {benefits.map((benefit, idx) => (
        <div key={benefit + idx} className={styles.benefit}>
          <img src={diamond} alt='diamond' />
          <p className={styles.benefitText}>{benefit}</p>
        </div>
      ))}
    </div>
  </div>
);
