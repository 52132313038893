import useSWR from 'swr';
import { API, fetcher } from '../../../api';
import { IQuest } from '../types';
import { IUser } from '../../../types';

export const useQuestsInfo = () => {
  const {
    data: quests,
    isLoading: isQuestsLoading,
    mutate: mutateQuests,
  } = useSWR<IQuest[]>(API.questsList, fetcher);

  const {
    data: user,
    isLoading: isUserLoading,
    mutate: mutateUser,
  } = useSWR<IUser>(API.userInfo, fetcher, { refreshInterval: 2 * 60 * 1000 });

  if (isQuestsLoading || isUserLoading || !quests || !user) {
    return { loading: true, quests: [] };
  }

  const mergedArray = quests.map(quest => {
    const userQuestInfo = user?.quests?.find(info => info.name === quest.name);
    return {
      ...quest,
      ...userQuestInfo,
    };
  });

  return { loading: false, quests: mergedArray, mutateUser, mutateQuests };
};
