import React from 'react';
// components
import { Layout } from '../../components/Layout/Layout';
import { Onboarding, Minting } from './containers';
import { AdaptiveHeightScroll } from '../../components/Scroll';
// other
import { useConnectWallet } from '../../hooks';
import { ConnectedWallet } from '@tonconnect/ui-react';

export const Nft: React.FC = () => {
  const wallet = useConnectWallet();

  return (
    <Layout>
      {wallet ? (
        <Minting wallet={wallet as ConnectedWallet} />
      ) : (
        <AdaptiveHeightScroll>
          <Onboarding wallet={wallet} />
        </AdaptiveHeightScroll>
      )}
    </Layout>
  );
};
