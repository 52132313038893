import React from 'react';
import { Box, Button, ButtonProps } from '@chakra-ui/react';

export interface ICloseButtonProps extends ButtonProps {
  background?: string;
}

export const CloseButton: React.FC<ICloseButtonProps> = ({ background = '#f5f5f5', ...buttonProps }) => (
  <Button
    _hover={{ backgroundColor: background }}
    backgroundColor={background}
    position='absolute'
    right='16px'
    top='16px'
    width='28px'
    height='28px'
    minW='28px'
    minH='28px'
    borderRadius='50%'
    color='#939393'
    display='flex'
    alignItems='center'
    justifyContent='center'
    padding='0'
    {...buttonProps}
  >
    <Box
      as='span'
      position='absolute'
      width='2.5px'
      height='16px'
      backgroundColor='#939393'
      transform='rotate(45deg)'
    />
    <Box
      as='span'
      position='absolute'
      width='2.5px'
      height='16px'
      backgroundColor='#939393'
      transform='rotate(-45deg)'
    />
  </Button>
);

export default CloseButton;
