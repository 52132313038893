import React from 'react';
import QRCode from 'qrcode.react';
import { Box, Text, VStack } from '@chakra-ui/react';

export const DesktopPage: React.FC = () => {
  const referral = window?.Telegram?.WebApp?.initDataUnsafe?.start_param;
  const redirectUrl = referral
    ? `https://t.me/DiamoreCryptoBot/app?startapp=${referral}`
    : `https://t.me/DiamoreCryptoBot/`;
  return (
    <Box height='100vh' display='flex' justifyContent='center' alignItems='center' p={4} textAlign='center'>
      <VStack spacing={6} align='center' textAlign='center'>
        <Box width='350px' height='250px' display='flex' justifyContent='center' alignItems='center'>
          <QRCode value={redirectUrl} size={200} renderAs='svg' level='H' includeMargin={true} />
        </Box>
        <VStack spacing={3} maxWidth='350px' align='center' textAlign='center'>
          <Text fontSize='32px' fontFamily='heading'>
            Play on mobile
          </Text>
          <Text fontSize='16px' color='gray.600' fontFamily='body'>
            @DiamoreCryptoBot
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};

export default DesktopPage;
