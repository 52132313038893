import dayjs, { Dayjs, duration } from 'dayjs';

dayjs.extend(duration);

export const msToTimeString = (milliseconds: number) => {
  const timeDuration = dayjs.duration(milliseconds);
  const hours = String(timeDuration.hours()).padStart(2, '0');
  const minutes = String(timeDuration.minutes()).padStart(2, '0');
  const seconds = String(timeDuration.seconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};

export const getDuration = (date1: Dayjs | string, date2: Dayjs | string) => {
  const hours = dayjs(date2).diff(dayjs(date1), 'hours');
  const days = Math.floor(hours / 24);
  return days ? `${days}d` : `${hours - days * 24}h`;
};

export const addHoursToDate = (hours = 0, date = new Date()) => date.setHours(date.getHours() + hours);
