import { useCallback, useMemo, useState } from 'react';

export const useOpen = (
  initOpen = false
): {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  setIsOpen: (open: boolean) => void;
} => {
  const [isOpen, setIsOpen] = useState(initOpen);

  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen(prev => !prev), []);

  return useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
      onToggle,
      setIsOpen,
    }),
    [isOpen, setIsOpen, onOpen, onClose, onToggle]
  );
};
