import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { InviteBtn } from './Btn';
import { Tips } from '../../../../../components/Tips/Tips';

const contentInfo =
  'Get 10% of DP earned by your friends, 2% earned by their friends and 1% of the 3rd friends row.';

interface IInviteFriendsProps {
  isFriends: boolean;
}

export const InviteFriends: React.FC<IInviteFriendsProps> = ({ isFriends }) => (
  <>
    <Box p={4} textAlign='center' width='350px' alignSelf='center'>
      <Text fontFamily='heading' fontSize='32px' position='relative'>
        Invite friends
        {isFriends && <Tips hasArrow={false} content={contentInfo} />}
      </Text>
      {!isFriends && (
        <Text fontFamily='body' fontSize='16px'>
          {contentInfo}
        </Text>
      )}
    </Box>
    <InviteBtn label='Invite friends' />
  </>
);
