import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
// components
import QuizTest from '../components/Quests/Test/Test';
import QuizResult from '../components/Quests/Test/Result/Result';
import { Layout } from '../../../components/Layout/Layout';
// other
import { API, fetcher, loggedAxios } from '../../../api';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { ISelectedTest } from '../components/Quests/Test/Question';
import { IQuestTest, IQuestTestOption } from '../types';
import { EmptyObject } from '../../../types';
import { ROUTES } from '../../../constants';

export const Quiz: React.FC = () => {
  const { bonus, status } = useQueryParams();
  const { id: quizId } = useParams();
  const navigate = useNavigate();

  const isCompleted = status === 'completed';

  const { data: tests = [], isLoading: isTestsLoading } = useSWR<Array<IQuestTest>>(
    API.questQuizOptions(quizId as string),
    fetcher
  );

  const [test, setTest] = useState<IQuestTest | EmptyObject>({});
  const [result, setResult] = useState<Array<IQuestTestOption>>([]);

  const currentQuestionIdx = tests?.findIndex(item => item._id === test?._id);
  const sequenceNumber = currentQuestionIdx + 1;
  const totalCount = tests?.length;

  useEffect(() => {
    if (isCompleted && result.length !== tests.length) {
      const newResult = tests.reduce((answers: Array<IQuestTestOption>, test) => {
        const correctAnswers = test.options.find(option => option.isCorrect);
        answers.push(correctAnswers as IQuestTestOption);
        return answers;
      }, []);
      setResult(newResult);
    } else {
      const nextTest = tests?.[result.length];
      setTest(nextTest);
    }
  }, [tests, result, isTestsLoading, isCompleted]);

  const onRetry = () => setResult([]);
  const onClose = () => navigate(`${ROUTES.home}?tab=QUESTS`);

  const handleNextClick = ({ answer }: ISelectedTest) => {
    const newResult = [...result, answer];
    setResult(newResult);

    if (newResult.length === tests.length) {
      const answers = newResult.map(answer => answer._id);
      loggedAxios.post(API.questQuizAnswer(quizId as string), { answers });
    }
  };

  const handlePrevClick = () => {
    if (sequenceNumber > 1) {
      const prevResult = result.slice(0, result.length - 1);
      setResult(prevResult);
    }
  };

  return (
    <Layout loading={isTestsLoading}>
      <div className='container'>
        {tests?.length && result.length !== tests.length ? (
          <QuizTest
            title={`Question ${sequenceNumber} of ${totalCount}`}
            question={test?.text}
            options={test?.options}
            onClickNext={handleNextClick}
            onClickPrev={handlePrevClick}
          />
        ) : (
          <>
            {result.length && (
              <QuizResult bonus={`+${bonus} 💎`} result={result} onRetry={onRetry} onReturn={onClose} />
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Quiz;
