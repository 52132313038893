import React from 'react';
// components
import { DailyRewardsBanner } from '../DailyRewards';
import { MyChipsBanner } from '../MyChips';
import { TapPadsBanner } from '../TapPads';
// styles
import styles from './styles.module.scss';

export const BannersList: React.FC = () => (
  <div className={styles.list}>
    <DailyRewardsBanner />
    <MyChipsBanner />
    <TapPadsBanner />
  </div>
);
