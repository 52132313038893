import React, { ReactNode } from 'react';
// styles
import styles from './styles.module.scss';

export interface IOnboardingStep {
  label: string;
  icon: string;
}

export interface IOnboardingStepsProps {
  steps: Array<IOnboardingStep>;
  children?: ReactNode;
  renderLabel?: (step: IOnboardingStep) => any;
}

export const OnboardingSteps: React.FC<IOnboardingStepsProps> = ({ steps = [], children, renderLabel }) => (
  <ul className={styles.steps}>
    {steps.map((step, key) => (
      <li key={key + step.label}>
        <div className={styles.icon}>{step.icon}</div>
        {renderLabel?.(step) || children || step.label}
      </li>
    ))}
  </ul>
);
