import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import dayjs from 'dayjs';
// components
import { Layout } from '../../../components/Layout';
import { GifTextBlock } from '../../../components/GifTextBlock';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Modal';
import { ITapPadsHistoryActivity, TapPadsActivity, TapPadsFeeds } from '../components/Quests';
// styles
import rocketGif from '../../../assets/images/gif/rocket.gif';
// other
import { API, fetcher, loggedAxios } from '../../../api';
import { useQueryParams } from '../../../hooks';
import { openLink } from '../../../utils/openTelegramLink';
import { ITapPadsFeed } from '../types';
import { ROUTES } from '../../../constants';

interface IApiTapPadsHistoryActivity {
  result: IApiTapPadsHistoryTransaction[];
  total: number;
  sum: string;
}

interface IApiTapPadsHistoryTransaction {
  targetBalance: string;
  amount: string;
  createdAt: string;
}

interface IActivity {
  history: ITapPadsHistoryActivity[];
  total: string;
  loading: boolean;
}

const defaultActivity = {
  history: [],
  total: '0',
  loading: false,
};

export const TapPads: React.FC = () => {
  const navigation = useNavigate();
  const { url, apikey, coefficient } = useQueryParams();

  const paymentMultiplier = Number(coefficient);

  const { data: feedsData, isLoading, mutate } = useSWR<ITapPadsFeed[]>(`${url}&apikey=${apikey}`, fetcher);

  const [feeds, setFeeds] = useState<ITapPadsFeed[]>();
  const [activity, setActivity] = useState<IActivity>(defaultActivity);
  const [showTransaction, setTransactionShowing] = useState<boolean>(false);

  const triggerTransactionShowing = () => setTransactionShowing(!showTransaction);

  useEffect(() => {
    feedsData?.length && setFeeds(feedsData);
  }, [feedsData]);

  const handleClickBack = () => navigation(`${ROUTES.home}?tab=QUESTS`);

  const openFeed = (feed: ITapPadsFeed) => {
    loggedAxios(feed.click_postback);
    openLink(feed.url);
    mutate();
  };

  const openRundomFeed = () => {
    const activeFeeds = feeds?.filter(feed => !feed.is_done);
    const randomFeed = activeFeeds?.[Math.floor(Math.random() * activeFeeds.length)];
    randomFeed && openFeed(randomFeed);
  };

  const showActivity = async () => {
    setActivity({ ...activity, loading: true });

    const { data } = await loggedAxios<IApiTapPadsHistoryActivity>(API.tapPadsEarning);

    if (data?.result) {
      const history = data.result.map(item => ({
        description: dayjs(item.createdAt).format('DD/MM/YYYY'),
        payout: Number(item.amount),
      }));
      setActivity({ total: data.sum, history, loading: false });
    }

    triggerTransactionShowing();
  };

  return (
    <Layout loading={isLoading || activity.loading}>
      <div className='container flexColumn'>
        {feeds ? (
          <>
            <TapPadsFeeds
              feeds={feeds}
              coefficient={paymentMultiplier}
              onClickLuckyBtn={openRundomFeed}
              onClickCardBtn={openFeed}
              onClickActivity={showActivity}
            />
            <Drawer open={showTransaction} setOpen={triggerTransactionShowing}>
              <TapPadsActivity {...activity} />
            </Drawer>
          </>
        ) : (
          <>
            <GifTextBlock
              title='There’s no income here yet'
              subTitle='You haven’t started earn points with Act 2 Earn yet'
              img={rocketGif}
            />
            <Button fullWidth onClick={handleClickBack}>
              Check other quests
            </Button>
          </>
        )}
      </div>
    </Layout>
  );
};
