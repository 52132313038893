import React from 'react';
// styles
import styles from './styles.module.scss';
import img from '../../assets/img.jpg';
// other
import { formatPrice } from '../../../../utils/formattingHelpers';
import { IDiamondCard } from '../../types/interfaces/IDiamondItem';
import { Icon } from '../../../../components/Icon';

interface IContentProps {
  diamondInfo: IDiamondCard;
}

export const Content: React.FC<IContentProps> = ({ diamondInfo }) => {
  const properties = [
    { property: 'Weight', label: diamondInfo?.weight },
    { property: 'Color', label: diamondInfo?.color },
    { property: 'Clarity', label: diamondInfo?.clarity },
    { property: 'Shape', label: diamondInfo?.shape },
  ];

  return (
    <>
      <div className='max-w-[600px] m-auto'>
        <img src={img} alt='' loading='lazy' />
      </div>
      <div className='inline-flex flex-col gap-1 p-[20px]'>
        <div className={`${styles.balance}`}>
          {formatPrice(diamondInfo?.price)} <Icon sizeInPx={36} />
        </div>
        <span>{diamondInfo?.name}</span>
      </div>
      <div className={styles.properties}>
        {properties.map(item => (
          <div className={styles.row} key={item.property}>
            <span className='w-[50%] text-[#171717A3]'>{item.property}</span>
            <span className='w-[50%]'>{item.label}</span>
          </div>
        ))}
      </div>
    </>
  );
};
