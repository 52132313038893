import React from 'react';
// components
import { Box, Flex, Text } from '@chakra-ui/react';
import { IOnboardingStep, OnboardingSteps } from '../../../components/OnboardingSteps';
import { TonWalletButton } from '../../../components/TonWalletButton';
import { ConnectedWallet, Wallet } from '@tonconnect/ui-react';

interface IOnboardingProps {
  wallet: Wallet | ConnectedWallet | null;
  onTonButtonClick?: (event: Event) => void;
}

const steps: Array<IOnboardingStep> = [
  { label: 'Connect wallet', icon: '👇' },
  { label: 'Check available stages', icon: '🔓' },
  { label: 'Mint', icon: '🖼️️' },
  { label: 'Check your walllet', icon: '👛' },
  { label: 'Profit', icon: '🏆' },
];

export const Onboarding: React.FC<IOnboardingProps> = ({ wallet, onTonButtonClick }) => (
  <Flex flexGrow='1' flexDirection='column' justifyContent='space-between' height='100%'>
    <Box paddingTop='8px'>
      <Flex flexDirection='column' alignItems='center'>
        <Box
          maxWidth='max-content'
          fontWeight='bold'
          border='1px solid #e2e2e2'
          padding='9px 16px'
          borderRadius='48px'
          background='fifthColor'
        >
          + Free NFT
        </Box>
        <Text
          fontFamily='heading'
          maxWidth='340px'
          fontSize='32px'
          position='relative'
          textAlign='center'
          lineHeight='1'
          padding='16px'
        >
          Girls&#39; Best Friends NFT
        </Text>
      </Flex>
      <OnboardingSteps steps={steps} />
    </Box>
    <Box padding='8px 16px'>
      <TonWalletButton wallet={wallet} onClick={onTonButtonClick} />
    </Box>
  </Flex>
);
