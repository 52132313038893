import { useEffect } from 'react';

export interface IUseIntervalParams {
  ms?: number;
  active?: boolean;
  deps?: any[];
}

export const useInterval = <T>(
  callback: () => T | Promise<T>,
  { ms = 300, active = true, deps = [] }: IUseIntervalParams
): void => {
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (active) {
      intervalId = setInterval(callback, ms);
    }

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [callback, ms, active, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};
