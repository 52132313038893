import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
// components
import { Layout } from '../../../components/Layout/Layout';
import Onboarding from '../components/Quests/Onboarding/Onboarding';
import { FullPageLoader } from '../../../components/FullPageLoader/FullPageLoader';
// other
import { API, fetcher } from '../../../api';
import { useTelegramBackButton } from '../../../hooks/useTelegramBackButton';
import { ROUTES } from '../../../constants';
import { IUser } from '../../../types';

export const Instruction: React.FC = () => {
  const { id } = useParams();

  const questInfoUrl = API.questInfo(id as string);

  const {
    data: quest,
    isLoading: isLoadingQuestInfo,
    mutate: mutateQuest,
  } = useSWR(questInfoUrl, fetcher, {
    revalidateOnMount: true,
    revalidateIfStale: true,
    revalidateOnFocus: true,
    refreshInterval: 2 * 60 * 1000,
  });

  const { data: userInfo, isLoading: isLoadingUserData } = useSWR<IUser>(API.userInfo, fetcher);

  useTelegramBackButton(ROUTES.home + '?tab=QUESTS');

  if (isLoadingQuestInfo || isLoadingUserData) return <FullPageLoader />;

  return (
    <Layout>
      <Onboarding userInfo={userInfo as IUser} quest={quest} mutateQuest={mutateQuest} />
    </Layout>
  );
};

export default Instruction;
