import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
// components
import { Banner, IBannerProps } from '../Banner';
// styles
import diamondGif from '../../../../../Diamonds/assets/diamond.gif';
// other
import { API, fetcher } from '../../../../../../api';
import { ROUTES } from '../../../../../../constants';

interface ITapPadsApiFeed {
  coefficient: number;
  isEnabled: boolean;
  url: string;
}

interface ITapPadsBannerProps extends Omit<IBannerProps, 'title' | 'description' | 'imageSrc'> {
  description?: string | JSX.Element;
}

export const TapPadsBanner: React.FC<ITapPadsBannerProps> = bannerProps => {
  const navigate = useNavigate();

  const { data: tapPads, isLoading } = useSWR<ITapPadsApiFeed>(API.tapPadsFeed, fetcher);

  const onOpen = () => navigate(`${ROUTES.tapPads}?url=${tapPads?.url}&coefficient=${tapPads?.coefficient}`);

  return (
    <>
      {!isLoading && tapPads?.isEnabled && (
        <Banner
          title='Act 2 Earn'
          description='Join others Apps'
          imageSrc={diamondGif}
          onClick={onOpen}
          {...bannerProps}
        />
      )}
    </>
  );
};
