import React from 'react';
// components
import { Flex } from '@chakra-ui/react';
import { Navbar } from '../../../components/Navbar';
import { Confetti } from '../../../components/Confetti';
import { Content } from './Content';

export const Purchase: React.FC = () => (
  <Flex direction='column' height='100vh'>
    <Content />
    <Navbar />
    <Confetti />
  </Flex>
);
