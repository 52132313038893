import { Quest } from '../types';

export const questAPI = {
  bybitConnection: 'user/connect/bybit-id',
  nomis: '/wallets/connect',
};

export const stepInputRegex = {
  bybitConnection: /^[0-9]+$/,
  nomis: /^[a-zA-Z0-9\-_]{26,48}$/,
};

export const stepInputRegexError = {
  bybitConnection: 'Please enter numbers only',
  nomis: 'Please enter correct wallet',
};

export const questRequestBody: Record<TQuestAPI, (input: string) => object> = {
  [Quest.BybitConnection]: (input: string) => ({ uid: input }),
  [Quest.Nomis]: (input: string) => ({
    address: input,
    name: Quest.Nomis,
  }),
};

export type TQuestAPI = keyof typeof questAPI;
export type TQuestTypeRegex = keyof typeof stepInputRegex;
export type TQuestTypeRegexError = keyof typeof stepInputRegexError;
