import React, { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
// components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
// styles
import 'swiper/css';
import styles from './styles.module.scss';
import fireIcon from '../../assets/fire.png';
import { Icon } from '../../../../components/Icon';

interface ISliderProps {
  rewards: string[];
  inRow: number;
}

export const Slider: React.FC<ISliderProps> = ({ rewards, inRow }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperClass>(null);

  useEffect(() => {
    setTimeout(() => {
      if (swiperRef.current) {
        swiperRef.current.slideTo(inRow);
      }
    }, 200);
  }, [inRow]);

  return (
    <Swiper
      ref={swiperRef}
      className='w-full !px-[16px] mt-[48px]'
      slidesPerView={3}
      centeredSlides={true}
      spaceBetween={-1}
      speed={2000}
      loop={false}
      onSlideChange={(swiper: SwiperClass) => setActiveIndex(swiper.activeIndex)}
      onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
      allowTouchMove={false}
    >
      {rewards.map((reward, index) => (
        <SwiperSlide key={index}>
          <Flex direction='column' alignItems='center' justify='center' overflow='hidden'>
            <Box className={`${styles.item} ${index <= activeIndex ? styles.active : ''}`}>
              <Flex
                margin='0 6px'
                zIndex={1}
                overflow='hidden'
                alignItems='center'
                justify='center'
                width='64px'
                height='64px'
                borderRadius='50%'
                background={index <= activeIndex ? 'black' : 'fifthColor'}
              >
                {index <= activeIndex && <Image src={fireIcon} boxSize='18px' />}
                {index > activeIndex && (
                  <Box width='12px' height='12px' borderRadius='50%' background='sixthColor'></Box>
                )}
              </Flex>
              {index !== 0 && (
                <span
                  className={`${styles.hasLineBefore} ${index <= activeIndex ? styles.active : ''}`}
                ></span>
              )}
              {index !== rewards.length - 1 && (
                <span className={`${styles.hasLineAfter} ${index < activeIndex ? styles.active : ''}`}></span>
              )}
            </Box>
            <Text
              fontSize='20px'
              fontWeight='bold'
              mt='2'
              display='inline-flex'
              alignItems='center'
              gap='3px'
            >
              +{reward} <Icon sizeInPx={22} />
            </Text>
            <Text fontSize='12px' color='secondaryColor'>
              LV {index + 1}
            </Text>
          </Flex>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
