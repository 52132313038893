import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
// components
import { Flex } from '@chakra-ui/react';
import { AdaptiveHeightScroll } from '../../../../../components/Scroll';
import { PlansContainer } from '../../Plan/Container';
import { IStageCardProps, OnStageCardClick, StageCard } from '../Card';
// styles
import styles from './styles.module.scss';
// other
import { getStageCardBtnText, getStageCardDescription } from '../../../utils/mintingListHelpers';
import { useInterval } from '../../../../../hooks/useInterval';
import { IPlanCardProps } from '../../Plan/Card';

export interface IStage extends Omit<IStageCardProps, 'title' | 'description'> {
  id?: string | number;
  startDate: Dayjs;
  finishDate?: Dayjs | null;
  whiteList: boolean;
}

interface IStagesListProps {
  stages: Array<IStage>;
  plans?: Array<IPlanCardProps>;
  needCheckForUpdate: boolean;
  isAllDone?: boolean;
  isAllDisabled?: boolean;
  isAllFinished?: boolean;
  isLoading?: boolean;
  onCardClick?: OnStageCardClick;
  onCardBtnClick?: (action: string, card: IStageCardProps) => Promise<void> | void;
  onUpdate?: () => void;
}

export const StagesList: React.FC<IStagesListProps> = ({
  stages = [],
  plans = [],
  needCheckForUpdate,
  isAllDone,
  isAllDisabled,
  isAllFinished,
  isLoading,
  onCardClick,
  onCardBtnClick,
  onUpdate,
}) => {
  useInterval(
    () => {
      const dateNow = dayjs().format('YYYY-MM-DD hh:mm');

      const needUpdate = stages.some(
        stage => stage.startDate.isSame(dateNow) || stage.finishDate?.isSame(dateNow)
      );

      if (needUpdate) {
        onUpdate?.();
      }
    },
    { ms: 10000, active: needCheckForUpdate }
  );

  return (
    <>
      <h2 className={styles.title}>MINTING STAGES</h2>
      <AdaptiveHeightScroll>
        <Flex flexDirection='column' gap='12px' padding=' 12px 16px'>
          {stages.map(({ startDate, finishDate, whiteList, ...stage }, key) => {
            const dateNow = dayjs();
            const description = getStageCardDescription(startDate, finishDate);
            const isActiveStage =
              startDate?.isBefore(dateNow) && (finishDate ? finishDate?.isAfter(dateNow) : true);
            const isComingSoon = dateNow.isBefore(startDate);
            const action = getStageCardBtnText({ startDate, isActiveStage, isComingSoon, ...stage });

            return (
              <StageCard
                key={key}
                title={`Stage ${key + 1} (${whiteList ? 'Whitelist' : 'Public'})`}
                description={description}
                comingSoon={isComingSoon}
                disabled={finishDate?.isBefore(dateNow) || isAllDisabled}
                selected={isActiveStage}
                done={isAllDone}
                hideBtn={isLoading || isAllFinished}
                onCardClick={onCardClick}
                {...stage}
                btn={{
                  onClick: card => onCardBtnClick?.(action as string, card),
                  isDisabled: isComingSoon || (action === 'MINT' && isAllDone),
                  text: action,
                  ...stage.btn,
                }}
              />
            );
          })}
          {!!plans.length && (
            <PlansContainer
              title='Take a look at the future staking plans '
              plans={plans}
              className={styles.plans}
            />
          )}
        </Flex>
      </AdaptiveHeightScroll>
    </>
  );
};
