import { Api, HttpClient } from 'tonapi-sdk-js';
import { Network } from '../types';

const tonapiURL = {
  mainnet: 'https://tonapi.io',
  testnet: 'https://testnet.tonapi.io',
};

export class NftAPI {
  network: Network;

  httpClient: HttpClient;

  client: Api<HttpClient>;

  constructor(network: Network) {
    this.network = network;
    this.httpClient = new HttpClient({
      baseUrl: tonapiURL[network],
    });
    this.client = new Api(this.httpClient);
  }

  async getNftByOwner(ownerAddress: string, NFTAddress: string) {
    const response = await this.client.accounts.getAccountNftItems(ownerAddress, {
      collection: NFTAddress,
    });

    return response?.nft_items;
  }
}
