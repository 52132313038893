import React, { useEffect, useState } from 'react';
// components
import { Button } from '../../Button';
// styles
import styles from './styles.module.scss';

export interface IBuyAssistantDrawerProps {
  open: boolean;
  setOpen?: (value: boolean) => void;
  balance?: number;
  price?: number;
  product?: string;
  invoiceLink: string;
}

export const BuyAssistantDrawer: React.FC<IBuyAssistantDrawerProps> = ({
  open,
  setOpen,
  balance = 0,
  price = 100,
  product = 'photo',
  invoiceLink,
}) => {
  const [isClosing, setClosing] = useState(false);

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        setOpen?.(!open);
        setClosing(false);
      }, 500); // Duration of the closing animation

      return () => clearTimeout(timer);
    }
  }, [open, setOpen, isClosing]);

  const toggleDrawer = () => setClosing(!isClosing);

  const processPayment = async () => {
    window.Telegram?.WebApp?.openInvoice(invoiceLink, () => {});
    toggleDrawer();
  };

  return (
    <>
      {open && (
        <div className={`${styles.overlay} ${isClosing ? styles.fadeOut : styles.fadeIn}`}>
          <div className={`${styles.drawer} ${isClosing ? styles.slideOut : styles.slideIn}`}>
            <div className={styles.header}>
              <span className={styles.headerBalance}>
                Balance <br />
                ⭐️ {balance}
              </span>
              <button className={styles.headerCloseBtn} onClick={toggleDrawer}>
                &times;
              </button>
            </div>
            <div className={styles.content}>
              <div className={styles.contentIcon}>
                <span>🔓</span>
              </div>
              <h2 className={styles.contentTitle}>Confirm Your Purchase</h2>
              <p className={styles.contentDescription}>
                Do you want to unlock {product} in <br />
                <span>for {price} Stars?</span>
              </p>
            </div>
            <Button
              _hover={{ background: '#007bff' }}
              bgColor='#007bff'
              fontSize='16px'
              m='30px 0 34px;'
              zIndex='1'
              fullWidth
              onClick={processPayment}
            >
              Confirm and Pay ★ {price}
            </Button>
            {[...Array(30)].map((_, i) => {
              const isFivePointedStar = Math.round(Math.random());
              const randomIntFromInterval = (min: number, max: number) =>
                Math.floor(Math.random() * (max - min + 1) + min);

              return (
                <div
                  key={i}
                  className={styles.star}
                  style={{
                    top: `${randomIntFromInterval(10, 40)}%`,
                    left: `${randomIntFromInterval(10, 90)}%`,
                    animationDelay: `-${i + 2}s`,
                  }}
                >
                  {isFivePointedStar ? '⭐️' : '☄️'}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default BuyAssistantDrawer;
