import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import { IUser } from '../../../types';
import { API, loggedAxios } from '../../../api';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

export const TimePassedFromGameStart = (user: IUser): number => {
  if (!user?.limitDate || !user?.requestedAt) {
    return 1000 * 1000 * 1000;
  }
  const startTime = new Date(user?.limitDate).getTime();
  const requestTime = new Date(user?.requestedAt).getTime();
  const passed = requestTime - startTime;

  return passed;
};

export const fromMillisecondToStringHours = (passed: number): string => {
  return dayjs.duration(passed).format('HH:mm:ss');
};

export const fromMillisecondToStringSecond = (totalMilliseconds: number): string => {
  const secs = Math.floor(totalMilliseconds / 1000);
  return String(secs).padStart(2, '0');
};

export const sendAdsWatchRequest = async (type: string) => {
  try {
    await loggedAxios.post(API.adsWatch, { type });
  } catch (e) {
    console.warn(e);
  }
};
