import React from 'react';
import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react';

export interface IButtonProps extends ButtonProps {
  secondary?: boolean;
  fullWidth?: boolean;
}

export const Button: React.FC<IButtonProps> = ({ secondary, fullWidth, ...buttonProps }) => (
  <ChakraButton
    _hover={{ background: secondary ? 'var(--bg-secondary-color)' : 'var(--bg-accent-color)' }}
    background={secondary ? 'var(--bg-secondary-color)' : 'var(--bg-accent-color)'}
    color={secondary ? 'var(--text-primary-color)' : 'var(--text-primary-inverse-color)'}
    textTransform='uppercase'
    fontFamily='Inter, sans-serif'
    fontWeight='600'
    fontSize='12px'
    lineHeight='12px'
    width={fullWidth ? '100%' : 'auto'}
    height='auto'
    borderRadius={fullWidth ? '8px' : '4px'}
    padding={fullWidth ? '19px' : '10px 16px'}
    {...buttonProps}
  />
);

export default Button;
