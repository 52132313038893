import React from 'react';
import { Link } from 'react-router-dom';
// components
import { GifTextBlock } from '../../../../components/GifTextBlock';
// styles
import styles from './styles.module.scss';
import diamondGif from '../../assets/diamond.gif';
// other
import { ROUTES } from '../../../../constants';

const BUTTONS = [
  { label: 'Continue shopping', link: `${ROUTES.diamonds}/?tab=FOR SALE` },
  { label: 'Go to own', link: `${ROUTES.diamonds}/?tab=OWN`, bg: 'grey' },
];

export const Content: React.FC = () => (
  <>
    <GifTextBlock
      title='The purchase was successfully completed!'
      subTitle='Check in the Own section'
      img={diamondGif}
    />
    <div className='flex flex-col justify-center items-center gap-[8px]'>
      {BUTTONS.map(el => {
        return (
          <Link key={el.label} to={el.link} className={`${styles.link} ${el.bg ? styles[el.bg] : ''}`}>
            {el.label}
          </Link>
        );
      })}
    </div>
  </>
);
