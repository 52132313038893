import { Sender } from '@ton/core';
import { CHAIN, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { Address } from 'ton-core';
import { INetwork, Network } from '../types';

export const useTonConnect = (): {
  sender: Sender;
  connected: boolean;
  wallet: string | null;
  network: INetwork | null;
} => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();

  const getNetwork = (networkChain: CHAIN | undefined): INetwork | null => {
    return networkChain
      ? {
          value: networkChain,
          name: networkChain === CHAIN.MAINNET ? Network.Mainnet : Network.Testnet,
        }
      : null;
  };

  return {
    sender: {
      send: async args => {
        tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc().toString('base64'),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        });
      },
      address: wallet?.account?.address ? Address.parse(wallet?.account?.address) : undefined,
    },

    connected: !!wallet?.account.address,
    wallet: wallet?.account.address ?? null,
    network: getNetwork(wallet?.account.chain),
  };
};
