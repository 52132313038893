import React from 'react';
import { toast } from 'react-toastify';
// components
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { CloseButton } from '../../../../../components/Button';
// other
import { getInitDataUnsafe } from '../../../../../api/auth';
import { hapticImpact } from '../../../../../utils/hapticFeedback';

const BUTTON_TYPE = {
  copy: 'copy',
  share: 'share',
  cancel: 'cancel',
};

const BUTTONS = [
  { id: 1, label: 'Copy link', variant: 'primary', type: BUTTON_TYPE.copy },
  { id: 2, label: 'Share', variant: 'outline', type: BUTTON_TYPE.share },
  { id: 3, label: 'Cancel', variant: 'transparent', type: BUTTON_TYPE.cancel },
];

interface IInvitationNavBarProps {
  setShowModal: (state: boolean) => void;
}

export const InvitationNavBar: React.FC<IInvitationNavBarProps> = ({ setShowModal }) => {
  const data = getInitDataUnsafe();
  const referralLink = `https://t.me/DiamoreCryptoBot/app?startapp=${data?.user?.id || 123}`;

  const handleClose = () => {
    setShowModal?.(false);
    hapticImpact('light');
  };

  const handleCopy = async () => {
    hapticImpact('medium');
    await navigator.clipboard.writeText(referralLink);
    toast.success('REFERRAL LINK IS COPIED', {
      icon: <CheckIcon />,
    });
  };

  const handleShare = () => {
    hapticImpact('medium');
    const shareMessage = 'Join Diamore to earn a Real Diamond 💎';
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(
      referralLink
    )}&text=${encodeURIComponent(shareMessage)}`;

    window.open(telegramUrl, '_blank');
  };

  const handleCancel = () => {
    hapticImpact('medium');
    handleClose();
  };

  const handleButtonClick = {
    [BUTTON_TYPE.copy]: handleCopy,
    [BUTTON_TYPE.share]: handleShare,
    [BUTTON_TYPE.cancel]: handleCancel,
  };

  const handleClick = (type: string) => {
    const handler = handleButtonClick[type];
    if (handler) {
      handler();
    }
  };

  return (
    <Box
      padding='16px 16px 8px'
      position='relative'
      backgroundColor='white'
      borderTopRightRadius='12px'
      borderTopLeftRadius='12px'
      maxH='290px'
      height='100%'
    >
      <CloseButton onClick={handleClose} />

      <Box width='36px' height='4px' backgroundColor='gray.300' borderRadius='2px' margin='0 auto' />

      <Text textAlign='center' marginTop='8px' fontSize='17px' fontWeight='600'>
        Invite friends
      </Text>

      <Flex width='100%' direction='column' marginTop='28px' gap='8px'>
        {BUTTONS.map(el => {
          return (
            <Button
              key={el.id}
              disabled={false}
              width='100%'
              onClick={() => handleClick(el.type)}
              variant={el.variant}
            >
              {el.label}
            </Button>
          );
        })}

        <Box width='140px' height='5px' backgroundColor='#171717' borderRadius='2px' margin='0 auto' />
      </Flex>
    </Box>
  );
};
