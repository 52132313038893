import React, { useState } from 'react';
// components
import { Button } from '../../Button';
import { BuyAssistantDrawer as Drawer } from '../Drawer';
// styles
import star from '../assets/assistantLogo.png';
import styles from './styles.module.scss';

export interface IBuyAssistantLauncherSpecification {
  title: string;
  description: string;
  icon: string;
}

export interface IBuyAssistantLauncherProps {
  price?: number;
  specification?: Array<IBuyAssistantLauncherSpecification>;
  invoiceLink?: string;
  getInvoiceLink: () => Promise<string>;
}

export const BuyAssistantLauncher: React.FC<IBuyAssistantLauncherProps> = ({
  price = 10,
  specification = [],
  getInvoiceLink,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [invoiceLink, setInvoiceLink] = useState<string>(props.invoiceLink || '');

  const updateInvoiceLink = async () => {
    if (getInvoiceLink) {
      const newInvoiceLink = await getInvoiceLink();
      setInvoiceLink(newInvoiceLink);
    }
  };

  const onConfirmPurchase = () => {
    updateInvoiceLink();
    setOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.header}>
          <img className={styles.labelIcon} src={star} alt='🌟' width='72' height='72' />
          <h4 className={styles.title}>Assistant</h4>
        </div>
        <ul className={styles.body}>
          {specification.map((el, idx) => (
            <li key={el.title + idx} className={styles.card}>
              <div className={styles.cardIcon}>
                <span>{el.icon}</span>
              </div>
              <div>
                <h5 className={styles.cardTitle}>{el.title}</h5>
                <p className={styles.cardDescription}>{el.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <Button fullWidth onClick={onConfirmPurchase}>{`BUY FOR ${price} TELEGRAM STARS`}</Button>
      <Drawer price={price} invoiceLink={invoiceLink} open={open} setOpen={setOpen} />
    </div>
  );
};

export default BuyAssistantLauncher;
